import { useNavigate, useParams } from "react-router-dom";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import ToastifyMessage from "../../../../lib/toastify";
import { useEffect, useState } from "react";
import { ToastError, ToastSuccess, ToastWarning } from "../../../UI/toast";
import { startupScreenServices } from "../../../../services/InspectionOneServices";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function NewGetStartedForm({ getStartedUpdated }: any) {
  const [isChoiceSelected, setIsChoiceSelected] = useState<boolean>(false);
  const navigate = useNavigate();
  const { logicId, id } = useParams();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    imageUrl: "",
    isDefault: false,
  });
  const [errors, setErrors] = useState({
    name: "",
  });

  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleCheckboxChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const generateGuid = () => {
    return uuidv4();
  };
  const handleFormValidation = () => {
    const name = formData.name;
    const isNameEmpty = name.trim() === "";

    setErrors({
      name: isNameEmpty ? "web.logic.label.errors" : "",
    });

    return !isNameEmpty;
  };
  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleGetStartedEdit(id);
    }
  }, [id]);
  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      try {
        setIsLoading(true);
        const requestJson = {
          ...formData,
          uniqueId: mode === "update" ? id : generateGuid(),
          ProcessLogicUniqueId: logicId,
          name: formData.name,
          description: formData.description,
          isDeleted: 0,
          LogicGetStartedId: 0,
          isDefault: isChoiceSelected ? 1 : 0,
        };

        const response = await startupScreenServices.StartupScreenAction(
          requestJson
        );
        if (response.success) {
          handleSuccess(
            t("web.startup.title.name"),
            t("web.toaster.desc.startupsuccess")
          );

          setTimeout(() => {
            navigate(`/ai-logic-studio/process-logic/${logicId}`);
            getStartedUpdated(true);
            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
          handleError(t("web.toolbar.label.ailogicresults"), response.message);
          setTimeout(() => {}, 1000);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };
  const handleGetStartedEdit = async (uniqueIdId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        uniqueId: uniqueIdId,
      };
      const response = await startupScreenServices.StartupScreenDetail(
        requestJson
      );
      if (response.success) {
        setIsLoading(false);
        console.log(response.data);
        const isDefault = response.data[0].isDefault === 1;
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data[0],
          isDefault: isDefault,
        }));
        setIsChoiceSelected(isDefault);
      } else {
        setIsLoading(false);
        handleError(t("web.toolbar.label.ailogicresults"), response.message);
        setTimeout(() => {}, 1000);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };
  const handleFormCancel = () => {
    navigate(`/ai-logic-studio/process-logic/${logicId}`);
  };
  //#region Alert
  const { ToastifyHandle } = ToastifyMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const handleSuccess = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastSuccess title={titleName} description={message} />,
      "success"
    );
  };
  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };
  const handleWarning = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastWarning title={titleName} description={message} />,
      "warning"
    );
  };

  //#endregion
  const handleIsCheckedChange = (newState: any) => {
    setIsChoiceSelected(newState);
    console.log("Toggle button state changed:", newState);
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.startupscreen.label.name")}
                      type="text"
                      id="processName"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <TextAreaField
                      labelName={t("web.startupscreen.label.desc")}
                      id="processDescription"
                      isRequired={true}
                      value={formData.description}
                      onChange={(e) => handleInputChange("description", e)}
                    />
                  )}
                </div>
                {/* <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <ToggleButtonWithoutForm
                      id="getStarted"
                      size="large"
                      label={t("web.startupscreen.label.default")}
                      isButtonChecked={formData.isDefault}
                      getIsChecked={handleIsCheckedChange}
                    />
                  )}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={() => handleFormSubmit()}
        isLoading={isLoading}
      />
    </div>
  );
}
export default NewGetStartedForm;
