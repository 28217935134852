import PillarTypes from "../../UI/pillar-types";
import ExploreOptions from "../explore-options";

function ExploreActions({
  fetchData,
  getSearchedQuery,
  isGridView,
  setIsGridView,
  magicTypeCount,
  handleSelectionType,
  handleClick,
  isLoading,
}: any) {
  return (
    <div className="w-full h-auto flex justify-between max-xl:flex-col ">
      <PillarTypes
        isIconOnly={false}
        data={magicTypeCount}
        handleSelectionType={handleSelectionType}
        isLoading={isLoading}
        actionType="magic"
      />
      <ExploreOptions
        isGridView={isGridView}
        setIsGridView={setIsGridView}
        fetchData={fetchData}
        getSearchedQuery={getSearchedQuery}
        handleClick={handleClick}
      />
    </div>
  );
}
export default ExploreActions;
