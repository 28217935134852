import { Fragment, useState } from "react";
import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";

import {
  ImageNameCellRenderer,
  MoreOptionsRenderer,
  NameCellRenderer,
} from "../../../UI/grid-cells";
import { useNavigate } from "react-router-dom";

function AILogicStudioGrid({
  selectedColumns,
  rowSelection,
  moreOptions,
  handleNavigate,
  handleEdit,
  handleDelete,
  rowData,
  scrollGroundRef,
  handleScroll,
  initialLoading,
  isLoading,
  limit,
  finalFetch,
  sorting,
  sortingColumnName,
  handleSort,
  handleCheckboxChange,
  noDataFound,
}: any) {
  const navigate = useNavigate();

  const [columnDefs, setColumnDefs] = useState<any>([
    {
      headerName: "Logic Name",
      token: "web.logic.label.name",
      field: "name",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Logic Description",
      token: "web.logic.label.desc",
      field: "description",
      width: "flex-1",
      align: "left",
    },
    {
      headerName: "Created Date",
      token: "web.common.label.createddate",
      field: "createdAt",
      width: "flex-1",
      align: "left",
    },
  ]);

  const selectedMoreOption = (option: string, row: any) => {
    console.log(option, row);
    if (option === "edit") {
      handleEdit(row);
    } else if (option === "delete") {
      handleDelete(row);
    }
  };

  const handleCellClicked = (params: any) => {
    navigate(`process-logic/${params.uniqueId}`);
  };

  return (
    <div className="w-full h-full">
      <InfiniteScrollGrid
        columnDefs={columnDefs}
        rowData={rowData}
        initialLoading={initialLoading}
        searchedQuery=""
        handleScroll={handleScroll}
        chatGroundRef={scrollGroundRef}
        selectedColumns={selectedColumns}
        isLoading={isLoading}
        finalFetch={finalFetch}
        rowSelection={rowSelection}
        moreOptions={moreOptions}
        handleSort={handleSort}
        sorting={sorting}
        sortingColumnName={sortingColumnName}
        noDataFound={noDataFound}
      >
        {rowData?.map((rowData: any, i: number) => (
          <Fragment key={i}>
            <tr className="w-full h-auto flex justify-between p-[0.65rem] border-b border-[#EDEDED] dark:border-[#BDBDBF] hover:bg-[#eff8ff] dark:hover:bg-[#112A46]">
              {/* <RowSelectionRenderer
                rowData={rowData}
                rowSelection={rowSelection}
                handleCheckboxChange={handleCheckboxChange}
              /> */}
              <ImageNameCellRenderer
                cellKey="name"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleCellClicked}
              />
              <NameCellRenderer
                cellKey="description"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleCellClicked}
              />
              <NameCellRenderer
                cellKey="createdAt"
                selectedColumns={selectedColumns}
                rowData={rowData}
                handleClick={handleCellClicked}
              />
              <MoreOptionsRenderer
                rowData={rowData}
                moreOptions={moreOptions}
                selectedMoreOption={selectedMoreOption}
              />
            </tr>
          </Fragment>
        ))}
      </InfiniteScrollGrid>
    </div>
  );
}
export default AILogicStudioGrid;
