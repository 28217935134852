import { Outlet, useNavigate } from "react-router-dom";
import PageHeader from "../../../components/global-layout/page-header";
import Container from "../../../components/section/ai-magix-container/container";
import { useState, useEffect } from "react";
import InputParametersWrapper from "./input-parameters-wrapper";
import { containerMapping } from "../../../services/authFunctions";

function ContainerWrapper() {
  const [containerMagicListData, setContainerMagicListData] = useState<any>([]);
  const breadcrumbs = [
    { label: "Ai Magix Container", link: "/ai-magix-container" },
    { label: "Automatic Inspection", link: undefined },
  ];
  const [isInputParamater, setIsInputParameter] = useState(false);
  const [inputParameterParams, setInputParameterParams] = useState(null);
  const [containerItems, setContainerItems] = useState<any[]>([]);
  const [containerMagicList, setContainerMagicList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [magixType, setMagixType] = useState<any>(false);
  const navigate = useNavigate();
  const closeInputParameter = () => {
    setIsInputParameter(false);
    setInputParameterParams(null);
  };
  const handleSubmit = async () => {
    let itemsWithOrder: any[];

    if (containerItems[0]?.type === 4) {
      itemsWithOrder = containerItems
        .map((item: any, index: number) => {
          if (index === 0) {
            return {
              ...item,
              id: new Date().getTime().toString(),
              orderNo: index + 1,
            };
          }
          return null; // Explicitly return null for all other items
        })
        .filter((item) => item !== null); // Filter out null items
    } else {
      itemsWithOrder = containerItems.map((item: any, index: number) => ({
        ...item,
        id: new Date().getTime().toString(),
        orderNo: index + 1,
      }));
    }

    setIsLoading(true);
    try {
      const newData = {
        ...containerMagicList,
        magicDetails: itemsWithOrder,
      };
      const response = await containerMapping(newData);
      if (response.success) {
        setIsLoading(false);
        closeInputParameter();
        navigate("/ai-magix-container");
      } else {
        console.error("Error fetching data:");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setMagixType(containerItems.some((item: any) => item.type === 4));
  }, [containerItems]);

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <PageHeader
          title={containerMagicListData?.name}
          breadcrumbs={breadcrumbs}
          hasSaveBtn={true}
          handleSaveResponse={() => handleSubmit()}
          isLoading={isLoading}
        />
        <Container
          openInputParameter={(val: boolean) => setIsInputParameter(val)}
          inputParameterParams={(val: any) => setInputParameterParams(val)}
          setContainerItems={setContainerItems}
          containerItems={containerItems}
          containerMagicList={containerMagicList}
          setContainerMagicList={setContainerMagicList}
          magixType={magixType}
          handleSubmit={handleSubmit}
        />
      </div>
      {isInputParamater && (
        <InputParametersWrapper
          inputParameterParams={inputParameterParams}
          closeInputParameter={closeInputParameter}
        />
      )}
      <Outlet />
    </>
  );
}
export default ContainerWrapper;
