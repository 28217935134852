import ModalPageFooter from "../../global-layout/modal-page-footer";
import IconBtn from "../../form/icon-btn";
import { LeftArrowIcon } from "../../svg";
import Authentication from "../../../auth/Authentication";
import i18n from "../../../utils/localization/i18n";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { languageDetails } from "../../../utils/common";
import { userCreate } from "../../../services/authFunctions";
interface LanguageSettingsProps {
  goToSettings: () => void;
  handleClose: () => void;
}

function LanguageSettings({
  goToSettings,
  handleClose,
}: LanguageSettingsProps) {
  const { t } = useTranslation();
  const authService = Authentication();
  var userDetails = authService.decryptData("UserDetails", 2);
  const [formData, setFormData] = useState(userDetails);

  const selectedLanguageString = sessionStorage.getItem(
    "selectedLanguageDetails"
  );

  // Parse the JSON string back to an object or use a default object if the string is null
  const selectedLanguageDetails = selectedLanguageString
    ? JSON.parse(selectedLanguageString)
    : { id: 1, code: "en", value: "English" };
  const languageData = languageDetails;
  const [selectedLanguage, setSelectedLanguage] = useState(
    selectedLanguageDetails.code
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleFormSubmit = () => {
    setIsLoading(true);
    const selectedLanguageDetails = languageDetails.find(
      (lang: any) => lang.code === selectedLanguage
    );
    languageUpdate(selectedLanguageDetails?.id);
    sessionStorage.setItem(
      "selectedLanguageDetails",
      JSON.stringify(selectedLanguageDetails)
    );
    i18n.changeLanguage(selectedLanguage);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    handleClose();
  };
  const languageUpdate = async (languageId: any) => {
    setIsLoading(true);
    const formDataLowerCase = {
      ...formData,
      languageId: languageId,
    };
    const result = await userCreate(
      formDataLowerCase,
      2,
      sessionStorage.getItem("userId"),
      "Language"
    );
    console.log("formDataLowerCase", formDataLowerCase);
    if (result.success) {
      authService.encryptData("", "UserDetails", 2);
      authService.encryptData(formDataLowerCase, "UserDetails", 2);
      handleLanguageChange(formDataLowerCase.languageId);
    } else {
      setIsLoading(false);
    }
  };
  const handleLanguageChange = (item: any) => {
    setSelectedLanguage(item);
  };
  const handleFormCancel = () => {
    handleClose();
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-full h-auto flex justify-start items-center gap-4 mb-[2.5rem]">
          <IconBtn
            size="w-[1.9rem]"
            icon={<LeftArrowIcon />}
            handleClick={() => goToSettings()}
          />
          <p className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white">
            {t("web.settings.label.language")}
          </p>
        </div>
        <div className="w-full h-auto">
          {languageData &&
            languageData.map((item: any, i: any) => (
              <div
                key={i}
                className="w-full h-auto flex justify-start items-center gap-4 mb-6"
              >
                <input
                  type="radio"
                  name="languageSettings"
                  id={item.code}
                  value={item.code}
                  checked={selectedLanguage === item.code}
                  onChange={() => handleLanguageChange(item.code)}
                  className="accent-io-primary cursor-pointer"
                />
                <label
                  htmlFor={item.code}
                  className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1"
                >
                  {item.value}
                </label>
              </div>
            ))}
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
      />
    </div>
  );
}
export default LanguageSettings;
