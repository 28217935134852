/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import EncryptionKey from "./EncryptionKey";
import { useMsal } from "@azure/msal-react";
import i18n from "../utils/localization/i18n";

const AuthService = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());
  const sessionTimeoutDuration = 120 * 120 * 1000; // 1 Hour
  let sessionTimeoutTimer: ReturnType<typeof setTimeout> | undefined;
  let sessionToken: string | null = null;
  const navigate = useNavigate();
  const primaryKey = EncryptionKey();
  const { instance } = useMsal();

  useEffect(() => {
    let logoutHandled = false;

    const handleStorageChange = (event: any) => {
      if (event.key === "logout") {
        if (!logoutHandled) {
          logoutHandled = true;
          setTimeout(() => {
            localStorage.removeItem("logout");
            logout();
          }, 50);
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [navigate]);

  const checkAuthToken = () => {
    const domainAuthToken = sessionStorage.getItem("SessionDomainToken");
    const loginAuthToken = sessionStorage.getItem("SessionLoginToken");
    const sideBarDetails = sessionStorage.getItem("sideBarDetails");
    const loginDetails = sessionStorage.getItem("loginDetails");

    if (
      (!domainAuthToken && !loginAuthToken) ||
      !sideBarDetails ||
      !loginDetails
    ) {
      logout();
    }
  };
  const startSessionTimeoutTimer = () => {
    if (loggedIn) {
      sessionTimeoutTimer = setTimeout(() => {
        logout();
      }, sessionTimeoutDuration);
    }
  };

  const generateSessionToken = () => {
    return Math.random().toString(36).substring(2, 15);
  };

  const updateLastActivityTime = () => {
    setLastActivityTime(Date.now());
    clearTimeout(sessionTimeoutTimer);
    startSessionTimeoutTimer();
  };

  const login = (type: any) => {
    setLoggedIn(true);
    updateLastActivityTime();
    sessionToken = generateSessionToken();
    sessionStorage.setItem("SessionToken", sessionToken);
    if (type == 1) {
      sessionToken = generateSessionToken();
      sessionStorage.setItem("SessionDomainToken", sessionToken);
    } else if (type == 2) {
      sessionToken = generateSessionToken();
      sessionStorage.setItem("SessionLoginToken", sessionToken);
    } else {
    }
  };

  const logout = async () => {
    try {
      setLoggedIn(false);
      sessionStorage.clear();
      clearTimeout(sessionTimeoutTimer);
      i18n.changeLanguage("en");
      localStorage.setItem("logout", Date.now().toString());

      navigate("/domain");

      let loginScheme = 1;
      if (loginScheme === 2) {
        await instance.logoutPopup();
      }
    } catch (e) {
      console.error("Logout failed:", e);
    }
  };

  const autoLogin = () => {
    const storedSessionToken = sessionStorage.getItem("SessionToken");
    if (storedSessionToken) {
      setLoggedIn(true);
      updateLastActivityTime();
      sessionToken = storedSessionToken;
    }
  };

  useEffect(() => {
    autoLogin();
    startSessionTimeoutTimer();
    // Cleanup function
    return () => clearTimeout(sessionTimeoutTimer);
  }, []);

  const isSessionActive = () => {
    const storedSessionToken = sessionStorage.getItem("SessionToken");
    return (
      typeof storedSessionToken === "string" &&
      storedSessionToken === sessionToken &&
      Date.now() - lastActivityTime < sessionTimeoutDuration
    );
  };

  const sessionCheck = () => {
    if (!isSessionActive()) {
      return true;
    }
    return false;
  };

  const isAuthenticated = () => loggedIn;

  const getSessionToken = () => sessionToken;

  const encryptData = (data: any, encryptName: any, actionType: any) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      primaryKey || ""
    ).toString();
    if (actionType === 1) {
      localStorage.setItem(encryptName, encryptedData);
    } else {
      sessionStorage.setItem(encryptName, encryptedData);
    }
  };

  const decryptData = (encryptName: any, actionType: any) => {
    let encryptedData;
    if (actionType === 1) {
      encryptedData = localStorage.getItem(encryptName);
    } else {
      encryptedData = sessionStorage.getItem(encryptName);
    }

    if (!encryptedData) {
      return null;
    }

    try {
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        primaryKey || ""
      ).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.log("Error decrypting data:", error);
      return null;
    }
  };
  useEffect(() => {
    const handleUserActivity = () => {
      updateLastActivityTime();
    };

    const events = ["mousemove", "keydown", "scroll", "click"];

    events.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );

    autoLogin();
    startSessionTimeoutTimer();

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      clearTimeout(sessionTimeoutTimer);
    };
  }, []);
  return {
    isAuthenticated,
    login,
    logout,
    isSessionActive,
    getSessionToken,
    sessionCheck,
    updateLastActivityTime,
    encryptData,
    decryptData,
    checkAuthToken,
  };
};

export default AuthService;
