import { useState } from "react";
import EnterTagsInput from "../../../form/enter-tags-input";
import {
  InputFieldWithoutForm,
  TextAreaField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import StepsHeader from "../../steps-header";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { SelectInputWithoutForm } from "../../../form/select-input";
import Authentication from "../../../../auth/Authentication";
import { ToggleButtonWithoutForm } from "../../../form/toggle-btn";

function CustomMagicStepOne({
  setCurrentStep,
  title,
  data,
  setData,
  handleSubmit,
  isLoading,
}: any) {
  const { t } = useTranslation();
  const { magicType } = useParams();
  const [errors, setErrors] = useState<any>({});
  const authService = Authentication();
  let parsedSideUserDetails: any;
  parsedSideUserDetails = authService.decryptData("UserDetails", 2);
  parsedSideUserDetails = parsedSideUserDetails || {};
  const setTagsAndUpdateData = (updatedTags: any) => {
    setData((prevData: any) => ({
      ...prevData,
      tags: updatedTags,
    }));
  };
  const setDefectsTypeData = (updatedTags: any) => {
    setData((prevData: any) => ({
      ...prevData,
      defectsType: updatedTags,
    }));
  };
  const handleChange = (
    inputName: string,
    valueOrEvent: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      typeof valueOrEvent === "string"
        ? valueOrEvent
        : valueOrEvent.target.value;
    const updatedData = {
      ...data,
      [inputName]: value,
    };
    setData(updatedData);
  };

  const validateFields = () => {
    const newErrors: any = {};
    if (!data.name) {
      newErrors.name = "Magic name is required";
    }
    // if (!data.description) {
    //   newErrors.description = "Description is required";
    // }

    if (magicType === "4" && !data.surfaceType.trim()) {
      newErrors.surfaceType = "Surface type is required";
    }
    // if (
    //   magicType === "4" &&
    //   (!data.defectsType || data.defectsType.length === 0)
    // ) {
    //   newErrors.defectsType = "At least one defect type is required";
    // }
    // if (!data.tags || data.tags.length === 0) {
    //   newErrors.tags = "At least one tag is required";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNextStep = () => {
    if (validateFields()) {
      setCurrentStep(2);
    }
  };

  const handleSelectionChange = (value: any, name: string) => {
    const updatedData = {
      ...data,
      [name]: value,
    };
    setData(updatedData);
  };

  const handlePrevious = () => setCurrentStep(1);

  const surfaceTypeOptions = [
    { value: "1", label: "Glossy Painted Surface" },
    { value: "2", label: "Matte painted Surface" },
    { value: "3", label: "Casted Surface" },
  ];
  const handleSaveDraft = () => {
    if (validateFields()) {
      handleSubmit();
    }
  };
  const getDefaultSurfaceType = (surfaceType: any) => {
    const defaultRole = surfaceTypeOptions.find(
      (option) => option.value === surfaceType
    );
    return defaultRole;
  };
  const handleToggleChange = (propertyPath: any, newState: any) => {
    setData((prevData: any) => {
      const updatedData = { ...data };
      // Update the nested property directly
      if (propertyPath === "isAutoCapture") {
        updatedData.cameraDetails.isAutoCapture = newState;
      } else if (propertyPath === "flashlightMode") {
        updatedData.cameraDetails.flashlightMode = newState;
      } else if (propertyPath === "orientation") {
        updatedData.cameraDetails.orientation = newState;
      } else if (propertyPath === "iOrientationLockerDisabled") {
        updatedData.cameraDetails.iOrientationLockerDisabled = newState;
      } else if (propertyPath === "isPreCheck") {
        updatedData.cameraDetails.isPreCheck = newState;
      } else if (propertyPath === "isFlashlightAccessDisabled") {
        updatedData.cameraDetails.isFlashlightAccessDisabled = newState;
      } else if (propertyPath === "isAutoCapture") {
        updatedData.cameraDetails.isAutoCapture = newState;
      } else if (propertyPath === "isWebViewInject") {
        updatedData.cameraDetails.isWebViewInject = newState;
      }
      return updatedData;
    });
  };
  const handleCameraChange = (
    fieldName: any,
    valueOrEvent: string | React.ChangeEvent<HTMLInputElement>
  ) => {
    const value =
      typeof valueOrEvent === "string"
        ? valueOrEvent
        : valueOrEvent.target.value;
    setData({
      ...data,
      cameraDetails: {
        ...data.cameraDetails,
        [fieldName]: value,
      },
    });
  };
  return (
    <div className="w-full h-full flex flex-col">
      <StepsHeader title={title} />
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[42.35%] h-auto py-[3rem] max-md:w-[60%]">
          <div className="w-full h-auto">
            <form>
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <>
                    <InputFieldWithoutForm
                      labelName={t("web.magix.text.magicname")}
                      type="text"
                      id="MagicName"
                      isRequired={true}
                      value={data.name}
                      onChange={(e) => handleChange("name", e)}
                      error={errors.name}
                    />
                  </>
                )}
              </div>
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <>
                    <TextAreaField
                      labelName={t("web.magix.label.description")}
                      id="description"
                      value={data.description}
                      onChange={(e) => handleChange("description", e)}
                    />
                  </>
                )}
              </div>
              {magicType === "4" && (
                <>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <SelectInputWithoutForm
                        labelName={t("web.label.common.surfacetype")}
                        id={`getStartedUniqueId`}
                        isRequired={true}
                        options={surfaceTypeOptions}
                        defaultValue={getDefaultSurfaceType(data.surfaceType)}
                        getOptionLabel={(option: any) => option.label}
                        getOptionValue={(option: any) => option.value}
                        getSelection={(option: any) =>
                          handleSelectionChange(option.value, "surfaceType")
                        }
                        error={errors.surfaceType}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <EnterTagsInput
                        labelName={t("web.magix.label.defectsType")}
                        idName="defectsType"
                        tags={data.defectsType}
                        setTags={setDefectsTypeData}
                        type={1}
                      />
                    )}
                  </div>
                </>
              )}
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <EnterTagsInput
                    labelName={t("web.magix.label.entertags")}
                    idName="visionEnterTags"
                    tags={data.tags}
                    setTags={setTagsAndUpdateData}
                    type={1}
                  />
                )}
              </div>
              {parsedSideUserDetails.roleId === 10 && (
                <>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.predictionendpoint")}
                        type="text"
                        id="PredictionEndpoint"
                        isRequired={true}
                        value={data.predictionEndpoint || ""}
                        onChange={(e) => handleChange("predictionEndpoint", e)}
                        error={errors.predictionEndpoint}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.predictionkey")}
                        type="text"
                        id="PredictionKey"
                        isRequired={true}
                        value={data.predictionKey || ""}
                        onChange={(e) => handleChange("predictionKey", e)}
                        error={errors.predictionKey}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="isAuto"
                        size="large"
                        label={t("web.magix.text.autocapture")}
                        isButtonChecked={data?.cameraDetails?.isAutoCapture}
                        getIsChecked={(newState) =>
                          handleToggleChange("isAutoCapture", newState)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.autoCaptureType")}
                        type="text"
                        id="AutoCaptureTypFDSAe"
                        value={data.cameraDetails.autoCaptureType || ""}
                        onChange={(e) =>
                          handleCameraChange("autoCaptureType", e)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.autoCaptureConfiguration")}
                        type="text"
                        id="autoCaptureConfiguration"
                        isRequired={true}
                        value={
                          data?.cameraDetails?.autoCaptureConfiguration || ""
                        }
                        onChange={(e) =>
                          handleCameraChange("autoCaptureConfiguration", e)
                        }
                        error={errors.autoCaptureConfiguration}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="flashlightMode"
                        size="large"
                        label={t("web.magix.text.flashlightMode")}
                        isButtonChecked={data?.cameraDetails?.flashlightMode}
                        getIsChecked={(flashlightMode) =>
                          handleToggleChange("flashlightMode", flashlightMode)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.resolutionType")}
                        type="text"
                        id="resolutionType"
                        isRequired={true}
                        value={data?.cameraDetails?.resolutionType || ""}
                        onChange={(e) =>
                          handleCameraChange("resolutionType", e)
                        }
                        error={errors.resolutionType}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.resolutionWidth")}
                        type="text"
                        id="resolutionWidth"
                        isRequired={true}
                        value={data?.cameraDetails?.resolutionWidth || ""}
                        onChange={(e) =>
                          handleCameraChange("resolutionWidth", e)
                        }
                        error={errors.resolutionWidth}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.resolutionHeight")}
                        type="text"
                        id="resolutionHeight"
                        isRequired={true}
                        value={data?.cameraDetails?.resolutionHeight || ""}
                        onChange={(e) =>
                          handleCameraChange("resolutionHeight", e)
                        }
                        error={errors.resolutionHeight}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.photoResolution")}
                        type="text"
                        id="photoResolution"
                        isRequired={true}
                        value={data?.cameraDetails?.photoResolution || ""}
                        onChange={(e) =>
                          handleCameraChange("photoResolution", e)
                        }
                        error={errors.photoResolution}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.videoResolution")}
                        type="text"
                        id="videoResolution"
                        isRequired={true}
                        value={data?.cameraDetails?.videoResolution || ""}
                        onChange={(e) =>
                          handleCameraChange("videoResolution", e)
                        }
                        error={errors.videoResolution}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.videoLength")}
                        type="text"
                        id="videoLength"
                        value={data?.cameraDetails?.videoLength || ""}
                        onChange={(e) => handleCameraChange("videoLength", e)}
                        error={errors.videoLength}
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="orientation"
                        size="large"
                        label={t("web.magix.text.orientation")}
                        isButtonChecked={data?.cameraDetails?.orientation}
                        getIsChecked={(orientation) =>
                          handleToggleChange("Orientation", orientation)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="iOrientationLockerDisabled"
                        size="large"
                        label={t("web.magix.text.iOrientationLockerDisabled")}
                        isButtonChecked={
                          data?.cameraDetails?.iOrientationLockerDisabled
                        }
                        getIsChecked={(iOrientationLockerDisabled) =>
                          handleToggleChange(
                            "iOrientationLockerDisabled",
                            iOrientationLockerDisabled
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="isPreCheck"
                        size="large"
                        label={t("web.magix.text.isPreCheck")}
                        isButtonChecked={data?.cameraDetails?.isPreCheck}
                        getIsChecked={(isPreCheck) =>
                          handleToggleChange("IsPreCheck", isPreCheck)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="isFlashlightAccessDisabled"
                        size="large"
                        label={t("web.magix.text.isFlashlightAccessDisabled")}
                        isButtonChecked={
                          data?.cameraDetails?.isFlashlightAccessDisabled
                        }
                        getIsChecked={(isFlashlightAccessDisabled) =>
                          handleToggleChange(
                            "isFlashlightAccessDisabled",
                            isFlashlightAccessDisabled
                          )
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <ToggleButtonWithoutForm
                        id="isWebViewInject"
                        size="large"
                        label={t("web.magix.text.isWebViewInject")}
                        isButtonChecked={data?.cameraDetails?.isWebViewInject}
                        getIsChecked={(isWebViewInject) =>
                          handleToggleChange("isWebViewInject", isWebViewInject)
                        }
                      />
                    )}
                  </div>
                  <div className="mb-[3.8rem]">
                    {isLoading ? (
                      <Skeleton width="100%" height={47} borderRadius={3} />
                    ) : (
                      <InputFieldWithoutForm
                        labelName={t("web.magix.text.webViewInjectScript")}
                        type="text"
                        id="webViewInjectScript"
                        isRequired={true}
                        value={data?.cameraDetails?.webViewInjectScript || ""}
                        onChange={(e) =>
                          handleCameraChange("webViewInjectScript", e)
                        }
                        error={errors.webViewInjectScript}
                      />
                    )}
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>

      <ModalPageFooter
        handleSaveDraft={handleSaveDraft}
        footerType="steps"
        backbtn={true}
        goToPrevious={handlePrevious}
        goToNext={handleNextStep}
        setData={setData}
        data={data}
        isLoading={isLoading}
      />
    </div>
  );
}

export default CustomMagicStepOne;
