import React, { createContext, useContext, useState, useEffect } from "react";
import Authentication from "../auth/Authentication";
import { useNavigate, useLocation } from "react-router-dom";

interface AuthContextProps {
  isAuthenticated: boolean;
  isDomainAuthenticated: boolean;
  login: (type: number) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const authService = Authentication();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isDomainAuthenticated, setIsDomainAuthenticated] =
    useState<boolean>(true);

  useEffect(() => {
    checkAuthStatus();
  }, [location.pathname]); // Use location.pathname as a dependency to re-run the effect on route change
  const checkAuthStatus = () => {
    const domainToken = sessionStorage.getItem("SessionDomainToken");
    const loginToken = sessionStorage.getItem("SessionLoginToken");
    const sideBarDetails = sessionStorage.getItem("sideBarDetails");

    if (window.location.pathname === "/login") {
      if (domainToken) {
        setIsDomainAuthenticated(true);
      }
      if (domainToken) {
        setIsAuthenticated(true);
      } else {
        authService.logout();
      }
    } else {
      if (domainToken && loginToken && sideBarDetails) {
        setIsAuthenticated(true);
        setIsDomainAuthenticated(true);
      } else {
        authService.logout();
      }
    }
  };
  const login = (type: number) => {
    authService.login(type);
    setIsAuthenticated(true);
    if (type === 1) {
      setIsDomainAuthenticated(true);
    }
  };

  const logout = () => {
    authService.logout();
    setIsAuthenticated(false);
    setIsDomainAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isDomainAuthenticated, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
