import { useTranslation } from "react-i18next";
import { TextBtn } from "../../form/text-btn";
import { useMyContext } from "../../my-context/MyContext";
import Skeleton from "react-loading-skeleton";

function ModalPageFooter({
  footerType,
  goToNext,
  goToPrevious,
  backbtn,
  isFinalStep,
  handleCancel,
  handleSubmit,
  setData,
  data,
  isLoading,
  handleSaveDraft,
  isImageLoader,
}: any) {
  const { t } = useTranslation();
  const { isExternalUser } = useMyContext();
  const handleNextClick = () => {
    if (setData) {
      setData(data);
    }
    if (goToNext) {
      setData(data);
      goToNext();
    }
  };

  const handleSaveDraftClick = () => {
    if (setData) {
      // Call setData function with the updated data object
      setData(data);
      handleSaveDraft(isFinalStep);
    }
    // Add other action logic if needed
  };
  const handleSaveClick = () => {
    handleSubmit?.();
  };

  const handleFinalStepClick = () => {
    if (setData) {
      // Call setData function with the updated data object
      setData(data);
    }
    if (goToNext) {
      goToNext();
    }
  };
  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto pt-[2.2rem] pb-[4rem] border-t border-io-gray-c8 dark:border-io-black-48">
        {footerType === "steps" && (
          <div className="w-full h-auto flex justify-between items-center">
            <div className="w-1/2 h-auto">
              <div className="w-full h-auto">
                {!backbtn && (
                  <TextBtn
                    label={t("web.common.button.back")}
                    btnStyle="fill-secondary"
                    btnType="button"
                    handleClick={() => goToPrevious?.()}
                  />
                )}
              </div>
            </div>
            <div className="w-1/2 h-auto">
              <div className="w-full h-auto flex justify-end items-center gap-5">
                {!isFinalStep && !isExternalUser && (
                  <TextBtn
                    label={t("web.common.button.savedraft")}
                    btnType="button"
                    btnStyle="border-primary"
                    handleClick={handleSaveDraftClick}
                    isLoading={isLoading}
                  />
                )}
                {!isFinalStep && (
                  <TextBtn
                    label={t("web.common.button.next")}
                    btnStyle="fill-primary"
                    btnType="button"
                    handleClick={() => goToNext?.()}
                    isLoading={isLoading}
                  />
                )}
                {isFinalStep && !isExternalUser && (
                  <TextBtn
                    label={t("web.common.button.submit")}
                    btnStyle="fill-primary"
                    btnType="button"
                    handleClick={handleSaveDraftClick}
                    isLoading={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {footerType === "form" && (
          <div className="w-full h-auto">
            <div className="w-full h-auto flex justify-start items-center gap-5">
              {isImageLoader ? (
                <>
                  <Skeleton width={100} height={40} borderRadius={3} />
                  <Skeleton width={100} height={40} borderRadius={3} />
                </>
              ) : (
                <>
                  <TextBtn
                    label={t("web.common.button.cancel")}
                    btnStyle="fill-secondary"
                    btnType="button"
                    handleClick={() => handleCancel?.()}
                  />
                  {!isExternalUser && (
                    <TextBtn
                      label={t("web.common.button.save")}
                      btnStyle="fill-primary"
                      btnType="submit"
                      handleClick={handleSaveClick}
                      isLoading={isLoading}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ModalPageFooter;
