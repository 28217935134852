import { useEffect, useRef, useState } from "react";
import ProcessTab from "../../components/UI/process-tab";
import PageHeader from "../../components/global-layout/page-header";
import { AILogicStudioPageHeaderIcon } from "../../components/svg";
import SurveyGrid from "../../components/section/ai-logic-studio/survey-grid";
import SubProcessGrid from "../../components/section/ai-logic-studio/sub-process-grid";
import GridToolBar from "../../components/UI/grid-toolbar";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import SurveyCards from "../../components/section/ai-logic-studio/survey-cards";
import SubProcessCards from "../../components/section/ai-logic-studio/sub-process-cards";
import DeleteUser from "../../components/section/user-management/delete-user";
import GridColumn from "../../components/section/user-management/grid-columns";
import GetStartedGrid from "../../components/section/ai-logic-studio/get-started-grid";
import GetStartedCards from "../../components/section/ai-logic-studio/get-started-cards";
import excelExport from "../../services/excelExport";
import {
  startupScreenServices,
  screenMapping,
  screenCollectionServices,
} from "../../services/InspectionOneServices";
import ErrorPopup from "../../components/UI/error-popup";
import PageLoader from "../../components/UI/page-loader";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../components/my-context/MyContext";

function AISubLogicStudioWrapper({
  surveyUpdated,
  getStartedUpdated,
  subProcessUpdated,
  setSurveyUpdated,
  setSubProcessUpdated,
  setGetStartedUpdated,
}: any) {
  const { isExternalUser } = useMyContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { logicId } = useParams();
  const { pathname } = useLocation();
  let siteTab = "";
  if (pathname.includes("/new-survey/")) {
    siteTab = "screencollection";
  } else if (pathname.includes("/new-sub-process/")) {
    siteTab = "screenmapping";
  } else {
    siteTab = "startupscreen";
  }

  const [selectedTab, setSelectedTab] = useState<string>(siteTab);
  const [selectedValue, setSelectedValue] = useState<string>(
    "web.startup.title.name"
  );
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const initialRenderRef = useRef(true);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [surveyData, setSurveyData] = useState<any>([]);
  const [getStartedData, setGetStartedData] = useState<any>([]);
  const [subProcessData, setSubProcessData] = useState<any>([]);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "name",
    "description",
    "createdAt",
  ]);
  const columnsStartupScreen = [
    {
      label: "Startup Screen Name",
      token: "web.startupscreen.label.name",
      key: "name",
    },
    {
      label: "Startup Screen Description",
      token: "web.startupscreen.label.desc",
      key: "description",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  const columnScreenCollection = [
    {
      label: "Screen Collection Name",
      token: "web.screencollection.label.name",
      key: "name",
    },
    {
      label: "Screen Collection Description",
      token: "web.screencollection.label.desc",
      key: "description",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  const columnsScreenmapping = [
    {
      label: "Screen Mapping Name",
      token: "web.screenmapping.label.name",
      key: "name",
    },
    {
      label: "Screen Mapping Description",
      token: "web.screenmapping.label.desc",
      key: "description",
    },
    // {
    //   label: "Startup Screen",
    //   token: "web.screenmapping.label.startupscreen",
    //   key: "getStartedName",
    // },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  const [inititalColumnData, setInitialColumnData] = useState<any>([]);
  //#region Common Function
  useEffect(() => {
    switch (selectedTab) {
      case "startupscreen":
        setInitialColumnData(columnsStartupScreen);
        setSelectedColumns(["name", "description", "createdAt"]);
        //setSelectedColumns(["name", "description", "default", "createdAt"]);
        break;
      case "screenmapping":
        setInitialColumnData(columnsScreenmapping);
        setSelectedColumns([
          "name",
          "description",
          "getStartedName",
          "createdAt",
        ]);
        break;
      default:
        setInitialColumnData(columnScreenCollection);
        setSelectedColumns(["name", "description", "createdAt"]);
        break;
    }
  }, [selectedTab]);
  const handleSelectedType = (value: string, token: string) => {
    setSelectedTab(value);
    setSelectedValue(token);
  };
  const handleEdit = (items: any) => {
    if (selectedTab === "startupscreen") {
      navigate(`${pathname}/new-get-started/${items.uniqueId}`);
    } else if (selectedTab === "screenmapping") {
      navigate(`${pathname}/new-sub-process/${items.uniqueId}`);
    } else {
      navigate(`${pathname}/new-survey/${items.uniqueId}`);
    }
  };

  const handleCreate = () => {
    if (selectedTab === "startupscreen") {
      navigate(`${pathname}/new-get-started/new`);
    } else if (selectedTab === "screenmapping") {
      navigate(`${pathname}/new-sub-process/new`);
    } else {
      navigate(`${pathname}/new-survey/new`);
    }
  };

  const handleRefresh = () => {
    setSurveyData([]);
    setGetStartedData([]);
    setSubProcessData([]);
    setStartIndex(0);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
    setPageLoader(false);
    setSearchedQuery("");
    setSearchedInputVal("");
    setResetSearch(true);
  };
  const handleNavigate = (cardData: any) => {
    if (selectedTab === "startupscreen") {
      navigate(`${pathname}/get-started/${cardData.uniqueId}`);
    } else if (selectedTab === "screenmapping") {
      navigate(`${pathname}/mapping/${cardData.uniqueId}`);
    } else {
      navigate(`${pathname}/survey/${cardData.uniqueId}`);
    }
  };
  const [deleteData, setDeleteData] = useState<any>(null);
  const handleDelete = async (items: any) => {
    setDeleteData(items);
    setShowDeletePopup(true);
  };
  const handleCommonDelete = async (items: any) => {
    setIsDeleted(false);
    try {
      setShowDeletePopup(false);
      setPageLoader(true);
      const requestJson = {
        uniqueId: items.uniqueId.trim(),
        isDeleted: 1,
        name: "",
        description: "",
        icon: "",
        logicId: logicId,
      };
      let response;
      if (selectedTab === "startupscreen") {
        response = await startupScreenServices.StartupScreenAction(requestJson);
      } else if (selectedTab === "screenmapping") {
        response = await screenMapping.ScreenMappingAction(requestJson);
      } else {
        response = await screenCollectionServices.ScreenCollectionAction(
          requestJson
        );
      }
      if (response.success) {
        if (selectedTab === "startupscreen") {
          setGetStartedData([]);
        } else if (selectedTab === "screenmapping") {
          setSubProcessData([]);
        } else {
          setSurveyData([]);
        }
        setStartIndex(0);
        setIsDeleted((prev) => !prev);
        setPageLoader(false);
      } else {
        setPageLoader(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setPageLoader(false);
    }
  };
  const checkLocation = () => {
    if (
      !pathname.includes("/survey/") &&
      !pathname.includes("/get-started/") &&
      !pathname.includes("/mapping/")
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  //#endregion
  //#region Use Effect
  useEffect(() => {
    const baseTools = isExternalUser
      ? ["search", "export", "refresh"]
      : ["create", "search", "export", "refresh"];

    const toolItems = isGridView ? baseTools : [...baseTools, "columns"];

    setToolItems(toolItems);
  }, [isGridView]);

  // useEffect(
  //   () =>
  //     setSelectedTab(
  //       pathname.includes("/new-get-started/")
  //         ? "startupscreen"
  //         : pathname.includes("/new-sub-process/")
  //         ? "screenmapping"
  //         : "screencollection"
  //     ),
  //   []
  // );

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setSurveyData([]);
      setGetStartedData([]);
      setSubProcessData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    if (
      surveyUpdated === true ||
      getStartedUpdated === true ||
      subProcessUpdated === true
    ) {
      setSurveyData([]);
      setGetStartedData([]);
      setSubProcessData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [surveyUpdated, getStartedUpdated, subProcessUpdated]);

  useEffect(() => {
    if (selectedTab === "startupscreen") {
      getStartedListFetch(searchedInputVal);
    } else if (selectedTab === "screenmapping") {
      subProcessListFetch(searchedInputVal);
    } else {
      surveyListFetch(searchedInputVal);
    }
    setSurveyUpdated(undefined);
    setSubProcessUpdated(undefined);
    setGetStartedUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isUpdated,
    isRefreshed,
  ]);

  useEffect(() => {
    if (!initialRenderRef.current) {
      setStartIndex(0);
      setSurveyData([]);
      setGetStartedData([]);
      setSubProcessData([]);
      if (selectedTab === "startupscreen") {
        getStartedListFetch(searchedInputVal);
      } else if (selectedTab === "screenmapping") {
        subProcessListFetch(searchedInputVal);
      } else {
        surveyListFetch(searchedInputVal);
      }
      // setIsUpdated(false);
    } else {
      initialRenderRef.current = false;
    }
  }, [selectedTab]);
  //#endregion
  //#region list Fetch
  const surveyListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        logicUniqueId: logicId,
        filterDetails: {
          searchValue: query,
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
          sorting: sorting,
          columnName: sortingColumnName,
        },
      };
      const response: any = await screenCollectionServices.ScreenCollectionList(
        requestJson
      );
      if (response.success) {
        const responseData = response.data.listDetails;
        const totalCount = response.data.totalCount;
        setSurveyData((prevData: any) => [...prevData, ...responseData]);
        setTotalCount(totalCount);
        setIsLoading(false);
        setIsSkeletonLoader(false);
        if (responseData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  const getStartedListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        logicUniqueId: logicId,
        filterDetails: {
          searchValue: query,
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
          sorting: sorting,
          columnName: sortingColumnName,
        },
      };
      const response: any = await startupScreenServices.StartupScreenList(
        requestJson
      );
      if (response.success) {
        const responseData = response.data.listDetails;
        const totalCount = response.data.totalCount;
        setGetStartedData((prevData: any) => [...prevData, ...responseData]);
        setTotalCount(totalCount);
        setIsLoading(false);
        setIsSkeletonLoader(false);
        if (responseData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
      setIsSkeletonLoader(false);
    }
  };
  const subProcessListFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        logicUniqueId: logicId,
        filterDetails: {
          searchValue: query,
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
          sorting: sorting,
          columnName: sortingColumnName,
        },
      };
      const response: any = await screenMapping.ScreenMappingList(requestJson);
      if (response.success) {
        const responseData = response.data.listDetails;
        const totalCount = response.data.totalCount;
        setSubProcessData((prevData: any) => [...prevData, ...responseData]);
        setTotalCount(totalCount);
        setIsLoading(false);
        setIsSkeletonLoader(false);
        if (responseData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsSkeletonLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };
  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setSurveyData([]);
    setGetStartedData([]);
    setSubProcessData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export

  const [isExcelLoading, setExcelLoading] = useState(false);

  const handleExcel = async () => {
    setExcelLoading(true);
    let screenName = "startupscreen";
    let fileName = "Startup Screen List";
    let sheetName = "Startup_Screen_List";
    let columnData = columnsStartupScreen;
    if (selectedTab === "screencollection") {
      screenName = "screencollection";
      fileName = "Screen Collection List";
      sheetName = "Screen_Collection_List";
      columnData = columnScreenCollection;
    } else if (selectedTab === "screenmapping") {
      screenName = "screenmapping";
      fileName = "Screen Mapping List";
      sheetName = "Screen_Mapping_List";
      columnData = columnsScreenmapping;
    }

    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnData,
        logicId
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };

  //#endregion
  const renderStepContent = () => {
    switch (selectedTab) {
      case "screencollection":
        return (
          <>
            {isGridView ? (
              <SurveyCards
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={surveyData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                noDataFound={noDataFound}
              />
            ) : (
              <SurveyGrid
                selectedColumns={selectedColumns}
                rowSelection={false}
                moreOptions={true}
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={surveyData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                sorting={sorting}
                sortingColumnName={sortingColumnName}
                handleSort={handleSort}
                handleCheckboxChange={handleCheckboxChange}
                noDataFound={noDataFound}
              />
            )}
            {/* <CreateFloatingButton
              icon={<CreateNewUserButtonIcon />}
              handleClick={() => navigate(`${pathname}/new-survey/new`)}
            /> */}
          </>
        );
      case "startupscreen":
        return (
          <>
            {isGridView ? (
              <GetStartedCards
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={getStartedData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                noDataFound={noDataFound}
              />
            ) : (
              <GetStartedGrid
                selectedColumns={selectedColumns}
                rowSelection={false}
                moreOptions={true}
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={getStartedData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                sorting={sorting}
                sortingColumnName={sortingColumnName}
                handleSort={handleSort}
                handleCheckboxChange={handleCheckboxChange}
                noDataFound={noDataFound}
              />
            )}
            {/* <CreateFloatingButton
              icon={<CreateNewUserButtonIcon />}
              handleClick={() => navigate(`${pathname}/new-get-started/new`)}
            /> */}
          </>
        );
      case "screenmapping":
        return (
          <>
            {isGridView ? (
              <SubProcessCards
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={subProcessData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                noDataFound={noDataFound}
              />
            ) : (
              <SubProcessGrid
                selectedColumns={selectedColumns}
                rowSelection={false}
                moreOptions={true}
                handleNavigate={handleNavigate}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                rowData={subProcessData}
                scrollGroundRef={scrollGroundRef}
                handleScroll={handleScroll}
                initialLoading={initialLoading}
                isLoading={isLoading}
                limit={limit}
                finalFetch={finalFetch}
                sorting={sorting}
                sortingColumnName={sortingColumnName}
                handleSort={handleSort}
                handleCheckboxChange={handleCheckboxChange}
                noDataFound={noDataFound}
              />
            )}
            {/* <CreateFloatingButton
              icon={<CreateNewUserButtonIcon />}
              handleClick={() => navigate(`${pathname}/new-sub-process/new`)}
            /> */}
          </>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {checkLocation() ? (
        <div className="w-full h-full flex flex-col">
          <PageHeader
            title={t("web.title.label.sublogic")}
            icon={<AILogicStudioPageHeaderIcon />}
          />
          <ProcessTab
            selectedTab={selectedTab}
            selectedType={handleSelectedType}
            isLoading={isLoading}
          />
          <div className="w-full flex-1">
            <div className="w-full h-full flex flex-col">
              <GridToolBar
                columnCount={totalCount}
                gridType={t(selectedValue)}
                selectView={true}
                tools={toolItems}
                handleAddRow={handleCreate}
                handleDelete={() => setShowDeletePopup(true)}
                handleColumns={() => setShowColumns(true)}
                isGridView={isGridView}
                fetchData={(query: any) => setSearchedQuery(query)}
                getSearchedQuery={(query: any) => setSearchedQuery(query)}
                handleRefresh={handleRefresh}
                handleExcel={handleExcel}
                isExcelLoading={isExcelLoading}
                resetSearch={resetSearch}
                isSkeletonLoader={isSkeletonLoader}
                setIsGridView={(isChecked: any) => setIsGridView(isChecked)}
              />
              <div className="w-full flex-1">{renderStepContent()}</div>
            </div>
          </div>
        </div>
      ) : null}
      {showDeletePopup && (
        <DeleteUser
          handleCancel={() => setShowDeletePopup(false)}
          rowData={deleteData}
          handleDelete={handleCommonDelete}
          actionFlag={selectedTab}
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={inititalColumnData}
          selectedColumns={selectedColumns}
        />
      )}{" "}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
      <Outlet />
    </>
  );
}
export default AISubLogicStudioWrapper;
