import { Fragment } from "react/jsx-runtime";
import NotificationCard from "../../UI/notification-card";
import ModalPageHeader from "../../global-layout/modal-page-header";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import { useTranslation } from "react-i18next";
import { LoginBtn } from "../../form/text-btn";
import { useEffect, useState } from "react";
interface NotificationCardProps {
  title: string;
  description: string;
  recievedTime: string;
  image: string;
}
interface NotificationProps {
  handleClose: () => void;
  handleChangePassword: () => void;
}

function Notification({
  handleClose,
  handleChangePassword,
}: NotificationProps) {
  const { t } = useTranslation();
  const notificationData: NotificationCardProps[] = [];

  const [remainingDays, setRemainingDays] = useState<number | 0>(0);
  const [titleData, setTitle] = useState("");

  const expiryDays = parseInt(process.env.REACT_APP_EXPIRY_DAYS || "5");
  useEffect(() => {
    const days = sessionStorage.getItem("passwordRemainingDays");

    if (days !== null) {
      const remaining = parseInt(days);
      setRemainingDays(remaining);
      if (remaining <= expiryDays) {
        setTitle(`Your password is going to expire in ${remaining} days.`);
      } else {
        setTitle("");
      }
    }
  }, [remainingDays, expiryDays]);

  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-2xl:w-[50%] max-md:w-[85%]">
        <div className="w-full h-full flex flex-col">
          <ModalPageHeader
            title={t("web.label.common.notification")}
            notificationCount={remainingDays <= expiryDays ? 1 : 0}
            handleClose={() => handleClose()}
          />
          <div className="w-full h-full flex-auto overflow-auto">
            <div className="w-full h-full px-[4.7rem] pt-[3.7rem]">
              <div className="w-full h-auto">
                {remainingDays !== null && remainingDays <= expiryDays && (
                  <div className="w-full h-auto border-b border-io-gray-c8 dark:border-io-black-48">
                    <div className="w-full h-auto flex justify-center items-start gap-7 py-[2.8rem]">
                      <div className="w-[4rem] h-[4rem]">
                        <img
                          src={require("../../../assets/images/changepassword.png")}
                          alt="Change Password"
                        />
                      </div>
                      <div className="flex-1 h-auto">
                        <div className="w-full h-auto flex justify-between items-center">
                          <div className="w-auto">
                            <h4 className="text-[2.2rem] font-inter font-medium text-io-black dark:text-io-white inline-block">
                              Time to Renew
                            </h4>
                          </div>
                          <div className="w-auto">
                            <div className="w-[1.1rem] h-[1.1rem] rounded-full bg-io-green"></div>
                          </div>
                        </div>
                        <p className="text-[1.4rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-5">
                          {titleData}
                        </p>
                        <div className="w-auto inline-block">
                          <LoginBtn
                            label="Change Password"
                            btnType="button"
                            btnStyle="fill-primary"
                            handleClick={handleChangePassword}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {notificationData.map((item, i) => (
                  <Fragment key={i}>
                    <NotificationCard data={item} />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default Notification;
