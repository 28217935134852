import { useRef, useState } from "react";
import GridSearch from "../../form/grid-search";
import IconTextBtn from "../../form/icon-text-btn";
import { CardGridToggleButton } from "../../form/toggle-btn";
import {
  AddIcon,
  ColumnIcon,
  DeleteIcon,
  ExportIcon,
  FilterIcon,
  ImportIcon,
  RefreshIcon,
  SortIcon,
} from "../../svg";
import FilterOptions from "../../form/filter-options";
import GridFilterOptions from "../../form/grid-filter-options";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

function GridToolBar({
  columnCount,
  gridType,
  handleDelete,
  handleColumns,
  handleAddRow,
  tools,
  isGridView,
  selectView,
  fetchData,
  getSearchedQuery,
  handleRefresh,
  handleExcel,
  isExcelLoading,
  resetSearch,
  handleImport,
  handleExcelDownload,
  isSkeletonLoader,
  setIsGridView,
}: any) {
  const { t } = useTranslation();
  const [isContentVisible, setContentVisible] = useState(false);
  const modalRef = useRef<any>(null);

  const handleOptionSelect = (option: any) => {
    console.log("Selected option:", option);
  };
  const exportToExcel = async () => {
    handleExcel();
  };

  const renderTool = (tool: string) => {
    switch (tool) {
      case "create":
        return (
          <div className="w-auto">
            <IconTextBtn
              type="button"
              label={t("web.toolbar.label.create")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<AddIcon />}
              handleClick={handleAddRow}
            />
          </div>
        );
      case "addrow":
        return (
          <div className="w-auto">
            <IconTextBtn
              type="button"
              label={t("web.toolbar.label.addrow")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<AddIcon />}
              handleClick={handleAddRow}
            />
          </div>
        );
      case "search":
        return (
          <div className="w-auto">
            <GridSearch
              onSearch={fetchData}
              getSearchedQuery={getSearchedQuery}
              reset={resetSearch}
              isSkeletonLoader={isSkeletonLoader}
            />
          </div>
        );
      case "sort":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.sort")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<SortIcon />}
            />
          </div>
        );
      case "import":
        return (
          <div className="w-auto">
            <label htmlFor="importExcelFile">
              <div className="w-auto flex justify-center items-center gap-3 border-none bg-none">
                <div className="w-[1.8rem] h-auto">
                  <ImportIcon />
                </div>
                <span className="text-[1.8rem] font-inter font-normal text-io-gray-47 dark:text-io-black-d1">
                  {t("web.common.title.import")}
                </span>
              </div>
            </label>
            <input
              type="file"
              accept=".xlsx"
              className="hidden w-0 h-0"
              id="importExcelFile"
              onChange={handleImport}
            />
          </div>
        );
      case "filter":
        return (
          <div className="w-auto relative" ref={modalRef}>
            <IconTextBtn
              label={t("web.toolbar.label.filter")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<FilterIcon />}
              handleClick={() => setContentVisible(!isContentVisible)}
            />
            {isContentVisible && (
              <div className="w-auto h-auto absolute top-full right-[-1rem] pt-5 z-[5]">
                <FilterOptions onSelectOptions={handleOptionSelect} />
              </div>
            )}
          </div>
        );
      case "gridfilter":
        return (
          <div className="w-auto relative" ref={modalRef}>
            <IconTextBtn
              label={t("web.toolbar.label.filter")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<FilterIcon />}
              handleClick={() => setContentVisible(!isContentVisible)}
            />
            {isContentVisible && (
              <div className="w-auto h-auto absolute top-full right-[-1rem] pt-5 z-[5]">
                <GridFilterOptions
                  handleClose={() => setContentVisible(!isContentVisible)}
                />
              </div>
            )}
          </div>
        );
      case "delete":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.delete")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<DeleteIcon />}
              handleClick={() => handleDelete?.()}
            />
          </div>
        );
      case "export":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.export")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<ExportIcon />}
              handleClick={() => exportToExcel?.()}
              isLoading={isExcelLoading}
            />
          </div>
        );
      case "columns":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.columns")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<ColumnIcon />}
              handleClick={() => handleColumns?.()}
            />
          </div>
        );
      case "refresh":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.refresh")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<RefreshIcon />}
              handleClick={handleRefresh}
            />
          </div>
        );
      case "sampleexcel":
        return (
          <div className="w-auto">
            <IconTextBtn
              label={t("web.toolbar.label.sampleExcel")}
              disabled={isSkeletonLoader}
              btnSize="large"
              icon={<ExportIcon />}
              handleClick={handleExcelDownload}
              isLoading={isExcelLoading}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full h-auto mb-[1rem]">
      <div className="w-full h-auto flex justify-between items-center">
        <div className="w-auto">
          {isSkeletonLoader ? (
            <Skeleton width={100} height={30} borderRadius={5} />
          ) : (
            <p className="text-[1.6rem] font-inter font-normal text-io-gray-47 dark:text-io-black-d1 italic">
              {columnCount > 0 ? columnCount : "0"} {gridType}{" "}
              {t("web.toolbar.label.found")}
            </p>
          )}
        </div>
        <div className="w-auto h-auto">
          <div className="w-auto flex justify-end items-center gap-[3.4rem] max-md:gap-[2.5rem]">
            {tools.map((tool: string, index: number) => (
              <div key={index} className="w-auto">
                {renderTool(tool)}
              </div>
            ))}
            {selectView && (
              <div className="w-auto">
                {isSkeletonLoader ? (
                  <Skeleton width={100} height={30} borderRadius={5} />
                ) : (
                  <CardGridToggleButton
                    isChecked={isGridView}
                    handleToggle={() => setIsGridView(!isGridView)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default GridToolBar;
