import { Fragment, useEffect, useState, useCallback } from "react";
import { AddIcon, PageModalCloseIcon } from "../../svg";
import CheckBoxGroup from "../../form/checkbox-group";
import { SelectInput } from "../../form/select-input";
import IconBtn from "../../form/icon-btn";
import IconTextBtn from "../../form/icon-text-btn";
import { useTranslation } from "react-i18next";

interface OptionItem {
  id: any;
  value?: any;
  label: any;
}
interface BindingOptions {
  serviceBindingsDetails: any[];
  masterBindingsDetails: any[];
}

interface SelectedScreen {
  questionDetails: any[];
}

function QuestionMoreInfo({
  fields,
  masterFields,
  errors,
  control,
  setValue,
  appendApiRequiredFields,
  appendMasterFields,
  handleAddBindParameter,
  handleAddMasterBindParameter,
  handleBinParameterDelete,
  handleMasterBinParameterDelete,
  selectedCheckboxOptions,
  bindingoptions,
  selectedScreen,
  watchApiRequired,
  watchMasterTableOne,
  responseSettingsType,
  setAPIAndMasterSchema,
  setAPIRequiredDefault,
  setMasterDefault,
  setServiceBindParamval,
  setMasterBindParamval,
  replaceAPIRequired,
  replaceMasterTable,
  questionMoreInfoOptions,
  updatedQuestionDetails,
  multipleOptionValidation,
}: any) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<OptionItem[]>([]);
  const [serviceDetails, setServiceDetails] = useState<OptionItem[]>([]);
  const [masterDetails, setMasterDetails] = useState<OptionItem[]>([]);
  const [defaultService, setDefaultService] = useState<OptionItem | null>(null);
  const [defaultMaster, setDefaultMaster] = useState<OptionItem | null>(null);

  const [modAPIReq, setModApiReq] = useState<any>(undefined);
  const [modMaster, setModmaster] = useState<any>([]);

  useEffect(() => {}, [watchApiRequired, modAPIReq]);

  const mapToOptionItems = useCallback(
    (items: any[], label: string): OptionItem[] =>
      items.map(
        (item): OptionItem => ({
          id: item.id,
          value: item.id,
          label: item[label],
        })
      ),
    []
  );

  const serviceBindingsDetails: OptionItem[] = mapToOptionItems(
    bindingoptions.serviceBindingsDetails,
    "name"
  );

  const masterBindingsDetails: OptionItem[] = mapToOptionItems(
    bindingoptions.masterBindingsDetails,
    "name"
  );
  const questionDetails: OptionItem[] = mapToOptionItems(
    updatedQuestionDetails,
    "title"
  );

  const fetchAndSetDetails = useCallback(
    (
      watchItem: any,
      bindingKey: string,
      setDetails: React.Dispatch<React.SetStateAction<OptionItem[]>>
    ) => {
      if (watchItem?.id) {
        const bindingDetails = bindingoptions[bindingKey];
        const details = bindingDetails.find(
          (service: any) => service.id === watchItem.id
        );
        const bindDetails = details ? details.bindDetails : [];
        const finalDetails: OptionItem[] = bindDetails.map(
          (item: any): OptionItem => ({
            id: item.id,
            value: item.id,
            label: item.name,
          })
        );
        setDetails(finalDetails);
      }
    },
    [bindingoptions]
  );

  useEffect(() => {
    // if (watchApiRequired?.id !== responseSettingsType.serviceAccessKey) {
    //   replaceAPIRequired?.();
    // }

    fetchAndSetDetails(
      watchApiRequired,
      "serviceBindingsDetails",
      setServiceDetails
    );
  }, [watchApiRequired, fetchAndSetDetails]);

  useEffect(() => {
    // if (watchMasterTableOne?.id !== responseSettingsType.masterAccessKey) {
    //   replaceMasterTable?.();
    // }

    fetchAndSetDetails(
      watchMasterTableOne,
      "masterBindingsDetails",
      setMasterDetails
    );
  }, [watchMasterTableOne, fetchAndSetDetails]);

  useEffect(() => {
    selectedOptions.forEach((option: OptionItem) => {
      if (option.id === "apirequired" && fields?.length === 0) {
        if (responseSettingsType?.serviceBindValues?.length === 0) {
          appendApiRequiredFields?.();
        }
      }
      if (option.id === "mastertable" && masterFields?.length === 0) {
        if (responseSettingsType?.masterBindValues?.length === 0) {
          appendMasterFields?.();
        }
      }
    });
  }, [selectedOptions]);

  const handleChangeCheckbox = (options: OptionItem[]) => {
    setSelectedOptions(options);
    selectedCheckboxOptions?.(options);
  };

  useEffect(() => {
    const defaultService = responseSettingsType?.serviceAccessKey
      ? serviceBindingsDetails.find(
          (option) => option.id === responseSettingsType.serviceAccessKey
        ) || null
      : null;

    setAPIRequiredDefault?.(defaultService);
    setDefaultService(defaultService);

    if (defaultService) {
      fetchAndSetDetails(
        defaultService,
        "serviceBindingsDetails",
        setServiceDetails
      );
    }
  }, [responseSettingsType]);

  useEffect(() => {
    const defaultMaster = responseSettingsType?.masterAccessKey
      ? masterBindingsDetails.find(
          (option) => option.id === responseSettingsType.masterAccessKey
        ) || null
      : null;
    setMasterDefault?.(defaultMaster);
    setDefaultMaster(defaultMaster);

    if (defaultMaster) {
      fetchAndSetDetails(
        defaultMaster,
        "masterBindingsDetails",
        setMasterDetails
      );
    }
  }, [responseSettingsType]);

  useEffect(() => {
    if (
      updatedQuestionDetails.length > 0 &&
      responseSettingsType?.serviceBindValues.length > 0
    ) {
      const modifiedServiceDetails: any = [];
      responseSettingsType?.serviceBindValues.forEach(
        (serviceBindValue: any) => {
          const serviceBindingDetail = bindingoptions[
            "serviceBindingsDetails"
          ].find(
            (item: any) => item.id === responseSettingsType.serviceAccessKey
          );

          const questDet = updatedQuestionDetails.map(
            (item: any): OptionItem => ({
              id: item.id,
              value: item.id,
              label: item.title,
            })
          );

          const serviceBindParameterTwo = questDet.find(
            (option: any) => option.id === serviceBindValue.questionId
          );

          if (serviceBindingDetail) {
            const defaultServiceDetail = serviceBindingDetail.bindDetails.find(
              (option: any) => option.id === serviceBindValue.key
            );

            if (defaultServiceDetail) {
              const serviceBindParameterOne = {
                ...defaultServiceDetail,
                value: defaultServiceDetail.id,
                label: defaultServiceDetail.name,
              };
              modifiedServiceDetails.push({
                serviceBindParameterOne,
                serviceBindParameterTwo,
              });
            }
          }
        }
      );
      setModApiReq(modifiedServiceDetails);
      setServiceBindParamval?.(modifiedServiceDetails);
    }
  }, [responseSettingsType, updatedQuestionDetails]);
  useEffect(() => {
    if (
      updatedQuestionDetails.length > 0 &&
      responseSettingsType?.masterBindValues.length > 0
    ) {
      const modifiedMasterDetails: any = [];

      responseSettingsType?.masterBindValues.forEach((masterBindValue: any) => {
        const masterBindingDetail = bindingoptions[
          "masterBindingsDetails"
        ].find((item: any) => item.id === responseSettingsType.masterAccessKey);
        const questDet = updatedQuestionDetails.map(
          (item: any): OptionItem => ({
            id: item.id,
            value: item.id,
            label: item.title,
          })
        );
        const masterBindParameterTwo = questDet.find(
          (option: any) => option.id === masterBindValue.questionId
        );

        if (masterBindingDetail) {
          const defaultMasterDetail = masterBindingDetail.bindDetails.find(
            (option: any) => option.id === masterBindValue.key
          );

          if (defaultMasterDetail) {
            const masterBindParameterOne = {
              ...defaultMasterDetail,
              value: defaultMasterDetail.id,
              label: defaultMasterDetail.name,
            };
            modifiedMasterDetails.push({
              masterBindParameterOne,
              masterBindParameterTwo,
            });
          }
        }
      });
      setModmaster(modifiedMasterDetails);
      setMasterBindParamval?.(modifiedMasterDetails);
    }
  }, [responseSettingsType, updatedQuestionDetails]);

  useEffect(() => {
    const options: OptionItem[] = [];

    if (responseSettingsType?.isApiRequired === 1) {
      options.push(questionMoreInfoOptions[0]);
    }
    if (responseSettingsType?.isMasterTable === 1) {
      options.push(questionMoreInfoOptions[1]);
    }
    setAPIAndMasterSchema?.(options);
    setSelectedOptions(options);
  }, [responseSettingsType]);

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto my-12">
        <CheckBoxGroup
          options={questionMoreInfoOptions}
          name="multipleChoice"
          checked={selectedOptions}
          onOptionChange={(options: any) => handleChangeCheckbox(options)}
        />
      </div>
      <div className="w-full h-auto ">
        {selectedOptions.map((option: any, i: number) => {
          if (option?.id === "apirequired") {
            return (
              <div
                key={i}
                className="w-full h-auto border-b border-io-gray-c8 pb-6 mb-6"
              >
                <div className="w-full my-6">
                  <SelectInput
                    labelName={t("web.logic.label.selectapirequired")}
                    error={errors?.apiRequired?.message}
                    idName="apiRequired"
                    name="apiRequired"
                    control={control}
                    isRequired={false}
                    isSearchable={true}
                    options={serviceBindingsDetails}
                    // options={questionOptions}
                    // defaultValue={defaultService}
                  />
                </div>
                <div className="w-full h-auto mb-6">
                  {fields.map((field: any, j: number) => {
                    return (
                      <div
                        key={j}
                        className="w-full h-auto flex justify-between items-center gap-[2%]"
                      >
                        <div className="w-1/2 my-6">
                          <SelectInput
                            labelName={t("web.logic.label.servicebindparam")}
                            error={
                              errors?.serviceBindParameter?.[j]
                                ?.serviceBindParameterOne?.message
                            }
                            idName={`serviceBindParameter[${j}].serviceBindParameterOne`}
                            name={`serviceBindParameter[${j}].serviceBindParameterOne`}
                            control={control}
                            isRequired={false}
                            isSearchable={true}
                            options={serviceDetails}
                            key={field.id}
                          />
                        </div>
                        <div className="w-1/2 my-6">
                          <SelectInput
                            labelName=""
                            error={
                              errors?.serviceBindParameter?.[j]
                                ?.serviceBindParameterTwo?.message
                            }
                            idName={`serviceBindParameter[${j}].serviceBindParameterTwo`}
                            name={`serviceBindParameter[${j}].serviceBindParameterTwo`}
                            control={control}
                            isRequired={false}
                            isSearchable={true}
                            options={questionDetails}
                            key={field.id}
                          />
                        </div>
                        <div className="w-auto">
                          <IconBtn
                            size="w-[1.6rem]"
                            icon={<PageModalCloseIcon />}
                            handleClick={() => handleBinParameterDelete?.(j)}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <div className="w-full h-auto my-2">
                    <IconTextBtn
                      type="button"
                      label={t("web.logic.text.add")}
                      btnSize="small"
                      icon={<AddIcon />}
                      handleClick={handleAddBindParameter}
                    />
                  </div>
                </div>
              </div>
            );
          } else if (option?.id === "mastertable") {
            return (
              <div key={i} className="w-full h-auto">
                <div className="w-full h-auto flex justify-between items-center gap-[2%]">
                  <div className="w-full my-6">
                    <SelectInput
                      labelName={t("web.logic.label.selectmasterrequired")}
                      error={errors?.masterTableOne?.message}
                      idName="masterTableOne"
                      name="masterTableOne"
                      control={control}
                      isRequired={false}
                      isSearchable={true}
                      options={masterBindingsDetails}

                      // defaultValue={defaultMaster}
                    />
                  </div>
                </div>
                <div className="w-full h-auto border-b border-io-gray-c8 pb-6 mb-6">
                  {masterFields.map((field: any, k: number) => (
                    <div
                      key={k}
                      className="w-full h-auto flex justify-between items-center gap-[2%]"
                    >
                      <div className="w-1/2 my-6">
                        <SelectInput
                          labelName={t("web.logic.label.masterbindparam")}
                          error={
                            errors?.masterBindParameter?.[k]
                              ?.masterBindParameterOne?.message
                          }
                          idName={`masterBindParameter[${k}].masterBindParameterOne`}
                          name={`masterBindParameter[${k}].masterBindParameterOne`}
                          control={control}
                          isRequired={false}
                          isSearchable={true}
                          options={masterDetails}
                          key={field.id}
                        />
                      </div>
                      <div className="w-1/2 my-6">
                        <SelectInput
                          labelName=""
                          error={
                            errors?.masterBindParameter?.[k]
                              ?.masterBindParameterTwo?.message
                          }
                          idName={`masterBindParameter[${k}].masterBindParameterTwo`}
                          name={`masterBindParameter[${k}].masterBindParameterTwo`}
                          control={control}
                          isRequired={false}
                          isSearchable={true}
                          options={questionDetails}
                          key={field.id}
                        />
                      </div>
                      <div className="w-auto">
                        <IconBtn
                          size="w-[1.6rem]"
                          icon={<PageModalCloseIcon />}
                          handleClick={() =>
                            handleMasterBinParameterDelete?.(k)
                          }
                        />
                      </div>
                    </div>
                  ))}
                  <div className="w-full h-auto my-2">
                    <IconTextBtn
                      type="button"
                      label={t("web.logic.text.add")}
                      btnSize="small"
                      icon={<AddIcon />}
                      handleClick={handleAddMasterBindParameter}
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}
export default QuestionMoreInfo;
