import { useEffect, useState } from "react";
import ModalPageWrapper from "../../global-layout/modal-page-wrapper";
import {
  ProfileDropDownIcon,
  SignOutIcon,
  UserManagementIcon,
} from "../../svg";
import Authentication from "../../../auth/Authentication";
import { useTranslation } from "react-i18next";
import defaultUserImage from "../../../assets/images/card-grdi-default.png";

interface Option {
  id: string;
  value: string;
}

function Profile({
  handleViewAccount,
  handleClose,
  setSiteSwitchModal,
  setConfirmSiteData,
}: any) {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);
  const authService = Authentication();
  const signOutClick = () => {
    authService.logout();
  };
  const [selectedOption, setSelectedOption] = useState<any>();
  const siteId = sessionStorage.getItem("siteId");
  var option = authService.decryptData("userSiteDetails", 2);
  var userDetails = authService.decryptData("UserDetails", 2);
  const profileImage = userDetails?.imageUrl ?? <UserManagementIcon />;
  const firstName = userDetails?.firstName ?? "";
  const lastName = userDetails?.lastName ?? "";
  const email = userDetails?.email ?? "";

  const allOption = { id: "all", value: "All" };

  const optionsWithAll = option.length > 2 ? [allOption, ...option] : option;

  useEffect(() => {
    const optionData = option.find((data: any) => data.id === siteId);
    if (optionData) {
      if (userDetails.lastAccess === -1) {
        setSelectedOption(optionsWithAll[0]);
      } else {
        setSelectedOption(optionData);
      }
    } else {
      setSelectedOption(optionsWithAll[0]);
    }
  }, [siteId]);

  const handleOptionClick = async (option: Option) => {
    const lastSwitchId = sessionStorage.getItem("siteSwitchId");
    if (option.id !== lastSwitchId) {
      setConfirmSiteData(option);
      setSelectedOption(option);
      setSiteSwitchModal(true);
    }
  };

  const handleImageError = (event: any) => {
    event.target.src = defaultUserImage;
  };
  return (
    <ModalPageWrapper withoutOverlay={true} onClose={handleClose}>
      <div className="w-[51rem] max-md:w-[48rem] h-auto bg-io-white dark:bg-io-black-15 ml-auto mr-[1%] rounded-[1.4rem] shadow-[rgba(0,0,0,0.2)_0px_18px_50px_-10px]">
        <div className="w-full h-auto p-[4.5rem] max-md:[3.5rem]">
          <div className="w-full h-auto">
            <div className="w-full h-auto">
              <div className="w-full h-auto flex justify-between items-center gap-8 mb-[4rem] max-md:mb-[2rem]">
                <div className="w-[10.5rem] h-[10.5rem] relative max-md:w-[7rem] max-md:h-[7rem]">
                  <div className="w-full h-full rounded-full overflow-hidden">
                    <img
                      src={profileImage}
                      alt="Profile Icon"
                      onError={handleImageError}
                    />
                  </div>
                </div>
                <div className="flex-1 h-auto">
                  <div className="w-full h-auto">
                    <h1 className="text-[3.4rem] max-md:text-[3rem] font-inter font-normal text-io-black dark:text-io-white leading-tight">
                      <span>
                        {firstName} &nbsp;
                        {lastName}
                      </span>
                      !
                    </h1>
                    <p className="text-[1.8rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 mb-6 max-md:mb-3">
                      {email}
                    </p>
                    <div className="w-full h-auto">
                      <div
                        className="w-auto h-auto inline-flex justify-start items-center gap-4 relative cursor-pointer"
                        onClick={() => setShowOptions(!showOptions)}
                      >
                        <p className="text-[1.6rem] font-inter font-normal text-io-black dark:text-io-white">
                          {selectedOption?.value}
                        </p>
                        <div className="w-[1.4rem] h-auto">
                          <ProfileDropDownIcon />
                        </div>
                        {showOptions && (
                          <div className="w-[27rem] h-auto p-4 absolute z-[2] top-full left-0 bg-io-white dark:bg-io-black-23 rounded-[0.6rem] shadow-lg">
                            <ul>
                              {optionsWithAll.map((opt: any) => (
                                <li
                                  key={opt.id}
                                  className={`text-[1.6rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 px-[1.3rem] py-[0.7rem] leading-none hover:bg-[#ECF7FF] dark:hover:bg-io-black-48 hover:text-io-primary dark:hover:text-io-primary cursor-pointer ${
                                    selectedOption?.id === opt.id
                                      ? "bg-[#ECF7FF] dark:bg-io-black-48 text-io-primary dark:text-io-primary"
                                      : ""
                                  }`}
                                  onClick={() => handleOptionClick(opt)}
                                >
                                  {opt.value}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-auto mb-[3rem] max-md:mb-[2rem]">
                <div className="w-full h-auto flex justify-center items-center border border-io-primary rounded-full px-[2.4rem] py-6 ">
                  <button
                    type="button"
                    className="bg-none border-none text-[1.6rem] max-md:text-[1.4rem] font-inter font-medium text-io-primary leading-none relative after:content-[''] after:w-[1px] after:h-[100%] after:absolute after:top-0 after:right-[-15%] after:bg-io-primary after:hidden"
                    onClick={() => {
                      handleViewAccount();
                    }}
                  >
                    {t("web.profile.label.user")}
                  </button>
                  {/* <button
                    type="button"
                    className="bg-none border-none text-[1.6rem] max-md:text-[1.4rem] font-inter font-medium text-io-primary leading-none"
                  >
                    Sign in With Different Account
                  </button>*/}
                </div>
              </div>
              <div className="w-full h-auto">
                <button
                  className="w-auto h-auto flex justify-center items-center gap-4 mx-auto"
                  onClick={signOutClick}
                >
                  <div className="w-[2rem] h-auto">
                    <SignOutIcon />
                  </div>
                  <p className="text-[1.6rem] uppercase font-inter font-semibold text-io-primary">
                    {t("web.profile.label.signout")}
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default Profile;
