import { Outlet } from "react-router";
import PageHeader from "../../../components/global-layout/page-header";
import Banner from "../../../components/UI/banner";
import Explore from "../../../components/section/explore";
import { useNavigate, useParams } from "react-router-dom";
import { VisionPageHeaderIcon } from "../../../components/svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";

function Vision({ visionUpdated, setVisionUpdated }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { magicType } = useParams();

  const [selectedMagicType, setSelectedMagicType] = useState(magicType);
  const createCustomMagic = () => {
    navigate(`/ai-magic-studio/vision/${selectedMagicType}/create/new`);
  };
  const handlePillarSelectionType = (option: any) => {
    setSelectedMagicType(option);
    navigate(`/ai-magic-studio/vision/${option}`);
  };

  return (
    <>
      <div className="w-full h-full flex flex-col ">
        <PageHeader
          title={t("web.title.label.vision")}
          icon={<VisionPageHeaderIcon />}
        />
        {/* <Banner /> */}
        <Explore
          visionUpdated={visionUpdated}
          setVisionUpdated={setVisionUpdated}
          handlePillarSelectionType={handlePillarSelectionType}
          handleClick={createCustomMagic}
        />
        {/* <CreateFloatingButton
          icon={<CreateCustomMagicButtonIcon />}
          handleClick={createCustomMagic}
        /> */}
      </div>
      <Outlet />
    </>
  );
}
export default Vision;
