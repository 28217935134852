import { useEffect, useRef, useState } from "react";
import IconTextBtn from "../../form/icon-text-btn";
import FilterOptions from "../../form/filter-options";
import GridSearch from "../../form/grid-search";
import { AddIcon, FilterIcon, SortIcon } from "../../svg";
import { CardGridToggleButton } from "../../form/toggle-btn";
import { useTranslation } from "react-i18next";
import { useMyContext } from "../../my-context/MyContext";
function ExploreOptions({
  isGridView,
  setIsGridView,
  fetchData,
  getSearchedQuery,
  handleClick,
}: any) {
  const { t } = useTranslation();
  const { isExternalUser } = useMyContext();
  const [isContentVisible, setContentVisible] = useState(false);
  const [showFilterAndSort, setShowFilterAndSort] = useState(true);
  const modalRef = useRef<any>(null);

  const handleOptionSelect = (option: any) => {
    console.log("Selected option:", option);
  };

  // const getIsChecked = (isChecked: boolean) => {
  //   setShowFilterAndSort(isChecked);
  //   isCardOrGridView(isChecked);
  // };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setContentVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef]);

  return (
    <div className="w-auto h-auto max-xl:w-full">
      <div className="w-auto h-auto flex justify-end items-center gap-12 max-3xl:gap-8 max-xl:justify-end max-xl:flex-row-reverse">
        <div className="w-auto">
          {!isExternalUser && (
            <IconTextBtn
              type="button"
              label={t("web.toolbar.label.create")}
              btnSize="large"
              icon={<AddIcon />}
              handleClick={handleClick}
            />
          )}
        </div>
        <div className="w-auto">
          <GridSearch
            onSearch={fetchData}
            getSearchedQuery={getSearchedQuery}
          />
        </div>
        <div className="w-auto">
          <CardGridToggleButton
            isChecked={isGridView}
            handleToggle={setIsGridView}
          />
        </div>
      </div>
    </div>
  );
}
export default ExploreOptions;
