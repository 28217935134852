import { useNavigate, useParams } from "react-router-dom";
import {
  InputFieldWithoutForm,
  TextAreaField,
  SelectField,
} from "../../../form/input-fields";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import ToastifyMessage from "../../../../lib/toastify";
import { useEffect, useState } from "react";
import { ToastError, ToastSuccess, ToastWarning } from "../../../UI/toast";
import { ToggleButtonWithoutForm } from "../../../form/toggle-btn";
import {
  screenMapping,
  startupScreenServices,
} from "../../../../services/InspectionOneServices";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import UploadImage from "../../../form/upload-image";

function NewSubProcessForm({ subProcessUpdated }: any) {
  const navigate = useNavigate();
  const { logicId, id } = useParams();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [getStartedOptions, setGetStartedOptions] = useState([]);
  const [processStatusOptions, setProcessStatusOptions] = useState([]);
  const [isImageLoader, setImageLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    getStartedUniqueId: "",
    description: "",
    imageUrl: "",
    isGetStartedAvailable: 0,
    isServiceCall: 0,
    isOnlyMagicContainer: 0,
    processStatusGroupUniqueId: "",
  });
  const [errors, setErrors] = useState({
    name: "",
  });

  const generateGuid = () => uuidv4();

  const handleFormValidation = () => {
    const name = formData.name.trim();
    const isNameEmpty = name === "";

    setErrors({
      name: isNameEmpty ? "web.form.error.mapscrnreq" : "",
    });

    return !isNameEmpty;
  };

  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleGetStartedEdit(id);
    }
  }, [id]);

  useEffect(() => {
    getStartedListFetch();
  }, []);

  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      try {
        setIsLoading(true);
        if (formData.isGetStartedAvailable === 0) {
          formData.isServiceCall = 0;
          formData.getStartedUniqueId = "";
        }
        const requestJson = {
          ...formData,
          uniqueId: mode === "update" ? id : generateGuid(),
          ProcessLogicUniqueId: logicId,
          isDeleted: 0,
          icon: formData.imageUrl,
        };
        const response = await screenMapping.ScreenMappingAction(requestJson);
        if (response.success) {
          handleSuccess(
            t("web.toater.title.screenmapping"),
            t("web.toaster.desc.screenmapsuccess")
          );
          setTimeout(() => {
            navigate(`/ai-logic-studio/process-logic/${logicId}`);
            subProcessUpdated(true);
            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
          handleError(t("web.toolbar.label.process"), response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  const handleGetStartedEdit = async (uniqueIdId: any) => {
    try {
      setIsLoading(true);
      const requestJson = {
        uniqueId: uniqueIdId,
        processLogicUniqueId: logicId,
      };
      const response = await screenMapping.ScreenMappingDetail(requestJson);
      if (response.success) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ...response.data[0],
          imageUrl: response.data[0].icon,
        }));
        setIsLoading(false);
      } else {
        setIsLoading(false);
        handleError(t("web.toolbar.label.process"), response.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleFormCancel = () => {
    navigate(`/ai-logic-studio/process-logic/${logicId}`);
  };

  const getStartedListFetch = async () => {
    setIsLoading(true);
    try {
      const requestJson = {
        processLogicUniqueId: logicId,
      };
      const response = await startupScreenServices.StartupScreenLists(
        requestJson
      );
      if (response.success) {
        const { logicDetails, processStatusDetails } = response.data;

        if (logicDetails) {
          const options = logicDetails.map(({ uniqueId, name }: any) => ({
            value: uniqueId,
            label: name,
          }));
          setGetStartedOptions(options);
        }
        if (processStatusDetails) {
          const options = processStatusDetails.map(
            ({ uniqueId, name }: any) => ({
              value: uniqueId,
              label: name,
            })
          );
          setProcessStatusOptions(options);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (name: any, value: any) => {
    if (
      name === "isServiceCall" ||
      name === "isOnlyMagicContainer" ||
      name === "isGetStartedAvailable"
    ) {
      value = value === true ? 1 : 0;
    }
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  //#region Alert
  const { ToastifyHandle } = ToastifyMessage();
  const handleSuccess = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastSuccess title={titleName} description={message} />,
      "success"
    );
  };
  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };
  const handleWarning = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastWarning title={titleName} description={message} />,
      "warning"
    );
  };
  //#endregion

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.screenmapping.label.name")}
                      type="text"
                      id="subprocessName"
                      isRequired={true}
                      value={formData.name}
                      error={t(errors.name)}
                      onChange={(e) => handleInputChange("name", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <TextAreaField
                      labelName={t("web.screenmapping.label.desc")}
                      id="subprocessDescription"
                      isRequired={true}
                      value={formData.description}
                      onChange={(e) => handleInputChange("description", e)}
                    />
                  )}
                </div>
                {/* <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <ToggleButtonWithoutForm
                      id="isGetStartedAvailable"
                      size="large"
                      label={t("web.process.label.startupscrn")}
                      labelPosition="right"
                      isButtonChecked={
                        formData.isGetStartedAvailable === 1 ? true : false
                      }
                      getIsChecked={(isChecked) =>
                        handleInputChange("isGetStartedAvailable", isChecked)
                      }
                    />
                  )}
                </div> */}
                {/* {formData.isGetStartedAvailable === 1 && (
                  <>
                    <div className="mb-[3.8rem]">
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <SelectField
                          labelName={t("web.screenmapping.label.startupscreen")}
                          options={getStartedOptions}
                          id="getStartedUniqueId"
                          isRequired={false}
                          value={formData.getStartedUniqueId}
                          onChange={(e) =>
                            handleInputChange("getStartedUniqueId", e)
                          }
                        />
                      )}
                    </div>
                    <div className="mb-[3.8rem]">
                      {isLoading ? (
                        <Skeleton width="100%" height={47} borderRadius={3} />
                      ) : (
                        <ToggleButtonWithoutForm
                          id="isServiceCall"
                          size="large"
                          label={t("web.process.label.strtscrncall")}
                          labelPosition="right"
                          isButtonChecked={
                            formData.isServiceCall === 1 ? true : false
                          }
                          getIsChecked={(isChecked) =>
                            handleInputChange("isServiceCall", isChecked)
                          }
                        />
                      )}
                    </div>
                  </>
                )} */}
                {/* <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <ToggleButtonWithoutForm
                      id="isOnlyMagicContainer"
                      size="large"
                      label={t("web.process.label.isonlymagixcontainer")}
                      labelPosition="right"
                      isButtonChecked={
                        formData.isOnlyMagicContainer === 1 ? true : false
                      }
                      getIsChecked={(isChecked) =>
                        handleInputChange("isOnlyMagicContainer", isChecked)
                      }
                    />
                  )}
                </div> */}
                {/* <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectField
                      labelName={t("web.common.label.processstatus")}
                      options={processStatusOptions}
                      id="processStatusGroupUniqueId"
                      isRequired={false}
                      value={formData.processStatusGroupUniqueId}
                      onChange={(e) =>
                        handleInputChange("processStatusGroupUniqueId", e)
                      }
                    />
                  )}
                </div> */}
              </div>
            </div>
            <div className="w-[32%] h-auto">
              <div className="w-full h-auto">
                {isLoading ? (
                  <Skeleton width="100%" height={190} borderRadius={3} />
                ) : (
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isReadOnly={false}
                    isImageLoader={isImageLoader}
                    setImageLoader={setImageLoader}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={() => handleFormCancel()}
        handleSubmit={() => handleFormSubmit()}
        isLoading={isLoading}
        isImageLoader={isImageLoader}
      />
    </div>
  );
}

export default NewSubProcessForm;
