import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface ToggleBtnProps {
  id: string;
  label?: string;
  size: string;
  labelPosition?: string;
  formHandle?: any;
  value?: any;
}
interface ToggleButtonWithoutFormProps {
  id: string;
  label?: string;
  size: string;
  getIsChecked: (isChecked: boolean) => void;
  labelPosition?: string;
  isButtonChecked?: boolean;
  isChecked?: boolean;
}
interface CardGridToggleButtonProps {
  // gridViewByDefault: boolean;
  // getIsChecked: (isChecked: boolean) => void;
  isChecked: boolean;
  handleToggle: (isChecked: any) => void;
}

const ToggleButton = ({
  id,
  label,
  size,
  formHandle,
  labelPosition,
  value,
}: ToggleBtnProps) => {
  return (
    <>
      <div
        className={`w-auto ${
          labelPosition === "left" &&
          " flex justify-center items-center gap-[0.7rem]"
        }${
          labelPosition === "right" &&
          " flex justify-end flex-row-reverse  items-center gap-[0.7rem]"
        }`}
      >
        {label && (
          <p
            className={`text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 ${
              labelPosition === "left" && "mb-0"
            } ${labelPosition === "right" && "mb-0"} ${
              labelPosition !== "left" && labelPosition !== "right" && "mb-2"
            }`}
          >
            {label}
          </p>
        )}

        <label
          htmlFor={id}
          className="w-auto inline-flex items-center cursor-pointer"
        >
          <input type="checkbox" id={id} className="sr-only" {...formHandle} />
          <div
            className={`${
              size === "small" ? "w-[3rem] h-[1.5rem]" : "w-[4.1rem] h-[2rem]"
            } rounded-full relative ${
              value ? "bg-io-primary" : "bg-io-gray-c8"
            }`}
          >
            {size === "small" ? (
              <div
                className={`w-[1rem] h-[1rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                  value ? "translate-x-[170%]" : "translate-x-[25%]"
                }`}
              ></div>
            ) : (
              <div
                className={`w-[1.5rem] h-[1.5rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                  value ? "translate-x-[148%]" : "translate-x-[20%]"
                }`}
              ></div>
            )}
          </div>
        </label>
      </div>
    </>
  );
};

const ToggleButtonWithoutForm = ({
  id,
  label,
  size,
  getIsChecked,
  labelPosition,
  isButtonChecked = false,
}: ToggleButtonWithoutFormProps) => {
  const [isChecked, setIsChecked] = useState(isButtonChecked);
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    setIsChecked(isButtonChecked);
  }, [isButtonChecked]);

  useEffect(() => {
    getIsChecked(isChecked);
  }, [isChecked]);

  return (
    <>
      <div
        className={`w-auto ${
          labelPosition === "left" &&
          " flex justify-center items-center gap-[0.7rem]"
        }${
          labelPosition === "right" &&
          " flex justify-end flex-row-reverse  items-center gap-[0.7rem]"
        }`}
      >
        {label && (
          <p
            className={`text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 ${
              labelPosition === "left" && "mb-0"
            } ${labelPosition === "right" && "mb-0"} ${
              labelPosition !== "left" && labelPosition !== "right" && "mb-2"
            }`}
          >
            {label}
          </p>
        )}

        <label
          htmlFor={id}
          className="w-auto inline-flex items-center cursor-pointer"
        >
          <input
            type="checkbox"
            id={id}
            className="sr-only"
            checked={!isChecked}
            onChange={handleToggle}
          />
          <div
            className={`${
              size === "small" ? "w-[3rem] h-[1.5rem]" : "w-[4.1rem] h-[2rem]"
            } rounded-full relative ${
              !isChecked ? "bg-io-gray-c8" : "bg-io-primary"
            }`}
          >
            {size === "small" ? (
              <div
                className={`w-[1rem] h-[1rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                  !isChecked ? "translate-x-[25%]" : "translate-x-[150%]"
                }`}
              ></div>
            ) : (
              <div
                className={`w-[1.5rem] h-[1.5rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                  !isChecked ? "translate-x-[20%]" : "translate-x-[130%]"
                }`}
              ></div>
            )}
          </div>
        </label>
      </div>
    </>
  );
};

const CardGridToggleButton = ({
  isChecked,
  handleToggle,
}: CardGridToggleButtonProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="w-auto flex justify-center items-center gap-3">
        <p
          className={`text-[1.8rem] font-inter font-normal text-io-gray-47 dark:text-io-white ${
            isChecked ? "text-opacity-50" : "text-opacity-100"
          }`}
        >
          {t("web.toolbar.label.grid")}
        </p>
        <label htmlFor="togglebtn" className="flex items-center cursor-pointer">
          <input
            type="checkbox"
            id="togglebtn"
            className="sr-only"
            checked={!isChecked}
            onChange={handleToggle}
          />
          <div
            className={`w-[5.4rem] h-[2.8rem] rounded-full relative ${
              !isChecked ? "bg-io-gray-9d" : "gradient-bg"
            }`}
          >
            <div
              className={`w-[2.2rem] h-[2.2rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                !isChecked ? "translate-x-[20%]" : "translate-x-[125%]"
              }`}
            ></div>
          </div>
        </label>
        <p
          className={`text-[1.8rem] font-inter font-normal text-io-gray-47 dark:text-io-white ${
            !isChecked ? "text-opacity-50" : "text-opacity-100"
          }`}
        >
          {t("web.toolbar.label.card")}
        </p>
      </div>
    </>
  );
};

export { ToggleButton, CardGridToggleButton, ToggleButtonWithoutForm };
