// Common validation function

import { isValidDomainName, isValidEmailAddress } from "../utils/common";

const domainValidation = (domainValue: any) => { 
    const validationCriteria = {
      required: true,
      validator: domainValue,  
      errorMessage: {
        required: "web.domain.alert.required",
        invalid: "web.domain.alert.invalid",
      },
    }; 
    const errorMessage = validateInput(domainValue, validationCriteria); 
    return errorMessage;
  }; 

const validateInput = (value: any, validationCriteria: any) => { 
    const { required, validator, errorMessage } = validationCriteria; 
    if (required && !value.trim()) {
      return errorMessage.required;
    } else if (!isValidDomainName && !validator(value)) {
      return errorMessage.invalid;
    } else {
      return "";
    }
  };
   
  const loginValidation = (email: any, password: any) => {
    const emailValidationCriteria = {
      required: true,
      validator: isValidEmailAddress,  
      errorMessage: {
        required: "web.login.alert.emailrequired",
        invalid: "web.login.alert.invalidemail",
      },
    };
  
    const passwordValidationCriteria = {
      required: true,
      errorMessage: {
        required: "web.login.alert.passwordrequired",
      },
    };
  
    const emailErrorMessage = validateInput(email, emailValidationCriteria);
    const passwordErrorMessage = validateInput(password, passwordValidationCriteria); 
  
    return {
      emailError: emailErrorMessage,
      passwordError: passwordErrorMessage,
    };
  };
  
  const userValidation = (formData:any) => {
    const { email, firstName, lastName, mobile, roleId } = formData;
    const isEmailValid = isValidEmailAddress(email.trim());
    const isEmailEmpty = !email.trim();
    const isFirstNameEmpty = !firstName.trim();
    const isLastNameEmpty = !lastName.trim();
    const isPhoneNumberEmpty = !mobile.trim();
    const isRoleEmpty = !roleId;

    // const isMobileValid = /^\d{6,15}$/.test(mobile.trim());
    const containsNonNumeric = /\D/.test(mobile.trim());

   const errorMessage={
      email: isEmailEmpty
        ? "web.login.alert.emailrequired" 
        : isEmailValid
        ? ""
        : "web.login.alert.invalidemail",
      firstName: isFirstNameEmpty ? "web.user.alert.fnamerequired" : "",
      lastName: isLastNameEmpty ? "web.user.alert.lnamerequired" : "",
      mobile: isPhoneNumberEmpty
      ? "web.user.alert.phonenumberrequired"
      : containsNonNumeric
      ? "web.user.alert.nonnumericmobilenumber"
      : "",
      roleId: isRoleEmpty ? "web.user.alert.rolerequired" : "",
    };

    return ( errorMessage);
  };
 
  export {  domainValidation, loginValidation,userValidation };
  