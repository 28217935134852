import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import EncryptionKey from "../../auth/EncryptionKey";
import CryptoJS from "crypto-js";
import i18n from "../../utils/localization/i18n";
import { languageDetails } from "../../utils/common";
interface MyContextType {
  language: string;
  theme: string;
  setLanguage: (lang: string) => void;
  setTheme: (theme: string) => void;
  isExternalUser: boolean;
  loadProviderData: () => void;
}

const MyContext = createContext<MyContextType | undefined>(undefined);

interface MyContextProviderProps {
  children: ReactNode;
}

export const MyContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}) => {
  const [language, setLanguage] = useState<string>("en");
  const [theme, setTheme] = useState<string>("light");
  const [isExternalUser, setIsExternalUser] = useState<boolean>(false);
  const [isCardView, setIsCardView] = useState<boolean>(false);

  const changeLanguage = (lang: string) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const changeTheme = (theme: string) => {
    setTheme(theme);
  };

  useEffect(() => {
    loadProviderData();
  }, []);

  const loadProviderData = () => {
    try {
      const isViewAccess = sessionStorage.getItem("UserDetails");
      const isGridView = sessionStorage.getItem("isGridView");

      if (!isViewAccess || !isGridView) {
        console.warn("One or more required items not found in sessionStorage.");
        return;
      }

      const primaryKey = EncryptionKey();

      // Decrypt data ensuring it is not null

      const decryptedIsViewAccess = CryptoJS.AES.decrypt(
        isViewAccess,
        primaryKey || ""
      );
      const decryptedIsGridView = CryptoJS.AES.decrypt(
        isGridView,
        primaryKey || ""
      );

      const isViewAccessData = decryptedIsViewAccess.toString(
        CryptoJS.enc.Utf8
      );
      const isGridViewData = decryptedIsGridView.toString(CryptoJS.enc.Utf8);

      if (!isViewAccessData || !isGridViewData) {
        console.warn("Decryption failed or returned an empty result.");
        return;
      }

      const isView = JSON.parse(isViewAccessData);
      setIsExternalUser(isView?.otherDetails?.isViewAccess === 1);

      const languages = languageDetails;
      if (languages) {
        console.log(isView.languageId);
        const selectedLanguageDetails = languages.find(
          (lang: any) => lang.id === isView.languageId
        );
        setLanguage(selectedLanguageDetails?.code || "en");
        sessionStorage.setItem(
          "selectedLanguageDetails",
          JSON.stringify(selectedLanguageDetails)
        );

        i18n.changeLanguage(selectedLanguageDetails?.code || "en");
      }

      const gridView = JSON.parse(isGridViewData);
      setIsCardView(gridView);

      setTheme("light");
    } catch (error) {
      console.error("Error loading provider data:", error);
    }
  };
  const encryptData = (data: any, encryptName: any, actionType: any) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "4c77b66cb19b4852856e43a2c16b7ad6"
    ).toString();
    if (actionType === 1) {
      localStorage.setItem(encryptName, encryptedData);
    } else {
      sessionStorage.setItem(encryptName, encryptedData);
    }
  };
  return (
    <MyContext.Provider
      value={{
        language,
        theme,
        setLanguage: changeLanguage,
        setTheme: changeTheme,
        isExternalUser,
        loadProviderData,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = (): MyContextType => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("useMyContext must be used within a MyContextProvider");
  }
  return context;
};
