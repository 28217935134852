import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { InputFieldWithoutForm } from "../../../form/input-fields";
import { SelectInputWithoutForm } from "../../../form/select-input";
import UploadImage from "../../../form/upload-image";
import ModalPageFooter from "../../../global-layout/modal-page-footer";
import { useTranslation } from "react-i18next";
import ErrorPopup from "../../../UI/error-popup";
import PageLoader from "../../../UI/page-loader";
import Skeleton from "react-loading-skeleton";
import { userValidation } from "../../../../services/validation";
import { userCreate, userEdit } from "../../../../services/authFunctions";
import Authentication from "../../../../auth/Authentication";
function NewUserForm({ userUpdated }: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [mode, setMode] = useState("insert");
  const [isImageLoader, setImageLoader] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    roleId: 1,
    isAzureAd: 0,
    imageUrl: "",
  });

  useEffect(() => {
    if (id !== "new") {
      setMode("update");
      handleUserEdit(id);
    }
  }, [id]);

  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    roleId: "",
  });
  const authService = Authentication();
  const handleInputChange = (name: string, value: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleCheckboxChange = (name: string, value: any) => {
    value = value === true ? 1 : 0;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFormValidation = () => {
    const user = userValidation(formData);

    setErrors({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      roleId: user.roleId,
    });

    return (
      !user.email &&
      !user.firstName &&
      !user.lastName &&
      !user.mobile &&
      !user.roleId
    );
  };

  const handleFormSubmit = async () => {
    if (handleFormValidation()) {
      setIsLoading(true);
      const formDataLowerCase = {
        ...formData,
        email: formData.email.toLowerCase(),
      };
      const result = await userCreate(formDataLowerCase, mode, id, "User");
      if (result.success) {
        let parsedLoginDetails = null;
        parsedLoginDetails = authService.decryptData("loginDetails", 2);
        parsedLoginDetails = parsedLoginDetails || "{}";
        const otherDetails = parsedLoginDetails?.otherDetails;

        if (
          otherDetails.roleId !== formDataLowerCase.roleId &&
          parsedLoginDetails.siteUserId === id
        ) {
          authService.logout();
        } else if (parsedLoginDetails.siteUserId === id) {
          authService.encryptData(formDataLowerCase, "UserDetails", 2);
        }
        setTimeout(() => {
          navigate("/user-management");
          userUpdated(true);
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };
  const handleUserEdit = async (userId: any) => {
    setIsLoading(true);
    const response = await userEdit(userId.trim());
    if (response.success) {
      response.data.imageUrl = response.data.profileImageUrl;
      setFormData(response.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleFormCancel = () => {
    setIsLoading(false);
    navigate("/user-management");
  };
  //#region Alert
  const [isLoading, setIsLoading] = useState(false);
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader] = useState(false);

  //#endregion
  const options = [
    { label: "Super Admin", id: 1 },
    { label: "Tenant Admin", id: 2 },
    { label: "Site Admin", id: 3 },
    { label: "Site User", id: 4 },
    { label: "Portal User", id: 4 },
    { label: "Portal Guest User", id: 6 },
    { label: "Mobile User", id: 7 },
    { label: "Mobile Guest User", id: 8 },
  ];
  const getRoleValue = (roleId: any) => {
    const defaultRole = options.find((option) => option.id === roleId);
    return defaultRole;
  };
  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full flex-auto basis-0 overflow-y-auto scrollbar-large">
        <div className="w-[93%] h-auto">
          <div className="w-full h-auto flex justify-between items-start pt-4">
            <div className="w-[60%] ">
              <div className="w-full h-auto">
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.firstname")}
                      type="text"
                      id="newUserFirstName"
                      isRequired={true}
                      value={formData.firstName}
                      error={t(errors.firstName)}
                      onChange={(e) => handleInputChange("firstName", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.lastname")}
                      type="text"
                      id="newUserLastName"
                      isRequired={true}
                      value={formData.lastName}
                      error={t(errors.lastName)}
                      onChange={(e) => handleInputChange("lastName", e)}
                    />
                  )}
                </div>
                {/* <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.displayname")}
                      type="text"
                      id="newUserDisplayName"
                      isRequired={false}
                      value={formData.displayName}
                      onChange={(e) => handleInputChange("displayName", e)}
                    />
                  )}
                </div> */}
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.email")}
                      type="email"
                      id="newUserEmail"
                      isRequired={true}
                      isReadOnly={mode !== "insert" ? true : false}
                      value={formData.email}
                      error={t(errors.email)}
                      onChange={(e) => handleInputChange("email", e)}
                    />
                  )}
                </div>
                <div className="mb-[3.8rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <InputFieldWithoutForm
                      labelName={t("web.profile.label.mobile")}
                      type="text"
                      id="newUserPhoneNumber"
                      isRequired={true}
                      value={formData.mobile}
                      error={t(errors.mobile)}
                      onChange={(e) => handleInputChange("mobile", e)}
                    />
                  )}
                </div>
                <div className="mb-[3rem]">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <SelectInputWithoutForm
                      labelName={t("web.profile.label.role")}
                      id={`screenName`}
                      isRequired={true}
                      options={options}
                      defaultValue={getRoleValue(formData.roleId)}
                      getOptionLabel={(option: any) => option.label}
                      getOptionValue={(option: any) => option.id}
                      getSelection={(option: any) =>
                        handleInputChange("roleId", option.id)
                      }
                    />
                  )}
                </div>
                <div className="w-full h-auto">
                  {isLoading ? (
                    <Skeleton width="100%" height={47} borderRadius={3} />
                  ) : (
                    <div className="w-full flex justify-start items-center gap-4 mb-4">
                      <input
                        type="checkbox"
                        name="azureActiveDirectory"
                        id="azureActiveDirectory"
                        defaultChecked={formData.isAzureAd === 1}
                        onChange={(event) =>
                          handleCheckboxChange(
                            "isAzureAd",
                            event.target.checked
                          )
                        }
                      />
                      <label
                        htmlFor="azureActiveDirectory"
                        className="text-[1.4rem] font-inter font-normal text-io-black dark:text-io-white"
                      >
                        {t("web.usermanagement.label.azure")}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="w-[32%] h-auto">
              {isLoading ? (
                <Skeleton width="100%" height={150} borderRadius={3} />
              ) : (
                <div className="w-full h-auto">
                  <UploadImage
                    formData={formData}
                    setFormData={setFormData}
                    isImageLoader={isImageLoader}
                    setImageLoader={setImageLoader}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalPageFooter
        footerType="form"
        handleCancel={handleFormCancel}
        handleSubmit={handleFormSubmit}
        isLoading={isLoading}
        isImageLoader={isImageLoader}
      />{" "}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
    </div>
  );
}
export default NewUserForm;
