import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputFieldWithoutForm } from "../../form/input-fields";
import { validatePassword } from "../../../utils/common";
import { changePassword, domainSubmit } from "../../../services/authFunctions";
import Authentication from "../../../auth/Authentication";
import { LoginBtn } from "../../form/text-btn";
import { useNavigate } from "react-router-dom";

function ResetPasswordStep({ setPageLoader }: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authService = Authentication();
  const [tenantLogoUrl, setTenantLogoUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const getSubdomain = (): string | null => {
    const { hostname } = window.location;
    const hostParts = hostname.split(".");
    if (
      hostname.includes("localhost") ||
      hostname.includes("inspectionone-host-staging.azurewebsites.net")
    ) {
      const domain = sessionStorage.getItem("domain");
      console.log(domain);
      return domain;
    }
    if (hostParts.length > 2) {
      return hostParts[0];
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setPageLoader(true);
      const result = await domainSubmit(getSubdomain(), false);
      if (result.success) {
        authService.login(1);
        setTenantLogoUrl(result.response.tenantLogo);
        authService.encryptData(result.data, "serviceDetails", 1);
        authService.encryptData(false, "isGridView", 2);
        // setCurrentStep(2);
        setIsLoading(false);
        setPageLoader(false);
      } else {
        setIsLoading(false);
        setPageLoader(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (field: string, value: string) => {
    const updatedFormData = { ...formData, [field]: value };
    setFormData(updatedFormData);
  };

  const isFormValid = () => {
    const validationErrors = validatePassword(formData);
    setErrors(validationErrors);
    return !Object.values(validationErrors).some(
      (error: any) => error.length > 0
    );
  };

  const handleChangePassword = async () => {
    if (isFormValid()) {
      setIsLoading(true);
      const response = await changePassword(
        formData.currentPassword,
        formData.newPassword
      );
      if (response.success) {
        setIsLoading(false);
        setTimeout(() => {
          navigate("/login");
          authService.logout();
        }, 1000);
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };

  return (
    <div className="w-full flex-1 overflow-y-auto">
      <div className="w-full h-full relative flex justify-center items-center ">
        <div className="w-full h-auto">
          <div className="w-full h-auto">
            <div className="w-[32rem] h-auto max-3xl:w-[25rem] max-2xl:w-[20rem] mx-auto">
              {tenantLogoUrl && <img src={tenantLogoUrl} alt="AI Magix Logo" />}
            </div>
          </div>
          <div className="w-full h-auto mx-auto">
            <div className="w-full h-auto text-center">
              <div className="w-full h-auto mb-[5.4rem]">
                <h1 className="text-[4.2rem] font-inter font-bold text-io-black-1a mb-[0.7rem]">
                  {t("web.login.text.reset")}
                </h1>
                {/* <p className="text-[1.8rem] font-inter font-normal text-io-gray-66">
                    {t("web.resetpassword.label.subtitle")}
                  </p> */}
              </div>
              <div className="w-[55.5%] h-auto mx-auto">
                <div className="w-full h-auto">
                  <div className="mb-[3rem]">
                    <InputFieldWithoutForm
                      labelName={t("web.settings.label.currentpassword")}
                      type="password"
                      id="currentPassword"
                      isRequired={true}
                      value={formData.currentPassword}
                      error={t(errors.currentPassword)}
                      onChange={(e) => handleInputChange("currentPassword", e)}
                      isLightThemeOnly={true}
                      textColor="text-io-black"
                    />
                  </div>
                  <div
                    className={`${
                      errors.newPassword
                        ? "mb-[5rem] max-2xl:mb-[6rem]"
                        : "mb-[3rem]"
                    }`}
                  >
                    <InputFieldWithoutForm
                      labelName={t("web.settings.label.newpassword")}
                      type="password"
                      id="newPassword"
                      isRequired={true}
                      value={formData.newPassword}
                      error={t(errors.newPassword)}
                      onChange={(e) => handleInputChange("newPassword", e)}
                      isLightThemeOnly={true}
                      onCopy={handleCopy}
                      textColor="text-io-black"
                    />
                  </div>
                  <div className="mb-[3rem]">
                    <InputFieldWithoutForm
                      labelName={t("web.settings.label.confirmpassword")}
                      type="password"
                      id="confirmPassword"
                      isRequired={true}
                      value={formData.confirmPassword}
                      error={t(errors.confirmPassword)}
                      onChange={(e) => handleInputChange("confirmPassword", e)}
                      onCopy={handleCopy}
                      isLightThemeOnly={true}
                      textColor="text-io-black"
                    />
                  </div>
                  <div className="w-full mb-[3rem]">
                    <LoginBtn
                      label={t("web.section.lable.reset")}
                      btnType="button"
                      isLoading={isLoading}
                      handleClick={handleChangePassword}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResetPasswordStep;
