import { useState } from "react";
import IconBtn from "../../../form/icon-btn";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import { PageModalCloseIcon, TabThumbDesktop, TabThumbMobile, TabThumbTablet } from "../../../svg";
import { PreviewAcknowledgement, PreviewAudio, PreviewDateTime, PreviewImage, PreviewImageWithInputs, PreviewInformation, PreviewLocation, PreviewMagicContainer, PreviewMultipleChoice, PreviewNumber, PreviewPersonName, PreviewScanner, PreviewSignature, PreviewSiteName, PreviewTenantName, PreviewText, PreviewToggle, PreviewVideo } from "../process-preview-items";
import { useTranslation } from "react-i18next";

interface ProcessPreviewModalProps {
    processData: any;
    questionDetails: any;
    selectedScreen:any;
    selectedResponseItem:any;
    responseValueOptions:any;
    pageTitle:any;
    handleCancel: () => void;
}

function ProcessPreviewModalNew({ processData, questionDetails,selectedScreen, selectedResponseItem, responseValueOptions,pageTitle, handleCancel }: ProcessPreviewModalProps) {
    const [selectedTab, setSelectedTab] = useState('mobile');
    const { t } = useTranslation();
    const tabs = [
        { id: 'mobile', label: 'Mobile', icon: <TabThumbMobile /> },
        { id: 'tablet', label: 'Tablet', icon: <TabThumbTablet /> },
        { id: 'desktop', label: 'Desktop', icon: <TabThumbDesktop /> }
    ];

    const handleTabChange = (tabId: string) => {
        setSelectedTab(tabId);
    };

    const renderResponseComponent = (item: any) => {
        const componentMap: { [key: string]: any } = {
          1: <PreviewTenantName item={item} />,
          2: <PreviewSiteName item={item} />,
          3: <PreviewPersonName item={item} />,
          4: <PreviewLocation item={item} />,
          5: <PreviewScanner item={item} />,
          6: <PreviewScanner item={item} />,
          7: <PreviewScanner item={item} />,
          8: <PreviewScanner item={item} />,
          9: (
            <PreviewMultipleChoice
              item={item}
              responseValueOptions={responseValueOptions}
            />
          ),
          10: (
            <PreviewMultipleChoice
              item={item}
              responseValueOptions={responseValueOptions}
            />
          ),
          11: (
            <PreviewMultipleChoice
              item={item}
              responseValueOptions={responseValueOptions}
            />
          ),
          12: <PreviewText item={item} />,
          13: <PreviewText item={item} />,
          14: <PreviewNumber item={item} />,
          15: <PreviewDateTime item={item} />,
          16: <PreviewDateTime item={item} />,
          17: <PreviewDateTime item={item} />,
          18: <PreviewAcknowledgement item={item} />,
          19: <PreviewInformation item={item} />,
          20: <PreviewSignature item={item} />,
          21: <PreviewImage item={item} />,
          22: <PreviewImageWithInputs item={item} />,
          23: <PreviewVideo item={item} />,
          24: <PreviewMagicContainer item={item} />,
          25: <PreviewAudio item={item} />,
          26: <PreviewToggle item={item} />,
          27: <PreviewDateTime item={item} />,
          28: (
            <PreviewMultipleChoice
              item={item}
              responseValueOptions={responseValueOptions}
            />
          ),
        };
        return componentMap[item.type] || <p>{t("web.label.Notfound")}</p>;
      };
    

    const renderSelectedDevice = () => {
        switch (selectedTab) {
            case "mobile":
                return (
                    <div className="w-full h-auto">
            <div className="w-[33rem] h-[65rem] mx-auto relative">
              <div className="w-full h-full z-[0]">
                <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[5rem] rounded-b-[2rem]"></div>
              </div>

              <div className="w-full h-full absolute top-0 left-0  z-[2]">
                <img
                  src={require("../../../../assets/images/preview-mobile-large.png")}
                  alt="MobileImage"
                />
              </div>
              <div className="w-full h-full absolute top-0 left-0 z-[3]">
                <div className="w-[80%] h-full mx-auto pb-[1rem] flex justify-center items-end">
                  <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                    <div className="w-full h-full flex flex-col">
                      <div className="w-full h-auto">
                        <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                          <p className="text-[1rem] font-inter font-normal text-io-black">
                            {pageTitle}
                          </p>
                        </div>
                        <div className="w-full h-auto px-2 py-2">
                          <div className="w-full h-auto flex justify-between items-center">
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              {selectedScreen?.orderNo}/{processData.length}{" "}
                              <span className="text-[0.8rem] text-io-black">
                                Questions
                              </span>
                            </p>
                            <p className="text-[1rem] font-inter font-normal text-io-primary">
                              &lt; &gt; &gt; &gt;
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-auto my-[1rem]">
                        <div className="w-full flex justify-between items-center gap-1">
                          {processData.map((item: any, index: number) => (
                            <div
                              key={index}
                              className={`w-full h-[2px] bg-io-primary ${
                                index < selectedScreen?.orderNo
                                  ? "opacity-100"
                                  : "opacity-50"
                              }`}
                            ></div>
                          ))}
                        </div>
                      </div>
                      <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                        <div className="w-full h-full">
                          {selectedScreen?.questionDetails.map(
                            (item: any, i: any) => renderResponseComponent(item)
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
                );
            case "tablet":
                return (
                    <div className="w-full h-auto">
                    <div className="w-[47.4rem] h-[65rem] mx-auto relative">
                      <div className="w-full h-full z-[0]">
                        <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[2rem] rounded-b-[1rem] mt-1"></div>
                      </div>
        
                      <div className="w-full h-full absolute top-0 left-0  z-[2]">
                        <img
                          src={require("../../../../assets/images/preview-tablet-large.png")}
                          alt="MobileImage"
                        />
                      </div>
                      <div className="w-full h-full absolute top-0 left-0 z-[3]">
                        <div className="w-[80%] h-[96%] mx-auto pb-[1.2rem] flex justify-center items-end">
                          <div className="w-full h-[90%] bg-io-white rounded-[2rem] overflow-hidden">
                            <div className="w-full h-full flex flex-col">
                              <div className="w-full h-auto">
                                <div className="w-full h-auto px-3 py-3 border-b border-io-gray-c8">
                                  <p className="text-[1rem] font-inter font-normal text-io-black">
                                    {pageTitle}
                                  </p>
                                </div>
                                <div className="w-full h-auto px-2 py-2">
                                  <div className="w-full h-auto flex justify-between items-center">
                                    <p className="text-[1rem] font-inter font-normal text-io-primary">
                                      {selectedScreen?.orderNo}/{processData.length}{" "}
                                      <span className="text-[0.8rem] text-io-black">
                                        Questions
                                      </span>
                                    </p>
                                    <p className="text-[1rem] font-inter font-normal text-io-primary">
                                      &lt; &gt; &gt; &gt;
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full h-auto my-[1rem]">
                                <div className="w-full flex justify-between items-center gap-1">
                                  {processData.map((item: any, index: number) => (
                                    <div
                                      key={index}
                                      className={`w-full h-[2px] bg-io-primary ${
                                        index < selectedScreen?.orderNo
                                          ? "opacity-100"
                                          : "opacity-50"
                                      }`}
                                    ></div>
                                  ))}
                                </div>
                              </div>
                              <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                                <div className="w-full h-full">
                                  {selectedScreen?.questionDetails.map(
                                    (item: any, i: any) => renderResponseComponent(item)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
            case "desktop":
                return (
                    <div className="w-full h-auto">
                    <div className="w-[76.6rem] h-[65rem] mx-auto relative">
                      {/* <div className="w-full h-full z-[0]">
                      <div className="w-[94%] mx-auto h-[20%] bg-io-primary rounded-t-[1rem] rounded-b-[1rem]"></div>
                    </div> */}
      
                      <div className="w-full h-full absolute top-0 left-0  z-[2]">
                        <img
                          src={require("../../../../assets/images/preview-desktop-large.png")}
                          alt="MobileImage"
                        />
                      </div>
                      <div className="w-full h-[48rem] absolute top-0 left-0 z-[3]">
                        <div className="w-[90%] h-full mx-auto pb-[1.2rem] flex justify-center items-end">
                          <div className="w-full h-[93%] bg-io-white overflow-hidden">
                            <div className="w-full h-full flex flex-col">
                              <div className="w-full h-auto">
                                <div className="w-full h-auto px-2 py-2 border-b border-io-gray-c8">
                                  <p className="text-[1rem] font-inter font-normal text-io-black">
                                    {pageTitle}
                                  </p>
                                </div>
                                <div className="w-full h-auto px-2 py-2">
                                  <div className="w-full h-auto flex justify-between items-center">
                                    <p className="text-[1rem] font-inter font-normal text-io-primary">
                                      {selectedScreen?.orderNo}/{processData.length}{" "}
                                      <span className="text-[0.8rem] text-io-black">
                                        Questions
                                      </span>
                                    </p>
                                    <p className="text-[1rem] font-inter font-normal text-io-primary">
                                      &lt; &gt; &gt; &gt;
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="w-full h-auto my-[0.3rem]">
                                <div className="w-full flex justify-between items-center gap-1">
                                  {processData.map((item: any, index: number) => (
                                    <div
                                      key={index}
                                      className={`w-full h-[2px] bg-io-primary ${
                                        index < selectedScreen?.orderNo
                                          ? "opacity-100"
                                          : "opacity-50"
                                      }`}
                                    ></div>
                                  ))}
                                </div>
                              </div>
                              <div className="w-full p-2 flex-auto basis-0 overflow-y-auto scrollbar-small">
                                <div className="w-full h-full">
                                  {selectedScreen?.questionDetails.map(
                                    (item: any, i: any) =>
                                      renderResponseComponent(item)
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
            default:
                return null;
        }
    };

    return (
        <ModalPageWrapper>
            <div className="w-full h-full flex justify-center items-center">
                <div className="w-[85%] h-auto bg-io-white dark:bg-io-black-1a rounded-[2.2rem] max-md:w-[90%]">
                    <div className="w-full h-full py-[4%]">
                        <div className="w-full h-auto flex justify-end mb-4 px-12">
                            <IconBtn
                                size="w-[3rem]"
                                icon={<PageModalCloseIcon />}
                                handleClick={() => handleCancel()}
                            />
                        </div>
                        <div className="w-full h-auto">
                            <div className="w-full h-auto flex justify-between items-center gap-4">
                                <div className="w-[17.5rem] h-auto">
                                    <div className="w-full h-auto flex flex-col justify-center items-start">
                                        {tabs.map(tab => (
                                            <button type="button" key={tab.id} className={`w-full h-[17rem] relative before:content-[''] before:w-[0.7rem] before:h-full before:bg-io-primary before:absolute before:top-0 before:left-0 rounded-[0.8rem] overflow-hidden ${selectedTab === tab.id ? 'bg-[#EDF8FF] text-io-primary fill-io-primary before:block dark:bg-io-black-29' : 'bg-io-white text-io-gray-66 fill-io-gray-66 before:hidden dark:bg-transparent dark:text-io-black-d1 dark:fill-io-white'}`} onClick={() => handleTabChange(tab.id)}>
                                                <div className="w-auto flex flex-col justify-center items-center gap-3">
                                                <div className="w-auto h-[5rem]">
                                                    {tab.icon}
                                                </div>
                                                    <p className="text-[2.6rem] font-inter font-normal text-inherit">{tab.label}</p>
                                                </div>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="flex-1 h-auto">
                                    <div className="w-full h-auto">
                                        {renderSelectedDevice()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalPageWrapper>
    )
}
export default ProcessPreviewModalNew;
