import React, { useState } from "react";
import { InputFieldWithoutForm } from "../input-fields";
import { TagCloseIcon } from "../../svg";
import { v4 as uuidv4 } from "uuid";

interface Tag {
  uniqueId: string;
  name: string;
  isInputLabel: number;
  orderId: number;
}

function EnterTagsInput({ labelName, idName, tags, setTags, type }: any) {
  const generateGuid = () => uuidv4();
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleInputChange = (value: string): void => {
    setInputValue(value);
    setError(""); // Clear error message on input change
  };

  const handleAddTag = (e: any): void => {
    e.preventDefault();
    if (inputValue.trim() !== "") {
      const trimmedInputValue = inputValue.trim();
      const isDuplicate = tags.some(
        (tag: any) =>
          tag.name === trimmedInputValue || tag === trimmedInputValue
      );

      if (isDuplicate) {
        setError("Duplicate tag not allowed");
      } else {
        if (type === 1) {
          setTags([...tags, trimmedInputValue]);
        } else {
          const newTag: Tag = {
            uniqueId: generateGuid(),
            name: trimmedInputValue,
            isInputLabel: 0,
            orderId: tags.length + 1,
          };
          setTags([...tags, newTag]);
        }
        setInputValue("");
        setError(""); // Clear error message on successful add
      }
    }
  };

  const handleDeleteTagIndex = (e: any, index: number): void => {
    e.preventDefault();
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  const handleDeleteTag = (tagUniqueId: string): void => {
    setTags(tags.filter((tag: Tag) => tag.uniqueId !== tagUniqueId));
  };

  return (
    <div className="w-full h-auto">
      <InputFieldWithoutForm
        labelName={labelName}
        type="text"
        id={idName}
        value={inputValue}
        onChange={handleInputChange}
        hasAddBtn={true}
        handleAdd={(e) => handleAddTag(e)}
      />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {type === 1 ? (
        <>
          {tags && tags.length > 0 && (
            <div className="w-full h-auto p-6 bg-io-gray-f8 dark:bg-io-black-1a mt-[0.3rem]">
              <div className="w-full h-auto flex justify-start items-center flex-wrap gap-[0.8rem]">
                {tags.map((tag: string, index: number) => (
                  <div
                    key={index}
                    className="w-auto flex justify-center items-center gap-[0.7rem] px-[0.8rem] py-[0.5rem] bg-io-primary rounded-full"
                  >
                    <p className="text-[1.2rem] font-inter font-normal text-io-white">
                      {tag}
                    </p>
                    <button
                      type="button"
                      className="w-4 h-auto"
                      onClick={(e) => handleDeleteTagIndex(e, index)}
                    >
                      <TagCloseIcon />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {tags && tags.length > 0 && (
            <div className="w-full h-auto p-6 bg-io-gray-f8 dark:bg-io-black-1a mt-[0.3rem]">
              <div className="w-full h-auto flex justify-start items-center flex-wrap gap-[0.8rem]">
                {tags.map((tag: Tag) => (
                  <div
                    key={tag.uniqueId}
                    className="w-auto flex justify-center items-center gap-[0.7rem] px-[0.8rem] py-[0.5rem] bg-io-primary rounded-full"
                  >
                    <p className="text-[1.2rem] font-inter font-normal text-io-white">
                      {tag.name}
                    </p>
                    {tag.isInputLabel === 0 && (
                      <button
                        type="button"
                        className="w-4 h-auto"
                        onClick={() => handleDeleteTag(tag.uniqueId)}
                      >
                        <TagCloseIcon />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default EnterTagsInput;
