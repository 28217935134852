import { DragEvent, useEffect, useRef, useState } from "react";
import {
  CloseImageEditor,
  SegmentAddMaskIcon,
  SegmentRefreshIcon,
  SegmentRemoveMaskIcon,
  UploadIcon,
} from "../../svg";
import IconBtn from "../../form/icon-btn";
import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useTranslation } from "react-i18next";
import { samModelDetails } from "../../../services/authFunctions";
import uploadFile from "../../../services/uploadFile";

declare module "fabric" {
  interface Canvas {
    __eventListeners: any;
  }
}
declare module "fabric" {
  namespace fabric {
    interface Object {
      uniqueId?: any;
      isDeleted?: any;
    }
  }
}

function ImageEditorNew({
  data,
  defaultUniqueId,
  onObjectDataChange,
  error,
}: any) {
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const uploadFiles = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<boolean>(false);
  const { editor, onReady, selectedObjects } = useFabricJSEditor();
  const [activeButton, setActiveButton] = useState("");
  const [objectCoordinates, setObjectCoordinates] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [maskImage, setMaskImage] = useState<string>("");

  const formDataValue: any = new FormData();
  const { t } = useTranslation();

  const currentLabel = data.labels.find(
    (label: { uniqueId: any }) => label.uniqueId === defaultUniqueId
  );

  // Function to convert base64 image to SVG
  const convertBase64ToSVG = (
    base64Image: string,
    width: number,
    height: number
  ): string => {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
        <image href="${base64Image}" width="${width}" height="${height}" />
      </svg>
    `;
  };

  const setMaskOverlay = (base64Image: string) => {
    if (!editor || !fabric) {
      return;
    }
    base64Image = "data:image/jpeg;base64," + base64Image;
    // Convert base64 image to SVG
    const svgString = convertBase64ToSVG(base64Image, 500, 300); // Adjust dimensions as needed

    // Create a temporary canvas to render SVG
    const tempCanvas = document.createElement("canvas");
    const tempContext = tempCanvas.getContext("2d");
    if (tempContext) {
      const img = new Image();
      img.onload = () => {
        tempCanvas.width = img.width;
        tempCanvas.height = img.height;
        tempContext.drawImage(img, 0, 0);
        const dataURL = tempCanvas.toDataURL();

        // Load the image data URL into fabric
        fabric.Image.fromURL(dataURL, (fabricImg) => {
          fabricImg.set({
            opacity: 0.5,
          });

          // Set the SVG as an overlay on the canvas
          editor.canvas.setOverlayImage(
            fabricImg,
            editor.canvas.renderAll.bind(editor.canvas)
          );
        });
      };
      img.src = "data:image/svg+xml;base64," + btoa(svgString);
    }
  };
  // const setMaskOverlay = (base64Image: string) => {
  //   if (!editor || !fabric) {
  //     return;
  //   }
  //   // Use the provided base64 image as the overlay image
  //   fabric.Image.fromURL("data:image/jpeg;base64," + base64Image, (img) => {
  //     img.set({ opacity: 0.5 });
  //     editor.canvas.setBackgroundImage(
  //       img,
  //       editor.canvas.renderAll.bind(editor.canvas)
  //     );
  //     if (Number(img.width) >= 100 || Number(img.height) >= 100) {
  //       const zoomLevel = Math.min(
  //         Number(editor.canvas.width) / Number(img.width),
  //         Number(editor.canvas.height) / Number(img.height)
  //       );
  //       const centerX = Number(editor.canvas.width) / 20;
  //       const centerY = Number(editor.canvas.height) / 20;
  //       editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
  //     } else {
  //       editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
  //     }
  //   });
  // };

  const renderImage = (base64URL: string) => {
    if (!editor || !fabric) {
      return;
    }

    fabric.Image.fromURL(base64URL, function (img) {
      editor.canvas.setBackgroundImage(
        img,
        editor.canvas.renderAll.bind(editor.canvas)
      );
      if (Number(img.width) >= 100 || Number(img.height) >= 100) {
        const zoomLevel = Math.min(
          Number(editor.canvas.width) / Number(img.width),
          Number(editor.canvas.height) / Number(img.height)
        );
        const centerX = Number(editor.canvas.width) / 20;
        const centerY = Number(editor.canvas.height) / 20;
        editor.canvas.zoomToPoint({ x: centerX, y: centerY }, zoomLevel);
      } else {
        editor.canvas.zoomToPoint({ x: 1, y: 1 }, 1.4);
      }
    });
  };
  const valueArray: Array<[number, number, number]> = [];
  // Moved handleCanvasClick function outside of useEffect
  const handleCanvasClick = async (
    event: fabric.IEvent,
    actionType: string
  ) => {
    if (!editor || !fabric) {
      return;
    }

    const pointer = editor.canvas.getPointer(event.e);
    const dot = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      radius: 10, // Radius of the dot
      fill: actionType === "add" ? "green" : "red", // Green for add, Red for remove
      selectable: false, // Prevents the dot from being selected
    });

    // Add the dot to the canvas
    editor.canvas.add(dot);
    if (actionType === "add") {
      const x = Math.round(pointer.x); // Round X to the nearest integer
      const y = Math.round(pointer.y); // Round Y to the nearest integer
      valueArray.push([x, y, 1]); // Add the rounded values to the array
      // Add your logic for adding a mask here
    } else if (actionType === "remove") {
      const x = Math.round(pointer.x); // Round X to the nearest integer
      const y = Math.round(pointer.y); // Round Y to the nearest integer
      valueArray.push([x, y, 0]); // Add the rounded values to the array
      // Add your logic for removing an area here
    }
    var formdata = await generateFormData();
    if (formdata) {
      formDataValue.append(
        "Image",
        currentLabel.magicBlob,
        currentLabel.magicBlobName
      );
      formDataValue.append("Points", formdata[1].value);
      formDataValue.append("isFinal", formdata[2].value || "0");
      const response = await samModelDetails(formDataValue);
      setMaskImage(response.response.additionalDetails[0].labelValue);
      setMaskOverlay(response.response.additionalDetails[0].labelValue);
    }
  };
  const generateFormData = () => {
    const formdata = [
      {
        key: "Image",
        type: "file",
        src: currentLabel.magicBlob,
      },
      {
        key: "Points",
        value: JSON.stringify(valueArray),
        type: "text",
      },
      {
        key: "isFinal",
        value: "1",
        type: "text",
      },
    ];

    return formdata;
  };
  const handleButtonClick = (actionType: string) => {
    setActiveButton(actionType);
    if (editor && editor.canvas) {
      editor.canvas.off("mouse:down"); // Remove any existing mouse:down event listener
      editor.canvas.on("mouse:down", (event) =>
        handleCanvasClick(event, actionType)
      );
    }
  };

  // Update the useEffect hook
  useEffect(() => {
    if (maskImage) {
      setMaskOverlay(maskImage);
    }
  }, [maskImage]);

  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }
    if (defaultUniqueId) {
      editor.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    }

    // Cleanup event listener on unmount
    return () => {
      editor.canvas.off("mouse:down");
    };
  }, [defaultUniqueId, editor, fabric]);

  useEffect(() => {
    if (editor || fabric) {
      if (currentLabel && currentLabel.magicUrl) {
        setSelectedImage(true);
        renderImage(currentLabel.magicUrl);
      } else {
        setSelectedImage(false);
      }
    }
  }, [currentLabel, editor, fabric]);

  const renderCanvas = (files: any) => {
    const file = files[0];
    const fileName = file.name;
    const fileType = fileName.split(".").pop();
    const extension = fileType ? fileType.toLowerCase() : "";
    const reader = new FileReader();
    editor?.canvas.renderAll();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        setSelectedImage(true);
        const base64URL = e.target.result.toString();
        currentLabel.magicUrl = base64URL;

        let binaryData: ArrayBuffer | null = e.target.result as ArrayBuffer; // Directly use the ArrayBuffer
        const blob = new Blob([binaryData], { type: file.type });

        console.log(formDataValue);
        currentLabel.magicBlob = file;
        currentLabel.magicBlobName = file.name;
        renderImage(base64URL);
      }
    };
    reader.readAsDataURL(file);
    uploadFile(file, extension).then(({ response, fileUrl }) => {
      if (response.success) {
        currentLabel.magicUrl = response.data.fileUrl;
      }
    });
  };

  const handleFileInputChange = (): void => {
    if (
      uploadFiles.current &&
      uploadFiles.current.files &&
      uploadFiles.current.files.length > 0
    ) {
      renderCanvas(uploadFiles.current.files);
    }
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      renderCanvas(files);
    }
  };

  return (
    <div className="w-full h-auto">
      <div className="w-full h-auto relative mb-[7rem]">
        <div className="w-full h-full">
          <div className="mb-4">
            <p>X: {objectCoordinates.x}</p>
            <p>Y: {objectCoordinates.y}</p>
          </div>
          <div
            className={`w-full h-full py-[25%] border border-dashed rounded-[8px] ${
              isDragging ? "border-green-500" : "border-io-primary"
            }`}
            ref={dropZoneRef}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div className="w-full h-full p-8">
              <input
                type="file"
                id="uploadFiles"
                onChange={handleFileInputChange}
                onClick={(event: any) => {
                  event.target.value = null;
                }}
                ref={uploadFiles}
                accept="image/*"
                className="hidden w-0 h-0"
              />
              <div className="w-full h-full flex justify-center items-center pointer-events-none">
                <div className="w-auto h-auto">
                  <div className="w-auto h-auto flex justify-center items-center gap-4">
                    <div className="w-[5.7rem] h-auto">
                      <UploadIcon />
                    </div>
                    <div className="w-auto">
                      <label
                        htmlFor="uploadFiles"
                        className="text-[1.2rem] font-inter font-medium text-io-primary inline-block mr-3 pointer-events-auto"
                      >
                        {t("web.imageeditor.title.choosefile")}
                      </label>
                      <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white inline-block">
                        {t("web.imageeditor.title.ordragand")}
                      </p>
                      <p className="text-[1.2rem] font-inter font-medium text-io-black dark:text-io-white">
                        {t("web.imageeditor.title.dropimage")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {error && (
          <p className="text-[1.4rem] font-inter font-normal text-io-red text-left absolute top-full left-0">
            {error}
          </p>
        )}
        <div className="w-[8.5rem] h-auto absolute left-[102%] top-0">
          <div className="w-full p-[1.2rem] bg-[#F4FAFF] rounded-[0.4rem]">
            <div className="w-full grid grid gap-x-[2rem] gap-[2rem] pb-[2rem] border-b border-[#ECECEC] ">
              {/* <div className="w-[2rem] h-[2rem] flex justify-center items-center">
                <IconBtn icon={<SegmentRectangleIcon />} size="w-[1.8rem]" />
              </div> */}
              <div className="w-[1.8rem] h-auto mx-auto">
                <IconBtn icon={<SegmentRefreshIcon />} size="w-[1.8rem]" />
              </div>
              {/* <div className="w-[2rem] h-[2rem] flex justify-center items-center">
                <IconBtn icon={<SegmentUndoIcon />} size="w-[1.8rem]" />
              </div>
              <div className="w-[2rem] h-[2rem] flex justify-center items-center">
                <IconBtn icon={<SegmentRedoIcon />} size="w-[1.8rem]" />
              </div> */}
            </div>
            <div className="w-full py-[1.8rem]">
              <div
                className={`w-full mb-[2rem] border-none bg-transparent ${
                  activeButton === "add"
                    ? "bg-grey-600 text-white opacity-100"
                    : "opacity-50"
                }`}
              >
                <button
                  type="button"
                  className="border-none bg-transparent opacity-50"
                  onClick={() => handleButtonClick("add")}
                >
                  <div className="w-[1.8rem] h-auto mx-auto">
                    <SegmentAddMaskIcon />
                  </div>
                  <p className="text-[1.2rem] font-inter font-normal text-io-black">
                    Add Mask
                  </p>
                </button>
              </div>
              <div
                className={`w-full border-none bg-transparent ${
                  activeButton === "remove"
                    ? "bg-grey-600 text-white opacity-100"
                    : "opacity-50"
                }`}
              >
                <button
                  type="button"
                  className="border-none bg-transparent opacity-50"
                  onClick={() => handleButtonClick("remove")}
                >
                  <div className="w-[1.8rem] h-auto mx-auto">
                    <SegmentRemoveMaskIcon />
                  </div>
                  <p className="text-[1.2rem] font-inter font-normal text-io-black">
                    Remove Area
                  </p>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`w-full h-full absolute top-0 left-0 ${
            selectedImage
              ? "visible pointer-events-auto"
              : " invisible pointer-events-none"
          }`}
        >
          <div className="w-full h-full relative bg-[#F4FAFF] dark:bg-io-black-15">
            <div className="w-full h-full rounded-[8px] overflow-hidden border-2 border-[#0078D4] ">
              <FabricJSCanvas className="w-full h-full" onReady={onReady} />
            </div>
            <div className="w-auto h-auto absolute top-2 right-2">
              <IconBtn size="w-[2rem]" icon={<CloseImageEditor />} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ImageEditorNew;
