import { useEffect, useRef, useState } from "react";
import PageHeader from "../../components/global-layout/page-header";
import ProcessGrid from "../../components/section/process/process-grid";
import { ProcessPageHeaderIcon } from "../../components/svg";
import ProcessMoreInfo from "../../components/section/process/process-more-info";
import ProcessCards from "../../components/section/process/process-cards";
import GridToolBar from "../../components/UI/grid-toolbar";
import GridColumn from "../../components/section/user-management/grid-columns";
import PageLoader from "../../components/UI/page-loader";
import { processServices } from "../../services/InspectionOneServices";
import excelExport from "../../services/excelExport";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import Authentication from "../../auth/Authentication";

interface Item {
  processName: string;
  processLogic: string;
  inspectedBy: string;
  verificationStatus: string;
  createdDate: string;
  id: string;
}

function Process() {
  const authService = Authentication();
  const { t } = useTranslation();
  const generateGuid = () => {
    return uuidv4();
  };
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [processData, setProcessData] = useState<any>([]);
  const [statusDetail, setStatusDetail] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [pageLoader, setPageLoader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [processUpdated, setProcessUpdated] = useState<any>(undefined);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [accessMode, setAccessMode] = useState<string>("");
  const [userProfileImage, setUserProfileImage] = useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "processName",
    "processLogic",
    "subProcessLogic",
    "inspectedBy",
    "createdDate",
    "verificationStatus",
  ]);

  useEffect(() => {
    let parsedLoginDetails = null;
    parsedLoginDetails = authService.decryptData("UserDetails", 2);
    parsedLoginDetails = parsedLoginDetails || "{}";
    console.log(parsedLoginDetails);
    setAccessMode(parsedLoginDetails.isViewAccess);
    setUserProfileImage(parsedLoginDetails.imageUrl);
  }, []);

  const columnsData = [
    {
      label: "Process Name",
      token: "web.process.label.name",
      key: "processName",
    },
    {
      label: "Logic Name",
      token: "web.common.label.logicname",
      key: "processLogic",
    },
    {
      label: "Screen Mapping Name",
      token: "web.common.label.screenmappingname",
      key: "subProcessLogic",
    },
    {
      label: "Inspected By",
      token: "web.common.label.inspectedby",
      key: "inspectedBy",
    },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdDate",
    },
    {
      label: "Verification Status",
      token: "web.common.label.verificationstatus",
      key: "verificationStatus",
    },
  ];

  const handleOpenMoreInfo = (item: Item) => {
    setSelectedItem(item);
    setShowMoreInfo(true);
  };

  const handleRefresh = () => {
    if (!isLoading) {
      setProcessData([]);
      setSortingColumnName("");
      setSorting(0);
      setStartIndex(0);
      setIsRefreshed((prev: any) => !prev);
      setPageLoader(false);
      setSearchedQuery("");
      setSearchedInputVal("");
      setResetSearch(true);
    }
  };
  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };

  //#region Use Effect
  useEffect(() => {
    if (isGridView === true) {
      setToolItems(["search", "export", "refresh"]);
    } else {
      setToolItems(["search", "export", "columns", "refresh"]);
    }
  }, [isGridView]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setProcessData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    fetchProcessData(searchedInputVal);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isRefreshed,
  ]);
  const handleNavigate = async (cardData: any) => {
    setPageLoader(true);

    const webSdkUrl = process.env.REACT_APP_WEB_SDK_URL;

    if (cardData && cardData.id) {
      const baseUrl = `${webSdkUrl}/InspectionOne`;
      try {
        const requestJson = {
          uniqueId: generateGuid(),
          logicUniqueId: cardData.processLogicId,
          subLogicUniqueId: cardData.subProcessLogicId,
          processUniqueId: cardData.id,
          sdkPortalLink: baseUrl,
          UserType:
            cardData.verificationStatus === "Verified"
              ? "External"
              : accessMode === "0"
              ? "Internal"
              : "External",
          languageCode: "en",
          userProfileImage: userProfileImage,
        };
        const response: any = await processServices.ProcessAuthTokenAction(
          requestJson
        );
        if (response.success) {
          const { authToken, siteId, languageCode } = response.data;
          const url = `${baseUrl}/${authToken}/${siteId}/${languageCode}`;
          window.open(url, "_blank");
        }
        setPageLoader(false);
      } catch (error) {
        setPageLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  //#endregion
  const getProcessStatusData = (
    processLogicId: any,
    subProcessLogicId: any,
    statusDetail: any,
    statusId: any
  ) => {
    if (statusDetail) {
      const optionData =
        (statusDetail[processLogicId] &&
          statusDetail[processLogicId][subProcessLogicId]) ||
        [];
      const filteredStatuses = optionData.filter((status: any) => {
        return status.id === statusId;
      });
      return filteredStatuses;
    }
    return [];
  };

  //#region list Fetch
  const fetchProcessData = async (query: any) => {
    if (!isLoading) {
      setIsLoading(true);
      setIsSkeletonLoader(true);
      setFinalFetch(false);
      setNoDataFound(false);
      try {
        const requestJson = {
          filterDetails: {
            searchValue: query,
            startIndex: startIndex,
            limit: limit,
            startDate: "",
            endDate: "",
            sorting: sorting,
            columnName: sortingColumnName,
          },
        };
        const response: any = await processServices.ProcessLists(requestJson);
        if (response.success) {
          const responseData = response.data.processDetails;
          setTotalCount(response.data.totalCount);
          setStatusDetail(response.data.statusDetail);
          const newArray = responseData.map((item: any) => ({
            processLogicId: item.processLogicId,
            subProcessLogicId: item.subProcessLogicId,
            processName: item.title === "" ? "No Label" : item.title,
            processLogic: item.processLogicName,
            subProcessLogic: item.subProcessLogicName,
            inspectedBy: item.createdBy,
            verificationStatus: item.displayStatus,
            isViewOnly: item.isViewOnly,
            verificationColour: getProcessStatusData(
              item.processLogicId,
              item.subProcessLogicId,
              response.data.statusDetail,
              item.statusId
            ),
            createdDate: item.createdAt,
            moreDetails: item.moreDetails,
            statusId: item.statusId,
            processLogicUniqueId: item.processLogicUniqueId,
            icon: item.icon,
            uniqueId: item.uniqueId,
            id: item.id,
            isOverridden: item.isOverridden,
          }));
          setProcessData((prevData: any) => [...prevData, ...newArray]);
          setIsLoading(false);
          setIsSkeletonLoader(false);
          if (!responseData.length) {
            setNoDataFound(true);
          } else {
            setNoDataFound(false);
          }
          if (startIndex < limit) {
            setIntialLoading(false);
          }
        }
        // setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsSkeletonLoader(false);
        console.error("Error fetching data:", error);
      }
    }
  };
  //#endregion
  //#region Scroll

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setProcessData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "ailogicresults";
    const fileName = "AI Logic Results";
    const sheetName = "AI_Logic_Results";
    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };

  //#endregion

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <PageHeader
          title={t("web.toolbar.label.ailogicresults")}
          icon={<ProcessPageHeaderIcon />}
        />
        <div className="w-full flex-1">
          <div className="w-full h-full flex flex-col">
            <GridToolBar
              columnCount={totalCount}
              gridType={t("web.toolbar.label.ailogicresults")}
              selectView={true}
              tools={toolItems}
              handleDelete={() => setShowDeletePopup(true)}
              handleColumns={() => setShowColumns(true)}
              isGridView={isGridView}
              fetchData={(query: any) => setSearchedQuery(query)}
              getSearchedQuery={(query: any) => setSearchedQuery(query)}
              handleRefresh={handleRefresh}
              handleExcel={handleExcel}
              isExcelLoading={isExcelLoading}
              isSkeletonLoader={isSkeletonLoader}
              resetSearch={resetSearch}
              setIsGridView={(isChecked: any) => setIsGridView(isChecked)}
            />
            <div className="w-full flex-1">
              {isGridView ? (
                <ProcessCards
                  handleNavigate={handleNavigate}
                  rowData={processData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  handleOpenMoreInfo={handleOpenMoreInfo}
                  noDataFound={noDataFound}
                />
              ) : (
                <ProcessGrid
                  selectedColumns={selectedColumns}
                  rowSelection={false}
                  moreOptions={true}
                  handleNavigate={handleNavigate}
                  rowData={processData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  sorting={sorting}
                  sortingColumnName={sortingColumnName}
                  handleSort={handleSort}
                  handleCheckboxChange={handleCheckboxChange}
                  handleOpenMoreInfo={handleOpenMoreInfo}
                  noDataFound={noDataFound}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showMoreInfo && (
        <ProcessMoreInfo
          statusDetail={statusDetail}
          selectedItem={selectedItem ? [selectedItem] : []}
          handleClose={() => setShowMoreInfo(false)}
          processUpdated={(val: boolean) => setProcessUpdated(val)}
          handleRefresh={handleRefresh}
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}
      {pageLoader && <PageLoader />}
    </>
  );
}
export default Process;
