// RequireAuth.tsx
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Assuming AuthContext provides authentication context

interface RequireAuthProps {
  children: JSX.Element;
  requireLogin?: boolean;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  requireLogin = false,
}) => {
  const { isAuthenticated, isDomainAuthenticated } = useAuth();
  const location = useLocation();
  if (!isDomainAuthenticated) {
    // If not authenticated with domain, redirect to login
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (requireLogin && !isAuthenticated) {
    // If route requires full login and not authenticated, redirect to login
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // If authenticated, render the children
  return children;
};

export default RequireAuth;
