import React from "react";
import { useState, useEffect } from "react";
import Authentication from "./../auth/Authentication";

function Dashboard() {
  const authService = Authentication();
  const [reportUrl, setReportUrl] = useState("");

  const powerBiUrl = process.env.REACT_APP_POWER_BI_URL;

  useEffect(() => {
    // Retrieve the Power BI report URL from session storage
    const setTenantId = sessionStorage.getItem("tenantId");
    var setSiteId = sessionStorage.getItem("siteId");
    const sourceId = 1;
    var userDetails = authService.decryptData("UserDetails", 2);
    var typeId = 2;
    if (userDetails.lastAccess === -1) {
      typeId = 1;
    }
    const url = `${powerBiUrl}?tenantId=${setTenantId}&siteId=${setSiteId}&sourceId=${sourceId}&typeId=${typeId}`;
    setReportUrl(url);
  }, []);

  return (
    <div>
      <iframe
        title="Power BI Report"
        width="100%"
        height="625px"
        src={reportUrl}
        allowFullScreen={true}
      ></iframe>
    </div>
  );
}

export default Dashboard;
