import { useEffect, useRef, useState } from "react";

import "../../user-management/user-management-grid/user-management-grid.css";
import { useNavigate, useParams } from "react-router-dom";
import GridToolBar from "../../../UI/grid-toolbar";
import InfiniteScrollGrid from "../../../UI/infinite-scroll-grid";
import {
  DefaultNameCellRenderer,
  MoreOptionsRenderer,
} from "../../../UI/grid-cells";
import { masterAccess } from "../../../../services/InspectionOneServices";
import { useTranslation } from "react-i18next";
import { ToastError, ToastSuccess, ToastWarning } from "../../../UI/toast";
import ToastifyMessage from "../../../../lib/toastify";

function UserMasterGrid({
  handleDelete,
  rowSelection,
  moreOptions,
  userMasterUpdated,
  setShowColumns,
  setUserMasterUpdated,
  handleDataDelete,
  setShowDeletePopup,
  setHeaderDetails,
  setMasterTableName,
}: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [startIndex, setStartIndex] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [finalFetch, setFinalFetch] = useState(false);
  const [limit] = useState(20);
  const [sorting, setSorting] = useState<number>(0);
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [masterAccessData, setMasterAccessData] = useState<any>([]);
  const [initialLoading, setIntialLoading] = useState(true);
  const chatGroundRef = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [toolItems, setToolItems] = useState<any>([
    "search",
    "addrow",
    "import",
    // "gridfilter",
    // "export",
    "sampleexcel",
    "columns",
    "refresh",
  ]);
  const [isGridView, setIsGridView] = useState(false);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<any>(false);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [isDeleted, setIsDeleted] = useState<boolean>(false);

  const [masterColumnsData, setMasterColumnsData] = useState<any>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [columnDefs, setColumnDefs] = useState<any>([]);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const [resetSearch, setResetSearch] = useState<any>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);

  const [tableName, setTableName] = useState("");

  const { ToastifyHandle } = ToastifyMessage();

  const handleAddRow = (data: void) => {
    navigate("/master-details/user-master/" + id + "/user-master-new/new");
  };

  const handleRefresh = () => {
    setMasterAccessData([]);
    setStartIndex(0);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
    setSearchedQuery("");
    setSearchedInputVal("");
    setResetSearch(true);
  };

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setStartIndex(0);
      setMasterAccessData([]);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);

  useEffect(() => {
    if (userMasterUpdated === true) {
      setMasterAccessData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
      masterDetailsFetch(searchedInputVal);
    }
  }, [userMasterUpdated]);

  useEffect(() => {
    masterDetailsFetch(searchedInputVal);
    setUserMasterUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    searchedInputVal,
    isUpdated,
    isDeleted,
    isRefreshed,
  ]);

  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = chatGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };

  const handleSort = (column: any, value: number, columnName: string) => {
    console.log(column.field);
    setMasterAccessData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };

  const handleEditDetails = (data: any) => {
    navigate("/user-master/" + id + "/user-master-new/" + data.id);
  };

  const handleExcelDownload = () => {
    // Check if fileUrl is not empty
    if (fileUrl) {
      // Create a temporary anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.setAttribute("download", "Sample Excel.xlsx");

      // Append the anchor to the body and trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up by removing the anchor
      document.body.removeChild(downloadLink);
    } else {
      handleWarning(
        "web.master.label.masterValues",
        "web.master.label.emptysampleurl"
      );
    }
  };

  const masterDetailsFetch = async (query: any) => {
    setIsLoading(true);
    setFinalFetch(false);
    setNoDataFound(false);
    try {
      const requestJson = {
        accessUniqueId: id,
        filterDetails: {
          searchValue: "",
          startIndex: startIndex,
          limit: limit,
          startDate: "",
          endDate: "",
        },
      };
      console.log(requestJson);
      const response: any = await masterAccess.MasterDetailsFetch(requestJson);
      if (response.success) {
        const responseMasterData = response?.data?.masterDetailsList;
        const columnsData = response.data.columnHeaders;
        const columnKeys = response.data.columnKeys;
        setMasterAccessData((prevData: any) => [
          ...prevData,
          ...responseMasterData,
        ]);
        console.log(response);

        setColumnDefs(columnsData);

        setMasterTableName(response.data.originalMasterName);
        setTableName(response.data.originalMasterName);
        setSelectedColumns(columnKeys);
        setHeaderDetails(columnsData);
        setTotalCount(response.data.masterDetailsList.length);
        setFileUrl(response.data.excelFileUrl);
        setIsLoading(false);
        if (responseMasterData.length === 0) {
          setNoDataFound(true);
        } else {
          setNoDataFound(false);
        }
        if (startIndex < limit) {
          setIntialLoading(false);
        }
      } else {
        console.log(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  console.log(masterAccessData);
  const selectedMoreOption = (option: string, row: any) => {
    if (option === "edit") {
      navigate(
        "/master-details/user-master/" + id + "/user-master-new/" + row.id
      );
    } else if (option === "delete") {
      handleDelete(row);
    }
  };

  const [fileName, setFileName] = useState("null");

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    const file = event.target.files && event.target.files[0];
    if (file) {
      try {
        const extension = file.name.split(".").pop() || "";
        const { response } = await uploadFile(file, extension);
        if (response.success) {
          handleSuccess(
            "web.master.label.masterValues",
            "Master excel uploaded succesfully"
          );
        } else {
          handleError("web.master.label.masterValues", response.message);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
      setIsLoading(false);
    }
  };

  async function uploadFile(
    file: File,
    extension: string
  ): Promise<{ response: any; fileUrl: string }> {
    return new Promise(async (resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target && e.target.result) {
          try {
            // Perform the file upload operation in the background
            let binaryData: ArrayBuffer | null = e.target.result as ArrayBuffer;
            // Make a call to your service function passing binaryData and id
            const response = await masterAccess.MasterDetailsExcelUpload(
              binaryData,
              extension,
              id
            );
            // Resolve the promise with the response and file URL
            resolve({ response, fileUrl: e.target.result.toString() });
          } catch (error) {
            console.error("Error uploading file:", error);
            reject(error); // Reject the promise if an error occurs
          }
        }
      };
      reader.readAsArrayBuffer(file); // Read file as ArrayBuffer
    });
  }

  const handleSuccess = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastSuccess title={titleName} description={message} />,
      "success"
    );
  };
  const handleError = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastError title={titleName} description={message} />,
      "error"
    );
  };
  const handleWarning = (titleName: any, message: any) => {
    ToastifyHandle(
      <ToastWarning title={titleName} description={message} />,
      "warning"
    );
  };

  useEffect(() => {
    if (handleDataDelete?.id) {
      handleRowDataDelete(handleDataDelete);
    }
  }, [handleDataDelete]);

  const currentUrl: string = window.location.href;
  const parts = currentUrl.split("/");
  const finalValue = parts[parts.length - 1];

  const handleRowDataDelete = async (rowData: any) => {
    setIsLoading(true);
    setShowDeletePopup(false);
    try {
      const requestJson = {
        accessUniqueId: finalValue,
        masterValueId: rowData.id,
        actionMode: 2,
        masterDetails: [rowData],
      };
      console.log(requestJson);
      console.log(rowData.id);
      const response = await masterAccess.MasterDetailsAction(requestJson);
      if (response.success) {
        setIsLoading(false);
        setMasterAccessData([]);
        setStartIndex(0);
        setIsDeleted((prev) => !prev);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full h-full flex flex-col">
      <GridToolBar
        columnCount={totalCount}
        gridType={`${tableName} ${t(
          totalCount === 1
            ? "web.toolbar.label.value"
            : "web.toolbar.label.values"
        )}`}
        selectView={false}
        tools={toolItems}
        handleColumns={() => setShowColumns(true)}
        handleAddRow={handleAddRow}
        handleImport={handleImport}
        handleExcelDownload={handleExcelDownload}
        isGridView={isGridView}
        fetchData={(query: any) => setSearchedQuery(query)}
        getSearchedQuery={(query: any) => setSearchedQuery(query)}
        handleRefresh={handleRefresh}
        resetSearch={resetSearch}
        isSkeletonLoader={isSkeletonLoader}
        setIsGridView={(isChecked:any) => setIsGridView(isChecked)}
      />
      <div className="w-full flex-1">
        <InfiniteScrollGrid
          columnDefs={columnDefs}
          rowData={masterAccessData}
          initialLoading={initialLoading}
          searchedQuery=""
          handleScroll={handleScroll}
          chatGroundRef={chatGroundRef}
          selectedColumns={selectedColumns}
          isLoading={isLoading}
          finalFetch={finalFetch}
          rowSelection={rowSelection}
          moreOptions={moreOptions}
          handleSort={handleSort}
          sorting={sorting}
          sortingColumnName={sortingColumnName}
          noDataFound={noDataFound}
        >
          {masterAccessData?.map((rowData: any, i: number) => (
            <tr
              key={i}
              className="w-full h-[5.5rem] flex justify-between p-[0.65rem] border-b border-[#EDEDED] hover:bg-[#eff8ff] dark:hover:bg-[#112A46]"
            >
              {selectedColumns.map((column) => (
                <DefaultNameCellRenderer
                  cellKey={column}
                  selectedColumns={selectedColumns}
                  rowData={rowData}
                />
              ))}
              <MoreOptionsRenderer
                rowData={rowData}
                moreOptions={moreOptions}
                selectedMoreOption={selectedMoreOption}
              />
            </tr>
          ))}
        </InfiniteScrollGrid>
      </div>
    </div>
  );
}
export default UserMasterGrid;
