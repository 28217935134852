
import { Fragment, useState } from "react";
import {
    DropDownIcon,
    ExpandIcon,
    InfoIcon,
    PageModalCloseIcon,
    PreviewCameraIcon,
    PreviewNoteIcon,
    PreviewSignatureIcon,
    ResponseAudioIcon,
    ResponseDateIcon,
    ResponseDateTimeIcon,
    ResponseImageIcon,
    ResponseImageWithInputIcon,
    ResponseLocationIcon,
    ResponseMagicContainerIcon,
    ResponseNumberIcon,
    ResponsePersonNameIcon,
    ResponseRangeDateIcon,
    ResponseScannerIcon,
    ResponseSiteNameIcon,
    ResponseTenantNameIcon,
    ResponseTextIcon,
    ResponseTimeIcon,
    ResponseToggleIcon,
    ResponseVideoIcon,
  } from "../../../svg";
import IconBtn from "../../../form/icon-btn";
export const PreviewTenantName = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseTenantNameIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewSiteName = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseSiteNameIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewPersonName = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponsePersonNameIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewLocation = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseLocationIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewScanner = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseScannerIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewMultipleChoice = ({ item, responseValueOptions }: any) => {
    console.log("responseValueOptions", item.optionGroupId, responseValueOptions);
  
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          {item.subType === 0 &&
            responseValueOptions.map((responseValue: any, index: number) => (
              <Fragment key={index}>
                {responseValue.groupId == item.optionGroupId && (
                  <div className="w-full ">
                    {responseValue?.options.map(
                      (option: any, index: number) =>
                        option.isDeleted === 0 && (
                          <div className="w-full flex justify-start items-center gap-4 mb-4">
                            <input type="radio" name="previewradio" />
                            <p>{option.value}</p>
                          </div>
                        )
                    )}
                  </div>
                )}
              </Fragment>
            ))}
          {item.subType === 1 &&
            responseValueOptions.map((responseValue: any, index: number) => (
              <Fragment key={index}>
                {responseValue.groupId == item.optionGroupId && (
                  <div className="w-full ">
                    {responseValue?.options.map(
                      (option: any, index: number) =>
                        option.isDeleted === 0 && (
                          <div className="w-full flex justify-start items-center gap-4 mb-4">
                            <input type="checkbox" name="previewradio" />
                            <p>{option.value}</p>
                          </div>
                        )
                    )}
                  </div>
                )}
              </Fragment>
            ))}
          {/* {item.subType === 0 &&
            <div className="w-full ">
              <div className="w-full flex justify-start items-center gap-4 mb-4">
                <input type="radio" name="previewradio" />
                <p>Yes</p>
              </div>
              <div className="w-full flex justify-start items-center gap-4">
                <input type="radio" name="previewradio" />
                <p>No</p>
              </div>
            </div>
          }
          {item.subType === 1 &&
            <div className="w-full ">
              <div className="w-full flex justify-start items-center gap-4 mb-4">
                <input type="checkbox" name="previewradio" />
                <p>All are working good</p>
              </div>
              <div className="w-full flex justify-start items-center gap-4">
                <input type="checkbox" name="previewradio" />
                <p>Power streeing</p>
              </div>
            </div>
          } */}
          {item.subType === 2 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.8rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <DropDownIcon />
              </div>
            </div>
          )}
          {item.subType === 3 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.8rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <DropDownIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  export const PreviewText = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseTextIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewNumber = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseNumberIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewDateTime = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          {item.subType === 0 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <ResponseDateIcon />
              </div>
            </div>
          )}
          {item.subType === 1 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <ResponseTimeIcon />
              </div>
            </div>
          )}
          {item.subType === 2 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <ResponseDateTimeIcon />
              </div>
            </div>
          )}
          {item.subType === 3 && (
            <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
              <div className="flex-1"></div>
              <div className="w-[1.5rem] h-auto">
                <ResponseRangeDateIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };
  export const PreviewAcknowledgement = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-start items-start gap-4">
            <div className="w-[2rem]">
              <input type="checkbox" name="previewAcknowledgement" />
            </div>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> */}
          </div>
        </div>
      </div>
    );
  };
  export const PreviewInformation = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-start items-start gap-4">
            <div className="w-[2rem]">
              <IconBtn icon={<InfoIcon />} size="w-[1.6rem]" />
            </div>
            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> */}
          </div>
        </div>
      </div>
    );
  };
  export const PreviewSignature = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full h-[15rem] bg-[#F5FAFD] flex justify-center items-center">
            <div className="w-[4rem]">
              <IconBtn icon={<PreviewSignatureIcon />} size="w-[4rem]" />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewImage = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseImageIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewImageWithInputs = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseImageWithInputIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewVideo = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseVideoIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewMagicContainer = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseMagicContainerIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export const PreviewAudio = ({ item }: any) => {
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className="w-full flex justify-between items-center border border-io-gray-c1 rounded-[3px] px-[0.5rem] py-[0.6rem]">
            <div className="flex-1"></div>
            <div className="w-[1.5rem] h-auto">
              <ResponseAudioIcon />
            </div>
          </div>
        </div>
      </div>
    );
  };
  export  const PreviewToggle = ({ item }: any) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleToggle = () => {
      setIsChecked(!isChecked);
    };
    return (
      <div className="w-full h-auto">
        <div className="w-full flex justify-between items-center">
          <p className="text-[1.2rem] font-inter font-medium text-io-black">
            {item.orderNo}. {item.title}{" "}
            {item.isMandatory && <span className="text-io-red">*</span>}
          </p>
          <div className="w-auto flex justify-end items-center gap-4">
            {item.isEvidence && (
              <div className="w-[1.5rem] h-auto">
                <PreviewNoteIcon />
              </div>
            )}
            {item.isNotes && (
              <div className="w-[1.5rem] h-auto">
                <PreviewCameraIcon />
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-[1rem]">
          <div className={`w-auto flex justify-start items-center gap-[0.7rem]`}>
            <p
              className={`text-[1.2rem] font-inter font-normal text-io-gray-66 dark:text-io-black-d1 `}
            ></p>
  
            <label
              htmlFor={"previewToggle"}
              className="w-auto inline-flex items-center cursor-pointer"
            >
              <input
                type="checkbox"
                id={"previewToggle"}
                className="sr-only"
                checked={!isChecked}
                onChange={handleToggle}
              />
              <div
                className={`w-[3rem] h-[1.5rem] rounded-full relative ${
                  !isChecked ? "bg-io-gray-c8" : "bg-io-primary"
                }`}
              >
                <div
                  className={`w-[1rem] h-[1rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                    !isChecked ? "translate-x-[25%]" : "translate-x-[170%]"
                  }`}
                ></div>
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  };