import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/global-layout/page-header";
import Mapping from "../../components/section/ai-logic-studio/mapping";
import { useForm, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Skeleton from "react-loading-skeleton";
import { v4 as uuidv4 } from "uuid";

import {
  screenMappingList,
  screenMappingAction,
} from "../../services/authFunctions";
import { useTranslation } from "react-i18next";
import { SelectInputWithoutForm } from "../../components/form/select-input";

import { typeOfResponse } from "../../services/common";
const schemaType: any = {
  1: yup.string(),
  2: yup.string(),
  3: yup.string(),
  4: yup.string(),
  5: yup.string(),
  6: yup.object(),
  7: yup.string(),
  8: yup.string(),
  9: yup.string(),
  10: yup.string(),
  11: yup.string(),
  12: yup.string(),
  13: yup.string(),
  14: yup.string(),
  15: yup.string(),
  16: yup.string(),
  17: yup.string(),
  18: yup.string(),
  19: yup.string(),
  20: yup.string(),
  21: yup.string(),
  22: yup.string(),
};

function MappingWrapper() {
  const { t } = useTranslation();
  const generateGuid = () => uuidv4();
  const navigate = useNavigate();
  const [mappingDetails, setMappingDetails] = useState<any>({});
  const [onDropdownChange, setOnDropdownChange] = useState(false);
  const [logicData, setLogicData] = useState<any>(null);
  const [allData, setAllData] = useState<any>(null);
  const [logicTotalCount, setLogicTotalCount] = useState<number | null>(0);
  const { logicId, id } = useParams();
  const [mappingMatchData, setMappingMatchData] = useState<any>({});
  const [dynamicShape, setDynamicShape] = useState<any>({});
  const [fieldData, setFieldData] = useState<any>([{}]);
  const [subProcess, setSubProcess] = useState<any>([{}]);
  const [isLoading, setIsLoading] = useState(false);
  const [getStartedOptions, setGetStartedOptions] = useState([]);
  const [getStartedDefaultValue, setGetStartedDefaultValue] = useState<any>({});

  const breadcrumbs = [
    { label: "AI Logic Studio", link: "/ai-logic-studio" },
    {
      label: "AI Sub Logic Studio",
      link: `/ai-logic-studio/process-logic/${logicId}`,
    },
    { label: "Automatic Inspection", link: undefined },
  ];

  const mappingFormShema = yup.object().shape({
    mappingItems: yup.array().of(
      yup.object().shape({
        ...dynamicShape,
        mappingGroupId: yup.mixed(),
        survey: yup
          .array()
          .test(
            "survey",
            "Please select at least one options",
            (value: any) => value?.length > 0
          ),
      })
    ),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    reset,
  } = useForm<any>({
    resolver: yupResolver(mappingFormShema),
    defaultValues: {
      mappingItems: [],
    },
  });
  const resetFormValues = () => {
    reset({
      mappingItems: [],
    });
    setFieldData([]);
    setDynamicShape({});
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappingItems",
  });

  const screenMappingListFetch = async (value: any, mode: any) => {
    setIsLoading(true);
    setSubProcess([{}]);
    setLogicData(null);
    setAllData(null);
    setLogicTotalCount(0);
    setMappingMatchData({});
    setMappingDetails([{}]);
    try {
      const response: any = await screenMappingList(id, logicId, value, mode);
      if (!response.success) {
        setIsLoading(false);
        return;
      }

      const {
        subProcessDetails,
        getStartedDetails,
        getStartedMaster,
        mappingDetails,
      } = response.data;

      setSubProcess(subProcessDetails[0] || "");
      setLogicData(getStartedDetails);
      setAllData(response.data);
      setLogicTotalCount(mappingDetails.length);
      setMappingMatchData(mappingDetails);
      setMappingDetails(mappingDetails);

      if (getStartedMaster) {
        const options = getStartedMaster.map(
          ({ uniqueId, name, isDefault }: any) => ({
            value: uniqueId,
            label: name,
            isDefault: isDefault,
          })
        );
        options.unshift({
          value: "",
          label: "No Get Started",
          isDefault: "No",
        });

        let defaultOption;
        let defaultStartedUniqueId = "";

        if (subProcessDetails && subProcessDetails.length > 0) {
          defaultStartedUniqueId =
            subProcessDetails[0].getStartedUniqueId || "";
        }
        if (value === null) {
          defaultOption =
            options.find(
              (option: any) => option.value === defaultStartedUniqueId
            ) || options[0];
        } else if (value === "") {
          defaultOption = options[0];
        } else {
          defaultOption =
            options.find((option: any) => option.value === value) || options[0];
        }

        setGetStartedDefaultValue(defaultOption);
        setGetStartedOptions(options);

        if (onDropdownChange) {
          if (defaultStartedUniqueId !== value) {
            setMappingDetails([{}]);
          }
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    screenMappingListFetch(null, 0);
  }, []);

  const handleInputChange = (value: any) => {
    const defaultOption = getStartedOptions.find(
      (option: any) => option.value === value
    );
    const currentDefaultValue = getStartedDefaultValue;
    setOnDropdownChange(true);
    if (currentDefaultValue && currentDefaultValue.value === value) {
      return;
    }
    if (defaultOption) {
      resetFormValues();
      screenMappingListFetch(value, 1);
      setGetStartedDefaultValue(defaultOption);
    }
  };

  useEffect(() => {
    if (logicData) {
      const titles = logicData.map((item: any) => {
        return {
          title: item.title,
          type: item.type,
          subType: item.subType,
          optionGroupId: item.optionGroupId,
          uniqueId: item.uniqueId,
          titleKey: item.title.toLowerCase().replace(/\s/g, ""),
        };
      });
      setFieldData(titles);
      const shape = titles.reduce((acc: any, item: any) => {
        const key = item.uniqueId;
        let resSchema;
        typeOfResponse.map((res: any) => {
          if (item.type === res.type) {
            resSchema = schemaType[res.type];
          }
        });
        acc[key] = resSchema;
        return acc;
      }, {});
      setDynamicShape(shape);
    }
  }, [logicData]);

  const getDefaultShapeValues = () => {
    const defaultValues: { [key: string]: any } = {};
    Object.keys(dynamicShape).forEach((key) => {
      if (dynamicShape[key].type === "string") {
        defaultValues[key] = "";
      } else if (dynamicShape[key].type === "object") {
        defaultValues[key] = undefined;
      }
    });

    return defaultValues;
  };

  useEffect(() => {
    if (logicTotalCount !== null && logicTotalCount > 0 && dynamicShape) {
      for (let i = 0; i < logicTotalCount; i++) {
        append({ ...getDefaultShapeValues() });
      }
      setDefaultValue();
    }
  }, [dynamicShape]);

  const handleAddNewMapping = () => {
    append({ ...getDefaultShapeValues() });
  };

  // const handleMakeACopy = (index: any) => {
  //   append({ ...getDefaultShapeValues() });
  // };
  // const handleMakeACopy = (index: any) => {
  //   const itemToCopy = fields[index];
  //   const copiedItem = { ...itemToCopy };
  //   append(copiedItem);
  // };

  const handleRemove = (index: number) => {
    remove(index);
  };
  const ensureDefaultValues = (dataArray: any, defaults: any) => {
    if (!Array.isArray(dataArray)) {
      console.error("Expected an array but received:", dataArray);
      return [];
    }
    return dataArray.map((item) => {
      const newItem = { ...item };
      Object.keys(defaults).forEach((key) => {
        if (!(key in newItem)) {
          newItem[key] = "";
        }
      });
      return newItem;
    });
  };

  const convertUniqueIdToStringValue = (
    input: Record<string, any>
  ): Record<string, string> => {
    const converted: Record<string, string> = {};
    Object.keys(input).forEach((key) => {
      const value = input[key];
      if (typeof value === "object" && value !== null && "value" in value) {
        converted[key] = value.value as string;
      } else {
        converted[key] = value as string;
      }
    });

    return converted;
  };

  const formatFormValue = (updated: any) => {
    return updated.map((item: any) => {
      var { survey, mappingGroupId, ...details } = item;
      const convertedObject = convertUniqueIdToStringValue(details);
      const surveyArray = Array.isArray(survey) ? survey : [survey];
      const surveyId = surveyArray.map(({ uniqueId, name }) => ({
        uniqueId: uniqueId,
        name: name,
      }));
      mappingGroupId = mappingGroupId ? mappingGroupId : generateGuid();
      return {
        mappingGroupId,
        details: convertedObject,
        surveyId,
        isDeleted: 0,
      };
    });
  };

  const findDeleteRecord = (mappingMatchData: any, mappingItems: any) => {
    const originalIds = mappingMatchData.map((obj: any) => obj.mappingGroupId);
    const filteredIds = originalIds.filter(
      (id: any) => !mappingItems.some((item: any) => item.mappingGroupId === id)
    );
    return filteredIds;
  };

  const handleFormSubmit = async (data: any) => {
    setIsLoading(true);
    const { mappingItems } = data;
    const updated = formatFormValue(
      ensureDefaultValues(mappingItems, mappingMatchData[0].details)
    );
    var wipeData = findDeleteRecord(mappingMatchData, mappingItems);
    const response = await screenMappingAction(
      id,
      logicId,
      updated,
      wipeData,
      getStartedDefaultValue
    );
    if (response.success) {
      setTimeout(() => {
        setIsLoading(false);
        navigate(`/ai-logic-studio/process-logic/${logicId}`);
      }, 1000);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const getDropdownOption = (
    answer: any,
    optionGroupId: any,
    masterDetail: any
  ): { value: any; label: any } | undefined => {
    let option: { value: any; uniqueId: any; label: any } | undefined;

    if (masterDetail?.[optionGroupId]) {
      const foundOption = (masterDetail[optionGroupId] as any[]).find(
        (option) => option.uniqueId === answer
      );
      if (foundOption) {
        option = {
          uniqueId: foundOption.uniqueId,
          value: foundOption.uniqueId,
          label: foundOption.value,
        };
      }
    }
    return option;
  };

  const setDefaultValue = async () => {
    if (mappingDetails.length > 0) {
      const surveyDefault = mappingDetails;
      surveyDefault.map((field: any, index: number) => {
        if (
          surveyDefault?.[index]?.surveyId &&
          surveyDefault?.[index]?.surveyId.length > 0
        ) {
          setValue(
            `mappingItems[${index}].survey`,
            surveyDefault?.[index].surveyId
          );
        }
        if (surveyDefault?.[index]?.mappingGroupId) {
          setValue(
            `mappingItems[${index}].mappingGroupId`,
            surveyDefault?.[index]?.mappingGroupId ?? generateGuid()
          );
          const defaultValue = mappingDetails[index]?.details;
          fieldData.map((dataItem: any, j: number) => {
            const fieldAnswer = defaultValue?.[dataItem.uniqueId];
            if (fieldAnswer) {
              let parsedFieldAnswer;
              if (dataItem.optionGroupId !== "0") {
                parsedFieldAnswer = getDropdownOption(
                  fieldAnswer,
                  dataItem.optionGroupId,
                  allData?.masterDetail
                );
              } else {
                parsedFieldAnswer = fieldAnswer;
              }
              setValue(
                `mappingItems[${index}].${dataItem.uniqueId}`,
                parsedFieldAnswer
              );
            }
          });
        }
      });
    }
  };

  return (
    <div className="w-full h-full">
      <form
        className="w-full h-full flex flex-col"
        onSubmit={handleSubmit((data) => handleFormSubmit(data))}
      >
        <PageHeader
          title={subProcess?.name}
          breadcrumbs={breadcrumbs}
          hasHookSaveBtn={true}
          hasEditBtn={false}
          isLoading={isLoading}
        />
        <div className="w-full h-auto flex justify-between items-start pt-4">
          <div className="w-[20%] ">
            <div className="w-full h-auto">
              <div className="mb-[3.8rem]">
                {isLoading ? (
                  <Skeleton width="100%" height={47} borderRadius={3} />
                ) : (
                  <SelectInputWithoutForm
                    labelName={t("web.screenmapping.label.startupscreen")}
                    id={`getStartedUniqueId`}
                    isRequired={true}
                    options={getStartedOptions}
                    defaultValue={getStartedDefaultValue}
                    getOptionLabel={(option: any) => option.label}
                    getOptionValue={(option: any) => option.value}
                    getSelection={(option: any) =>
                      handleInputChange(option.value)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Skeleton width="100%" height={47} borderRadius={3} />
        ) : (
          <Mapping
            fields={fields}
            control={control}
            items={"mappingItems"}
            register={register}
            errors={errors}
            addNewMapping={handleAddNewMapping}
            // handleMakeACopy={handleMakeACopy}
            handleRemove={handleRemove}
            data={allData}
            fieldData={fieldData}
          />
        )}
      </form>
    </div>
  );
}
export default MappingWrapper;
