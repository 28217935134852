import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PageHeader from "../../components/global-layout/page-header";
import DeleteUser from "../../components/section/user-management/delete-user";
import GridColumn from "../../components/section/user-management/grid-columns";
import { UserManagmentPageHeaderIcon } from "../../components/svg";
import UserManagementGrid from "../../components/section/user-management/user-management-grid";
import GridToolBar from "../../components/UI/grid-toolbar";
import UserManagementCards from "../../components/section/user-management/user-management-cards/inde";
import excelExport from "../../services/excelExport";
import ErrorPopup from "../../components/UI/error-popup";
import PageLoader from "../../components/UI/page-loader";
import { useTranslation } from "react-i18next";
import { userDelete, userListFetch } from "../../services/authFunctions";
import Authentication from "../../auth/Authentication";
import { useMyContext } from "../../components/my-context/MyContext";

function UserManagement({ userUpdated, setUserUpdated }: any) {
  const { isExternalUser } = useMyContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [startIndex, setStartIndex] = useState(0);
  const [listData, setListData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSkeletonLoader, setIsSkeletonLoader] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [limit] = useState(20);
  const [initialLoading, setIntialLoading] = useState(true);
  const [finalFetch, setFinalFetch] = useState(false);
  const scrollGroundRef = useRef<any>(null);
  const [sorting, setSorting] = useState<number>(0);
  const [searchedInputVal, setSearchedInputVal] = useState("");
  const [sortingColumnName, setSortingColumnName] = useState<string>("");
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showColumns, setShowColumns] = useState(false);
  const [isGridView, setIsGridView] = useState(false);
  const [toolItems, setToolItems] = useState<any>([]);
  const [searchedQuery, setSearchedQuery] = useState<any>(undefined);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<any>(null);
  const [resetSearch, setResetSearch] = useState<any>(false);
  const [isRefreshed, setIsRefreshed] = useState<any>(false);
  const [noDataFound, setNoDataFound] = useState<boolean>(false);
  const [isFetchMore, setIsFetchMore] = useState<any>(false);
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "firstName",
    "email",
    "mobile",
    "roleName",
    "createdAt",
  ]);
  const columnsData = [
    { label: "Name", token: "web.master.label.name", key: "firstName" },
    {
      label: "Email ID",
      token: "web.section.usermangae.emailid",
      key: "email",
    },
    {
      label: "Phone Number",
      token: "web.section.usermangae.Phone Number",
      key: "mobile",
    },
    { label: "Role", token: "web.label.common.role", key: "roleName" },
    {
      label: "Created Date",
      token: "web.common.label.createddate",
      key: "createdAt",
    },
  ];
  //#region Common function
  const createNewUser = () => {
    navigate("/user-management/user/new");
  };
  const handleEdit = (items: any) => {
    navigate(`/user-management/user/${items.uniqueId}`);
  };
  const handleNavigate = (cardData: any) => {
    navigate(`/user-management/user/${cardData.uniqueId}`);
  };
  const handleRefresh = () => {
    setListData([]);
    setStartIndex(0);
    setSortingColumnName("");
    setSorting(0);
    setIsRefreshed((pre: any) => !pre);
    setPageLoader(false);
    setSearchedQuery("");
    setSearchedInputVal("");
    setResetSearch(true);
  };
  const handleDelete = async (items: any) => {
    setDeleteData(items);
    setShowDeletePopup(true);
  };
  const authService = Authentication();
  const handleUserDelete = async (items: any) => {
    setShowDeletePopup(false);
    setPageLoader(true);
    const userUniqueId = sessionStorage.getItem("userId");
    try {
      const result = await userDelete(items);
      if (result.success) {
        if (items.uniqueId === userUniqueId) {
          authService.logout();
        } else {
          setTimeout(() => {
            setListData([]);
            setStartIndex(0);
            setIsDeleted((prev) => !prev);
            setPageLoader(false);
          }, 2000);
        }
      }
    } catch (error) {
      setPageLoader(false);
      console.error("Error fetching data:", error);
    }
  };
  const handleCheckboxChange = (event: any, key: string) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedColumns((prevSelected) => [...prevSelected, key]);
    } else {
      if (selectedColumns.length === 1) return;
      setSelectedColumns((prevSelected) =>
        prevSelected.filter((item) => item !== key)
      );
    }
  };
  //#endregion
  //#region Use Effect
  useEffect(() => {
    const baseTools = ["search", "export", "refresh"];
    const gridTools = isGridView ? baseTools : [...baseTools, "columns"];
    const tools = isExternalUser ? gridTools : ["create", ...gridTools];
    setToolItems(tools);
  }, [isGridView]);

  useEffect(() => {
    if (searchedQuery !== undefined) {
      setListData([]);
      setStartIndex(0);
      setSearchedInputVal(searchedQuery);
    }
  }, [searchedQuery]);
  useEffect(() => {
    if (userUpdated === true) {
      setListData([]);
      setStartIndex(0);
      setIsUpdated((pre: any) => !pre);
    }
  }, [userUpdated]);
  useEffect(() => {
    listFetch(searchedInputVal);
    setUserUpdated(undefined);
    setResetSearch(false);
  }, [
    isFetchMore,
    sortingColumnName,
    sorting,
    searchedInputVal,
    isDeleted,
    isUpdated,
    isRefreshed,
  ]);

  //#endregion
  //#endregion
  //#region list Fetch
  const listFetch = async (query: any) => {
    setIsLoading(true);
    setIsSkeletonLoader(true);
    setFinalFetch(false);
    setNoDataFound(false);
    const result = await userListFetch(
      query,
      startIndex,
      limit,
      sorting,
      sortingColumnName
    );
    if (result?.success) {
      const responseData = result.data?.listDetails;
      setListData((prevData: any) => [...prevData, ...responseData]);
      setTotalCount(result.data?.count);
      setIsLoading(false);
      setIsSkeletonLoader(false);
      if (responseData.length === 0) {
        setNoDataFound(true);
      } else {
        setNoDataFound(false);
      }
      if (startIndex < limit) {
        setIntialLoading(false);
      }
    }
  };
  //#endregion
  //#region Scroll
  const loadMore = (limit: any) => {
    if (!finalFetch) {
      setStartIndex((prevIndex) => prevIndex + limit);
      setIsFetchMore((pre: any) => !pre);
    }
  };

  const handleScroll = () => {
    const chatGround = scrollGroundRef.current;
    if (
      chatGround.scrollTop + 1 + chatGround.clientHeight >=
      chatGround.scrollHeight
    ) {
      if (startIndex + limit < totalCount) {
        loadMore(limit);
      } else {
        setFinalFetch(true);
      }
    }
  };
  //#endregion
  //#region Sorting
  const handleSort = (column: any, value: number, columnName: string) => {
    setListData([]);
    setStartIndex(0);
    setSorting(value);
    setSortingColumnName(columnName);
  };
  //#endregion
  //#region  Excel Export
  const [isExcelLoading, setExcelLoading] = useState(false);
  const handleExcel = async () => {
    setExcelLoading(true);
    const screenName = "user";
    const fileName = "User List";
    const sheetName = "User_List";
    try {
      await excelExport.excelExport(
        screenName,
        fileName,
        sheetName,
        columnsData
      );
      setExcelLoading(false);
    } catch (error) {
      console.error("Export failed:", error);
      setExcelLoading(false);
    }
  };

  //#endregion
  //#region  Render Page
  // const checkLocation = () => {
  //   if (!pathname.includes("user-management/user")) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  return (
    <>
      {/* {checkLocation() ? ( */}
      <div className="w-full h-full flex flex-col">
        <PageHeader
          title={t("web.title.label.user")}
          icon={<UserManagmentPageHeaderIcon />}
        />
        <div className="w-full flex-1">
          <div className="w-full h-full flex flex-col">
            <GridToolBar
              columnCount={totalCount}
              gridType={t("web.toolbar.label.user")}
              selectView={true}
              tools={toolItems}
              handleAddRow={createNewUser}
              handleDelete={() => setShowDeletePopup(true)}
              handleColumns={() => setShowColumns(true)}
              isGridView={isGridView}
              fetchData={(query: any) => setSearchedQuery(query)}
              getSearchedQuery={(query: any) => setSearchedQuery(query)}
              handleRefresh={handleRefresh}
              handleExcel={handleExcel}
              isExcelLoading={isExcelLoading}
              resetSearch={resetSearch}
              isSkeletonLoader={isSkeletonLoader}
              setIsGridView={(isChecked:any) => setIsGridView(isChecked)}
            />
            <div className="w-full flex-1">
              {isGridView ? (
                <UserManagementCards
                  handleNavigate={handleNavigate}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  rowData={listData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  noDataFound={noDataFound}
                />
              ) : (
                <UserManagementGrid
                  selectedColumns={selectedColumns}
                  rowSelection={false}
                  moreOptions={true}
                  handleNavigate={handleNavigate}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  rowData={listData}
                  scrollGroundRef={scrollGroundRef}
                  handleScroll={handleScroll}
                  initialLoading={initialLoading}
                  isLoading={isLoading}
                  limit={limit}
                  finalFetch={finalFetch}
                  sorting={sorting}
                  sortingColumnName={sortingColumnName}
                  handleSort={handleSort}
                  handleCheckboxChange={handleCheckboxChange}
                  noDataFound={noDataFound}
                />
              )}
            </div>
          </div>
        </div>
        {/* <CreateFloatingButton
          icon={<CreateNewUserButtonIcon />}
          handleClick={createNewUser}
        /> */}
      </div>
      {/* ) : null} */}
      {showDeletePopup && (
        <DeleteUser
          handleCancel={() => setShowDeletePopup(false)}
          rowData={deleteData}
          handleDelete={handleUserDelete}
          actionFlag="user"
        />
      )}
      {showColumns && (
        <GridColumn
          handleClose={() => setShowColumns(false)}
          handleCheckboxChange={handleCheckboxChange}
          columnsData={columnsData}
          selectedColumns={selectedColumns}
        />
      )}{" "}
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
      <Outlet />
    </>
  );
  //#endregion
}
export default UserManagement;
