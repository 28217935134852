import { ReactNode, useState } from "react";
import ModalPageHeader from "../../../global-layout/modal-page-header";
import ModalPageWrapper from "../../../global-layout/modal-page-wrapper";
import ResponseSettingsForm from "../response-settings-form";
import ResponseValueForm from "../response-value-form";
import { useTranslation } from "react-i18next";
import ResponseSettingsFormNew from "../response-settings-form-new";
interface ResponseSettingsProps {
  selectedScreen: any;
  handleClose: () => void;
  responseSettingsType: any;
  handleImport: (file: File) => void;
  handleReponseSettingsSubmit: (data: any, type: any) => void;
  selectedResponseValue: (data: any) => void;
  responseValueOptions: any;
  selectedResponseItem: any;
  selectedChoice: any;
  responseMultipleChoiceOptions: any;
  responseDateTimeOptions: any;
  responseScannerOptions: any;
  responseImageOptions: any;
  responseTextOptions: any;
  responseMagicContainerOptions: any;
  handleSaveResponse: (data: any) => void;
  passUpdatedResponseval: (data: any) => void;
  bindingoptions: (data: any) => void;
  watchQuestionName: any;
}

interface Item {
  id: string;
  order: number;
  label: string;
  icon: ReactNode;
}
function ResponseSettingsNew({
  selectedScreen,
  handleClose,
  responseSettingsType,
  handleImport,
  handleReponseSettingsSubmit,
  selectedResponseValue,
  responseValueOptions,
  selectedResponseItem,
  selectedChoice,
  responseMultipleChoiceOptions,
  responseDateTimeOptions,
  responseScannerOptions,
  responseImageOptions,
  responseTextOptions,
  responseMagicContainerOptions,
  handleSaveResponse,
  passUpdatedResponseval,
  bindingoptions,
  watchQuestionName,
  multipleOptionValidation,
}: any) {
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<string>("settings");

  return (
    <ModalPageWrapper>
      <div className="w-[41%] h-full bg-io-white dark:bg-io-black-15 ml-auto max-md:w-[85%]">
        <div className="w-full h-full">
          <div className="w-full h-full flex flex-col">
            <ModalPageHeader
              title={t("web.common.responsesettings")}
              handleClose={handleClose}
            />
            <div className="w-full h-full flex-auto">
              <div className="w-full h-full pl-[4.7rem] pt-[3.7rem] pr-4">
                <ResponseSettingsFormNew
                  selectedScreen={selectedScreen}
                  responseSettingsType={responseSettingsType}
                  handleReponseSettingsSubmit={handleReponseSettingsSubmit}
                  handleFormCancel={handleClose}
                  selectedResponseItem={selectedResponseItem}
                  selectedChoice={selectedChoice}
                  responseMultipleChoiceOptions={responseMultipleChoiceOptions}
                  responseDateTimeOptions={responseDateTimeOptions}
                  responseScannerOptions={responseScannerOptions}
                  responseImageOptions={responseImageOptions}
                  responseTextOptions={responseTextOptions}
                  responseMagicContainerOptions={responseMagicContainerOptions}
                  handleSaveResponse={(data: any) => handleSaveResponse(data)}
                  bindingoptions={bindingoptions}
                  handleImport={handleImport}
                  selectedResponseValue={selectedResponseValue}
                  responseValueOptions={responseValueOptions}
                  passUpdatedResponseval={passUpdatedResponseval}
                  watchQuestionName={watchQuestionName}
                  multipleOptionValidation={multipleOptionValidation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalPageWrapper>
  );
}
export default ResponseSettingsNew;
