import { useEffect, useState } from "react";
import LoginFooter from "../components/section/login-footer";
import LoginSlider from "../components/section/login-slider";
import DomainSteps from "../components/section/domain-steps";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorPopup from "../components/UI/error-popup";
import PageLoader from "../components/UI/page-loader";
function Login() {
  const [isServerError, setIsServerError] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);

  useEffect(() => {
    setPageLoader(pageLoader);
  }, [pageLoader]);
  return (
    <>
      <div className="w-full h-screen">
        <div className="w-full h-full login-bg">
          <div className="w-full h-full flex justify-between max-md:flex-col">
            <div className="w-1/2 h-full max-md:w-full login-pattern-bg max-md:h-auto">
              <div className="w-full h-full p-[5rem] flex flex-col max-md:p-[4rem]">
                <div className="w-[19.3rem] h-auto max-md:w-[17rem]">
                  <img
                    src={require("../assets/images/inspection-logo.png")}
                    alt="Inspection Logo"
                  />
                </div>
                <LoginSlider />
              </div>
            </div>
            <div className="w-1/2 h-full max-md:w-full">
              <div className="w-full h-full bg-io-white rounded-l-[5rem] max-md:rounded-b-none max-md:rounded-tr-[5rem]">
                <div className="w-full h-full py-[3.5rem] pl-[8.8rem] pr-[4.5rem] max-md:p-[5rem]">
                  <div className="w-full h-full flex flex-col">
                    <DomainSteps setPageLoader={setPageLoader} />
                    <LoginFooter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {isServerError && (
        <ErrorPopup handleClose={() => setIsServerError(false)} />
      )}
      {pageLoader && <PageLoader />}
      {/* <NoticeBar title="Connection to the server was lost and the client is trying to re-establish it.  Please wait or contact the administrator." /> */}
    </>
  );
}
export default Login;
