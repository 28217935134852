import {
  containerServices,
  domainServices,
  logicServices,
  loginServices,
  magicServices,
  processServices,
  samModelServices,
  sidebarServices,
  subProcessMapping,
  userServices,
  siteSwitchServices,
} from "./InspectionOneServices";
import { ToastError, ToastSuccess } from "../components/UI/toast";
import ToastifyMessage from "../lib/toastify";
import { v4 as uuidv4 } from "uuid";

const { ToastifyHandle } = ToastifyMessage();
const generateGuid = () => {
  return uuidv4();
};

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
const baseWrapperUrl = process.env.REACT_APP_WRAPPER_URL;

const data = {
  "api.web.loginValidate": `${baseApiUrl}/LoginValidation`,
  "api.web.loginAzure": `${baseApiUrl}/LoginValidationAzure`,
  "api.web.userLists": `${baseApiUrl}/UserListFetch`,
  "api.web.usersDetails": `${baseApiUrl}/EditUserListFetch`,
  "api.web.userCreation": `${baseApiUrl}/UserCreation`,
  "api.web.userUpdate": `${baseApiUrl}/UserEdit`,
  "api.web.userDelete": `${baseApiUrl}/UserDelete`,
  "api.web.blobstorage": `${baseApiUrl}/BlobStorage`,
  "api.web.magicList": `${baseApiUrl}/MagicLists`,
  "api.web.magicAction": `${baseApiUrl}/MagicAction`,
  "api.web.magicDetails": `${baseApiUrl}/MagicDetails`,
  "api.web.magicAllList": `${baseApiUrl}/MagicAllList`,
  "api.web.containerLists": `${baseApiUrl}/ContainerLists`,
  "api.web.containerAction": `${baseApiUrl}/ContainerAction`,
  "api.web.containerDetails": `${baseApiUrl}/ContainerDetails`,
  "api.web.sidebarDetails": `${baseApiUrl}/SidebarDetails`,
  "api.web.logicList": `${baseApiUrl}/LogicList`,
  "api.web.logicAction": `${baseApiUrl}/LogicAction`,
  "api.web.logicDetail": `${baseApiUrl}/LogicDetail`,
  "api.web.questionAction": `${baseApiUrl}/QuestionnaireAction`,
  "api.web.questionOption": `${baseApiUrl}/QuestionnaireOptions`,
  "api.web.processLists": `${baseApiUrl}/ProcessLists`,
  "api.web.processStatusAction": `${baseApiUrl}/ProcessStatusAction`,
  "api.web.processAuthTokenAction": `${baseApiUrl}/ProcessAuthTokenAction`,
  "api.web.screenCollectionList": `${baseApiUrl}/ScreenCollectionList`,
  "api.web.screenCollectionAction": `${baseApiUrl}/ScreenCollectionAction`,
  "api.web.screenCollectionDetail": `${baseApiUrl}/ScreenCollectionDetail`,
  "api.web.startupScreenList": `${baseApiUrl}/StartupScreenList`,
  "api.web.startupScreenAction": `${baseApiUrl}/StartupScreenAction`,
  "api.web.startupScreenDetail": `${baseApiUrl}/StartupScreenDetail`,
  "api.web.screenMappingList": `${baseApiUrl}/ScreenMappingList`,
  "api.web.screenMappingAction": `${baseApiUrl}/ScreenMappingAction`,
  "api.web.screenMappingDetail": `${baseApiUrl}/ScreenMappingDetail`,
  "api.web.questionnaireOption": `${baseApiUrl}/QuestionnaireOptions`,
  "api.web.questionnaireOptionAction": `${baseApiUrl}/QuestionnaireOptionAction`,
  "api.web.startupScreenLists": `${baseApiUrl}/StartupScreenLists`,
  "api.web.questionnaireDetails": `${baseApiUrl}/QuestionnaireDetails`,
  "api.web.bindingDetails": `${baseApiUrl}/BindingsFetch`,
  "api.web.containerMasterFetch": `${baseApiUrl}/ContainerMasterFetch`,
  "api.web.logicMappingAction": `${baseApiUrl}/LogicMappingAction`,
  "api.web.logicMappingDetails": `${baseApiUrl}/LogicMappingDetails`,
  "api.web.masterAccessDetailsFetch": `${baseApiUrl}/MasterAccessDetailsFetch`,
  "api.web.masterAccessActionFetch": `${baseApiUrl}/MasterAccessActionFetch`,
  "api.web.masterAccessAction": `${baseApiUrl}/MasterAccessAction`,
  "api.web.masterColumnDetailsFetch": `${baseApiUrl}/MasterColumnDetailsFetch`,
  "api.web.masterColumnDetailsAction": `${baseApiUrl}/MasterColumnDetailsAction`,
  "api.web.masterColumnDetailsActionFetch": `${baseApiUrl}/MasterColumnDetailsActionFetch`,
  "api.web.masterBindingsFetch": `${baseApiUrl}/BindingsFetch`,
  "api.web.masterDetailsFetch": `${baseApiUrl}/MasterDetailsFetch`,
  "api.web.masterDetailsActionFetch": `${baseApiUrl}/MasterDetailsActionFetch`,
  "api.web.changePassword": `${baseApiUrl}/ChangePassword`,
  "api.web.masterDetailsAction": `${baseApiUrl}/MasterDetailsAction`,
  "api.web.masterDetailsExcelUpload": `${baseApiUrl}/MasterDetailsExcelUpload`,
  "api.web.samModelServices": `${baseWrapperUrl}/SamModelSegmentation`,
  "api.web.userSiteSwitch": `${baseApiUrl}/UserSiteSwitchWeb`,
};
///SamModelSegmentation
//#region Site Switch

const siteSwitch = async (siteId: any) => {
  try {
    const requestJson = {
      siteId: siteId,
    };
    const response: any = await siteSwitchServices.UserSiteSwitch(requestJson);
    const responseData = response.data;
    if (response.success) {
      return { success: true, data: data, response: responseData };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

//#endregion

//#region Login

const domainSubmit = async (domainValue: any, message: any) => {
  try {
    const requestJson = {
      tenantDomain: domainValue.trim(),
    };
    const response: any = await domainServices.DomainDetails(requestJson);
    const responseData = response.data;
    if (response.success) {
      sessionStorage.setItem("tenantId", responseData.tenantId);
      if (message) {
        handleSuccess(
          "web.domain.label.domain",
          "web.toaster.desc.domainsuccess"
        );
      }
      return { success: true, data: data, response: responseData };
    } else {
      if (message) {
        handleError("web.domain.label.domain", response.message);
      }
      // handleError(t("web.domain.label.domain"), response.message);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const loginSubmit = async (email: any, password: any) => {
  try {
    const requestJson = {
      emailId: email,
      password: password,
    };
    const response: any = await loginServices.LoginDetails(requestJson);
    const responseData = response.data;
    if (response.success) {
      sessionStorage.setItem("siteId", responseData.siteId);
      sessionStorage.setItem("userId", responseData.siteUserId);
      sessionStorage.setItem("tenantId", responseData.tenantId);
      sessionStorage.setItem(
        "passwordRemainingDays",
        responseData.passwordRemainingDays
      );
      var freshlogin = responseData.freshLogin ?? 0;
      var isPasswordExpired = responseData.isPasswordExpired ?? 0;
      var userSiteDetails = responseData.userSiteDetails;
      var loginAttempts = false;
      if (responseData.freshLogin === undefined) {
        loginAttempts = true;
        handleError("web.login.button.login", "web.toaster.desc.loginerror");
      } else {
        var result = await sidebarDetails(
          responseData.otherDetails.roleId,
          responseData.siteId
        );

        handleSuccess(
          "web.login.button.login",
          "web.toaster.desc.loginsuccess"
        );
      }

      return {
        success: true,
        data: {
          freshlogin: freshlogin,
          isPasswordExpired: isPasswordExpired,
          activeMenu: result?.activeMenu,
          sideMenu: result?.sideMenu,
          loginDetails: responseData,
          loginAttempts: loginAttempts,
          userSiteDetails: userSiteDetails,
        },
      };
    } else {
      handleError("web.login.button.login", response.message);
      let isPasswordExpired = 0;
      if (response.message === "Password has been expired") {
        isPasswordExpired = 1;
      }
      // setTimeout(() => {
      //   sessionStorage.clear();
      // }, 1000);
      return { success: false, isPasswordExpired: isPasswordExpired };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const azureLogin = async (email: any) => {
  try {
    const requestJson = {
      emailId: email,
    };
    const response: any = await loginServices.AzureDetails(requestJson);
    const responseData = response.data;
    if (response.success) {
      sessionStorage.setItem("siteId", responseData.siteId);
      sessionStorage.setItem("userId", responseData.siteUserId);
      sessionStorage.setItem("tenantId", responseData.tenantId);
      var freshlogin = responseData.freshLogin ?? 0;
      var isPasswordExpired = responseData.isPasswordExpired ?? 0;
      var loginAttempts = false;
      if (responseData.freshLogin === undefined) {
        loginAttempts = true;
        handleError("web.login.button.login", "web.toaster.desc.loginerror");
      } else {
        var result = await sidebarDetails(
          responseData.otherDetails.roleId,
          responseData.siteId
        );
        handleSuccess(
          "web.login.button.login",
          "web.toaster.desc.loginsuccess"
        );
      }
      return {
        success: true,
        data: {
          freshlogin: freshlogin,
          isPasswordExpired: isPasswordExpired,
          activeMenu: result?.activeMenu,
          sideMenu: result?.sideMenu,
          loginDetails: responseData,
          loginAttempts: loginAttempts,
        },
      };
    } else {
      handleError("web.login.button.login", response.message);
      let isPasswordExpired = 0;
      if (response.message === "Password has been expired") {
        isPasswordExpired = 1;
      }
      return { success: false, isPasswordExpired: isPasswordExpired };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
//#endregion

//#region user List
const userListFetch = async (
  query: any,
  startIndex: any,
  limit: any,
  sorting: any,
  column: any
) => {
  try {
    const requestJson = {
      searchString: query,
      startIndex: startIndex,
      limit: limit,
      sorting: sorting,
      columnName: column,
    };
    const response: any = await userServices.UserLists(requestJson);
    if (response.success) {
      const responseData = response.data.userListDetails;
      const userCount = response.data.totalCount;
      return {
        success: true,
        data: {
          count: userCount,
          listDetails: responseData,
        },
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const userDelete = async (items: any) => {
  try {
    const requestJson = {
      uniqueId: items.uniqueId.trim(),
    };
    const response = await userServices.DeleteUser(requestJson);
    if (response.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const userCreate = async (formData: any, mode: any, id: any, type: any) => {
  try {
    const requestJson = {
      uniqueId: mode === "insert" ? generateGuid() : id,
      ...formData,
      password: "",
      authorization_scheme: formData.roleId,
      isAzureAd: formData.isAzureAd,
      isLoggedOnce: 0,
      mobile: formData.mobile,
      countryCode: "+91",
      address: "",
      languageId: formData.languageId || 2,
      profileImageUrl: formData.imageUrl,
    };
    const response =
      mode === "insert"
        ? await userServices.UserCreation(requestJson)
        : await userServices.UserUpdate(requestJson);
    if (response.success) {
      if (mode === "insert") {
        handleSuccess("web.title.label.user", "web.toaster.desc.usersuccess");
      } else {
        if (type === "Profile") {
          handleSuccess(
            "web.title.label.profile",
            "web.toaster.desc.profilesuccess"
          );
        } else if (type === "Language") {
          handleSuccess(
            "web.title.label.language",
            "web.toaster.desc.languagesuccess"
          );
        } else {
          handleSuccess(
            "web.title.label.user",
            "web.toaster.desc.usereditsuccess"
          );
        }
      }
      return { success: true };
    } else {
      handleError("web.title.label.user", response.message);
      setTimeout(() => {}, 1000);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const userEdit = async (userId: any) => {
  try {
    const requestJson = {
      uniqueId: userId.trim(),
    };
    const response = await userServices.UserDetails(requestJson);
    if (response.success) {
      return { success: true, data: response.data };
    } else {
      handleError("web.title.label.user", response.message);
      setTimeout(() => {}, 1000);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
//#endregion

//#region Magic
const magicListFetch = async (
  query: any,
  type: any,
  startIndex: any,
  limit: any,
  sorting: number,
  sortingColumnName: string
) => {
  try {
    const requestJson = {
      magicType: type ?? 1,
      magicPillar: 1,
      filterDetails: {
        searchValue: query,
        startIndex: startIndex,
        limit: limit,
        startDate: "",
        endDate: "",
        sorting: sorting,
        columnName: sortingColumnName,
      },
    };
    const response = await magicServices.MagicLists(requestJson);
    if (response.success) {
      const magicDetails = response.data.listDetails;
      const magicTypeCounts = response.data.magicTypeCounts;
      return {
        success: true,
        data: {
          count: magicTypeCounts,
          listDetails: magicDetails,
        },
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      success: false,
    };
  }
};
const magicDelete = async (items: any) => {
  try {
    const requestJson = {
      isDeleted: 1,
      type: 0,
      pillar: 1,
      definition: 2,
      magicId: 0,
      inputType: 0,
      uniqueId: items.uniqueId,
      inputSubType: 0,
      name: items.name,
      description: items.description,
      tags: [],
      overlay: "",
      thumbnail: "",
      labels: [],
    };
    const response = await magicServices.MagicAction(requestJson);
    if (response.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const magicCreate = async (data: any) => {
  try {
    const requestJson = data;
    const response = await magicServices.MagicAction(requestJson);
    if (response.success) {
      handleSuccess("web.toaster.title.magicstudio", response.data);
      return { success: true };
    } else {
      console.error("Error fetching data:");
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const magicEdit = async (magicUnique: any) => {
  try {
    const requestJson = {
      uniqueId: magicUnique,
    };
    const response = await magicServices.MagicDetails(requestJson);
    if (response.success) {
      return { success: true, data: response.data.magicDetails };
    } else {
      handleError("web.toaster.title.magic", response.message);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
//#endregion

//#region Container
const containerDelete = async (items: any) => {
  try {
    const requestJson = {
      isDeleted: 1,
      name: items.name,
      containerId: items.containerId,
      uniqueId: items.uniqueId,
      description: items.description,
      containerDetails: {},
    };
    const response = await containerServices.ContainerAction(requestJson);
    if (response.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const containerList = async (
  query: any,
  startIndex: any,
  limit: any,
  sorting: any,
  sortingColumnName: any
) => {
  try {
    const requestJson = {
      filterDetails: {
        searchValue: query ?? "",
        startIndex: startIndex,
        limit: limit,
        startDate: "",
        endDate: "",
        sorting: sorting,
        columnName: sortingColumnName,
      },
    };
    const response: any = await containerServices.ContainerLists(requestJson);
    if (response.success) {
      return {
        success: true,
        data: {
          count: response.data.totalCount,
          listDetails: response.data.listDetails,
        },
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const containerCreate = async (containerData: any, mode: any, id: any) => {
  try {
    const requestJson = {
      isDeleted: 0,
      uniqueId: mode === "insert" ? generateGuid() : id,
      Name: containerData.name,
      containerId: mode === "insert" ? 0 : containerData.containerId,
      Description: containerData.description,
      magicDetails: [],
    };
    const response = await containerServices.ContainerAction(requestJson);
    if (response.success) {
      handleSuccess("web.logic.label.magixcontainer", response.data);
      return { success: true, data: response.data };
    } else {
      handleError("web.logic.label.magixcontainer", response.data);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const containerEdit = async (id: any) => {
  try {
    const requestJson = {
      uniqueId: id.trim(),
    };
    const response = await containerServices.ContainerDetailFetch(requestJson);
    if (response.success) {
      return { success: true, data: response.data };
    } else {
      handleError("web.title.label.user", response.message);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

const containerMapping = async (containerData: any) => {
  try {
    const response = await containerServices.ContainerAction(containerData);
    if (response.success) {
      handleSuccess(
        "web.logic.label.magixflowmappingsuccessfully",
        response.data
      );
      return { success: true, data: response.data };
    } else {
      handleError(
        "web.logic.label.magixflowmappingsuccessfully",
        response.data
      );
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

//#endregion

//#region Logic
const logicList = async (
  query: any,
  startIndex: any,
  limit: any,
  sorting: any,
  sortingColumnName: any
) => {
  try {
    const requestJson = {
      filterDetails: {
        searchValue: query,
        startIndex: startIndex,
        limit: limit,
        startDate: "",
        endDate: "",
        sorting: sorting,
        columnName: sortingColumnName,
      },
    };
    const response: any = await logicServices.LogicList(requestJson);
    if (response.success) {
      const responseData = response.data.listDetails;
      const totalCount = response.data.totalCount;
      return {
        success: true,
        data: { listDetails: responseData, count: totalCount },
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const logicDelete = async (items: any) => {
  try {
    const requestJson = {
      uniqueId: items.uniqueId.trim(),
      isDeleted: 1,
      name: "",
      description: "",
      icon: "",
      logicId: 0,
    };
    const response = await logicServices.LogicAction(requestJson);
    if (response.success) {
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

const logicCreate = async (formData: any, mode: any, id: any) => {
  try {
    const requestJson = {
      ...formData,
      uniqueId: mode === "update" ? id : generateGuid(),
      ProcessLogicUniqueId: "",
      icon: formData.imageUrl,
    };
    const response = await logicServices.LogicAction(requestJson);
    if (response.success) {
      handleSuccess("web.toolbar.label.process", response.data);
      return { success: true, data: response.data };
    } else {
      handleError("web.toolbar.label.process", response.data);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const logicEdit = async (uniqueIdId: any) => {
  try {
    const requestJson = { uniqueId: uniqueIdId };
    const response = await logicServices.LogicDetail(requestJson);
    if (response.success) {
      return {
        success: true,
        data: response.data[0],
        imageUrl: response.data[0].icon,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

//#endregion

const screenMappingList = async (
  uniqueId: any,
  logicId: any,
  getStartedUniqueId: any,
  mode: any
) => {
  try {
    const requestJson = {
      uniqueId: uniqueId,
      logicUniqueId: logicId,
      getStartedUniqueId: getStartedUniqueId,
      mode: mode,
    };
    const response: any = await subProcessMapping.LogicMappingDetails(
      requestJson
    );
    if (response.success) {
      return {
        success: true,
        data: response.data,
      };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const screenMappingAction = async (
  subProcessUniqueId: any,
  logicId: any,
  data: any,
  wipeData: any,
  getStartedDefaultValue: any
) => {
  try {
    const final = {
      subProcessUniqueId: subProcessUniqueId,
      processLogicUniqueId: logicId,
      mappingDetails: data,
      wipeData: wipeData,
      GetStartedId: getStartedDefaultValue.value,
    };
    const response = await subProcessMapping.LogicMappingAction(final);
    if (response.success) {
      handleSuccess("web.mapping.title.name", "web.toaster.dec.mappingsuccess");
      return {
        success: true,
      };
    } else {
      handleError("web.mapping.title.name", response.message);
      return {
        success: false,
      };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return {
      success: false,
    };
  }
};
const changePassword = async (currentPassword: any, newPassword: any) => {
  try {
    const requestJson = {
      passwordDetails: {
        currentPassword: currentPassword,
        newPassword: newPassword,
      },
    };
    const response = await userServices.ChangePassword(requestJson);
    if (response.success) {
      handleSuccess(
        "web.toaster.title.password",
        "web.toaster.desc.passwordsucccess"
      );
      return { success: true };
    } else {
      handleError("web.toaster.title.password", response.message);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const containerInputCreate = async (newData: any) => {
  try {
    const response = await containerServices.ContainerAction(newData);
    if (response.success) {
      handleSuccess("web.toaster.title.container", response.data);
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
const statusAction = async (id: any, statusId: any, statusText: any) => {
  try {
    const requestData = {
      uniqueId: id,
      statusId: statusId,
      statusText: statusText,
    };
    const response = await processServices.ProcessStatusAction(requestData);
    if (response.success) {
      handleSuccess(
        "web.toolbar.label.process",
        "web.toaster.desc.processsuccess"
      );
      return { success: true };
    } else {
      console.error("Error fetching data:");
      handleError("web.toolbar.label.process", response.message);
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};

//#region Common
const sidebarDetails = async (roleId: any, siteUniqueId: any) => {
  try {
    const requestJson = {
      roleId: roleId,
      siteId: siteUniqueId,
    };
    const response = await sidebarServices.sidebarDetails(requestJson);
    if (response.success) {
      var sideMenu = response.data;
      const activeMenu = sideMenu.find(
        (item: any) => item.routerLinkActive === "Active"
      );
      return { activeMenu, sideMenu };
    }
  } catch (error) {
    console.error("Error fetching sidebar details:", error);
    throw error;
  }
};

const handleSuccess = (titleName: any, message: any) => {
  ToastifyHandle(
    <ToastSuccess title={titleName} description={message} />,
    "success"
  );
};
const handleError = (titleName: any, message: any) => {
  ToastifyHandle(
    <ToastError title={titleName} description={message} />,
    "error"
  );
};
// const handleWarning = (titleName: any, message: any) => {
//   ToastifyHandle(
//     <ToastWarning title={titleName} description={message} />,
//     "warning"
//   );
// };
//#endregion

//#region DataScience
const samModelDetails = async (formdatavalue: any) => {
  try {
    const response: any = await samModelServices.samModelDetails(formdatavalue);
    const responseData = response.data;
    if (response.success) {
      return { success: true, data: data, response: responseData };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { success: false };
  }
};
export {
  siteSwitch,
  domainSubmit,
  loginSubmit,
  userListFetch,
  userCreate,
  userEdit,
  userDelete,
  magicListFetch,
  magicDelete,
  magicCreate,
  magicEdit,
  containerDelete,
  containerList,
  containerCreate,
  containerEdit,
  containerMapping,
  logicList,
  logicDelete,
  changePassword,
  logicCreate,
  logicEdit,
  containerInputCreate,
  statusAction,
  azureLogin,
  screenMappingList,
  screenMappingAction,
  sidebarDetails,
  samModelDetails,
};
