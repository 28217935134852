import { useState } from "react";
import { EditIcon } from "../../svg";
import { TextBtn } from "../../form/text-btn";
import IconBtn from "../../form/icon-btn";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { useMyContext } from "../../my-context/MyContext";

function PageHeader({
  title,
  icon,
  breadcrumbs,
  hasSaveBtn,
  hasEditBtn,
  handleEdit,
  isLoading,
  handleSaveResponse,
  data,
  hasHookSaveBtn,
  hasToggleButton,
  getToggleIsChecked,
  currentTheme,
  isSkeletonLoader = false,
}: any) {
  const saveResponse = (data: any) => {
    handleSaveResponse(data);
  };
  const { t } = useTranslation();
  const { isExternalUser } = useMyContext();
  const [isChecked, setIsChecked] = useState(currentTheme || false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
    getToggleIsChecked(isChecked);
  };
  return (
    <div className="w-full h-auto mb-[2rem]">
      <div className="w-full h-auto flex justify-between items-center pb-5 border-b border-io-gray-c8 dark:border-io-black-48">
        <div className="w-1/2 h-auto max-md:w-[80%]">
          {breadcrumbs && breadcrumbs.length > 0 && (
            <div className="w-full">
              {breadcrumbs.map((breadcrumb: any, index: any) => (
                <span
                  key={index}
                  className="text-[1.2rem] text-io-primary relative mr-8 after:content-['>'] after:text-[1.2rem] after:absolute after:top-[50%] after:translate-y-[-50%] after:right-[-15%] after:text-io-primary last:text-io-gray-66 dark:last:text-io-black-d1 last:after:hidden"
                >
                  {breadcrumb.link ? (
                    <NavLink key={index} to={breadcrumb.link}>
                      {breadcrumb.label}
                    </NavLink>
                  ) : (
                    breadcrumb.label
                  )}
                </span>
              ))}
            </div>
          )}
          <div className="w-auto flex justify-start items-center gap-6">
            {icon && <div className="w-auto h-16">{icon}</div>}
            {isSkeletonLoader ? (
              <Skeleton width={300} height={40} borderRadius={5} />
            ) : (
              <h1 className="text-[2.8rem] text-io-black font-inter font-normal dark:text-io-white">
                {title}
              </h1>
            )}

            {hasEditBtn && (
              <div className="w-auto h-auto">
                <IconBtn
                  size="w-[2rem]"
                  icon={<EditIcon />}
                  handleClick={() => handleEdit?.()}
                />
              </div>
            )}
          </div>
        </div>
        {hasToggleButton && (
          <div className="w-[30rem] flex justify-center items-center gap-3 ml-8">
            <p
              className={`text-[1.8rem] font-inter font-normal text-io-gray-47 dark:text-io-white ${
                !isChecked ? "text-opacity-50" : "text-opacity-100"
              }`}
            >
              Theme 1
            </p>
            <label
              htmlFor="togglebtn"
              className="flex items-center cursor-pointer"
            >
              <input
                type="checkbox"
                id="togglebtn"
                className="sr-only"
                checked={isChecked}
                onChange={handleToggle}
              />
              <div
                className={`w-[5.4rem] h-[2.8rem] rounded-full relative ${
                  isChecked ? "bg-io-gray-9d" : "gradient-bg"
                }`}
              >
                <div
                  className={`w-[2.2rem] h-[2.2rem] bg-white rounded-full shadow-md absolute top-[50%] translate-y-[-50%] transform transition-transform duration-300 ${
                    isChecked ? "translate-x-[20%]" : "translate-x-[125%]"
                  }`}
                ></div>
              </div>
            </label>
            <p
              className={`text-[1.8rem] font-inter font-normal text-io-gray-47 dark:text-io-white ${
                isChecked ? "text-opacity-50" : "text-opacity-100"
              }`}
            >
              Theme 2
            </p>
          </div>
        )}
        {hasSaveBtn && !isExternalUser && (
          <div className="w-1/2 h-auto max-md:w-[20%]">
            <div className="w-full flex justify-end items-center">
              <TextBtn
                isLoading={isLoading}
                label={t("web.common.button.save")}
                btnStyle="fill-primary"
                btnType="button"
                handleClick={() => saveResponse(data)}
              />
            </div>
          </div>
        )}
        {hasHookSaveBtn && (
          <div className="w-1/2 h-auto max-md:w-[20%]">
            <div className="w-full flex justify-end items-center">
              <TextBtn
                isLoading={isLoading}
                label={t("web.common.button.save")}
                btnStyle="fill-primary"
                btnType="submit"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default PageHeader;
